import { PaymentAccountsToGetAllResponse } from 'model/payment-accounts';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum ListPaymentAccountsTypes {
    LIST_PAYMENT_ACCOUNTS_REQUEST = '@@payment-accounts-list/LIST_PAYMENT_ACCOUNTS_REQUEST',
    LIST_PAYMENT_ACCOUNTS_SUCCESS = '@@payment-accounts-list/LIST_PAYMENT_ACCOUNTS_SUCCESS',
    LIST_PAYMENT_ACCOUNTS_ERROR = '@@payment-accounts-list/LIST_PAYMENT_ACCOUNTS_ERROR',
    LIST_PAYMENT_ACCOUNTS_RESET = '@@payment-accounts-list/LIST_PAYMENT_ACCOUNTS_RESET',
}

export interface ListPaymentAccountsState extends BaseStoreState {
    readonly accounts?: PaymentAccountsToGetAllResponse;
}
