import { Grid } from '@material-ui/core';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import BaseOperationCard from 'features/ccb-operation/ccb-operation-detail/components/cards/base-operation-card/base-operation-card';
import OperationReadOnlyTextField from 'features/ccb-operation/ccb-operation-detail/components/operation-read-only-text-field/operation-read-only-text-field';
import { PersonType } from 'model/enums/person-type';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';

interface DocumentOperationCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.organization';

const OrganizationOperationCard = (props: DocumentOperationCardProps) => {
    const {
        operation: { organization },
    } = props;

    const { t } = useTranslation();

    return (
        <BaseOperationCard title={t(`${I18N_PREFIX}.title`)} isOpenable>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.name`)} value={organization?.name} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.identification`)} value={organization?.identification ? <IdentificationFormatter value={organization?.identification} type={PersonType.CORPORATION}/> : '-'} />
            </Grid>
        </BaseOperationCard>
    );
};

export default OrganizationOperationCard;
