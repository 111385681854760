import { Checkbox } from '@material-ui/core';
import clsx from 'clsx';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { OperationApprovalStatus } from 'model/enums/operation-approval-status';
import { PersonType } from 'model/enums/person-type';
import { DisapproveOperationApprovalRequest, OperationApproval, OperationApprovalFilterRequest } from 'model/operation-approvals';
import { Trans, useTranslation } from 'react-i18next';
import { useListOperationApprovalsState } from 'reducer/hooks';
import { valuesOfObject } from 'shared/util/object-utils';
import { getPersonType } from 'shared/util/person-type-utils';
import { OperationApprovalMoreOptionsType } from '../more-options-icon-button/menu/operation-approval-more-options-menu';
import OperationApprovalMoreOptionsIconButton from '../more-options-icon-button/operation-approval-more-options-icon-button';

interface OperationApprovalListProps {
    filterActive: OperationApprovalFilterRequest;
    isPending: boolean | undefined;
    setSelectedOperationApproval: (value: OperationApproval | undefined) => void;
    setModalType: (value: OperationApprovalMoreOptionsType | undefined) => void;
    setOperationToDisapprove: (value: DisapproveOperationApprovalRequest | undefined) => void;
    showCheckbox: boolean;
    handleChange: (value: number) => void;
    verifyCheckedCheckbox: (value: number) => boolean;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-approval';

const ScdOperationApprovalList = (props: OperationApprovalListProps) => {
    const { filterActive, isPending, setSelectedOperationApproval, setModalType, setOperationToDisapprove, showCheckbox, handleChange, verifyCheckedCheckbox } = props;

    const { t } = useTranslation();

    const { operationApprovals } = useListOperationApprovalsState();

    const showInvalidFilterMessage = valuesOfObject({ ...filterActive, status: filterActive?.status?.length ? filterActive.status : undefined }).some(it => it !== undefined);

    const getFavoredTranslation = (identification: string, personName: string, companyName: string): React.ReactNode => {
        if (identification === undefined || identification === null) return '-';

        if (getPersonType(identification) === PersonType.INDIVIDUAL) {
            return <Trans i18nKey={`${I18N_PREFIX}.favored.fullFavored`} t={t} values={{ name: personName }} components={{ identification: <IdentificationFormatter value={identification} type={PersonType.INDIVIDUAL} /> }} />;
        }

        return <Trans i18nKey={`${I18N_PREFIX}.favored.fullFavored`} t={t} values={{ name: companyName }} components={{ identification: <IdentificationFormatter value={identification} type={PersonType.CORPORATION} /> }} />;
    };

    return (
        <>
            {operationApprovals?.content?.length !== 0 ? (
                operationApprovals?.content?.map(operation => {
                    const { id, approvalDate, approverName, ccbNumber, debtorIdentification, requestDate, status, companyName, personName } = operation;
                    return (
                        <tr className="page-container--table-cell" key={id}>
                            <td className="page-container--table-cell-title" title={ccbNumber ?? ''}>
                                {ccbNumber}
                            </td>
                            <td className="page-container--table-cell-title">
                                <span>{requestDate ? <DateFormatter date={requestDate} format={DateFormat.DATETIME_COMPLETE_UTC} /> : '00/00/00'}</span>
                            </td>
                            <td className="page-container--table-cell-title">
                                <span>{getFavoredTranslation(debtorIdentification, personName, companyName)}</span>
                            </td>
                            {!isPending && (
                                <>
                                    <td className="page-container--table-cell-title">
                                        <span>{approvalDate ? <DateFormatter date={approvalDate} format={DateFormat.DATETIME_COMPLETE_UTC} /> : '-'}</span>
                                    </td>
                                    <td className="page-container--table-cell-title" title={approverName ?? ''}>
                                        <span>{approverName ?? '-'}</span>
                                    </td>
                                    <td className="page-container--table-cell-title" title={t(`${I18N_PREFIX}.status.options.${status}`).toUpperCase()}>
                                        <span
                                            className={clsx('page-container--table-status', {
                                                PENDING: status === OperationApprovalStatus.PENDING,
                                                APPROVED: status === OperationApprovalStatus.APPROVED,
                                                DISAPPROVED: status === OperationApprovalStatus.DISAPPROVED,
                                            })}
                                        >
                                            {t(`${I18N_PREFIX}.status.options.${status}`).toUpperCase()}
                                        </span>
                                    </td>
                                </>
                            )}
                            {showCheckbox ? (
                                <Checkbox color="primary" checked={verifyCheckedCheckbox(id)} onChange={() => handleChange(id)} />
                            ) : (
                                <td className="page-container--table-cell-title">
                                    <OperationApprovalMoreOptionsIconButton
                                        operationApproval={operation}
                                        onClick={() => {
                                            setSelectedOperationApproval(operation);
                                            setOperationToDisapprove({ id: operation?.id });
                                        }}
                                        setModalType={setModalType}
                                    />
                                </td>
                            )}
                        </tr>
                    );
                })
            ) : (
                <div className="scd-ccb-operation-approval__table-empty-filter">
                    <span className="scd-ccb-operation-approval__table-empty-filter-image" />
                    <span className="scd-ccb-operation-approval__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                    <span className="scd-ccb-operation-approval__table-empty-filter-subtitle">{showInvalidFilterMessage && t(`${I18N_PREFIX}.invalid-filter`)}</span>
                </div>
            )}
        </>
    );
};

export default ScdOperationApprovalList;
