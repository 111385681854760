import React from 'react';

import { useTranslation } from 'react-i18next';
import { Carousel as BootstrapCarousel, CarouselCaption, CarouselIndicators, CarouselItem } from 'reactstrap';

import background1 from 'images/img-background1.jpg';
import background2 from 'images/img-background2.jpg';
import background3 from 'images/img-background3.jpg';
import background4 from 'images/img-background4.jpg';

import './carousel.scss';

const I18N_PREFIX = 'pages.login.carousel';

export const Carousel = () => {
    const [activeIndex, setActiveIndex] = React.useState<number>(0);
    const [animating, setAnimating] = React.useState<boolean>(false);
    const { t } = useTranslation();

    const items = [
        {
            src: background1,
            altText: 'Slide 1',
            captionHeader: t(`${I18N_PREFIX}.first`),
        },
        {
            src: background2,
            altText: 'Slide 2',
            captionHeader: t(`${I18N_PREFIX}.second`),
        },
        {
            src: background3,
            altText: 'Slide 3',
            captionHeader: t(`${I18N_PREFIX}.third.start`),
            captionText: t(`${I18N_PREFIX}.third.end`),
        },
        {
            src: background4,
            altText: 'Slide 4',
            captionHeader: t(`${I18N_PREFIX}.fourth`),
            captionText: '',
        },
    ];

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const goToIndex = (newIndex: number) => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    const slides = items.map(item => {
        return (
            <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.src}>
                <div className="carousel--item" style={{ backgroundImage: `url(${item.src})` }} />
                <CarouselCaption captionText={item.captionText ?? ''} captionHeader={item.captionHeader ?? ''} />
            </CarouselItem>
        );
    });

    return (
        <div className="carousel--color">
            <BootstrapCarousel activeIndex={activeIndex} next={next} previous={previous} interval={4000}>
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
                {slides}
            </BootstrapCarousel>
        </div>
    );
};

export default Carousel;
