import { Grid } from '@material-ui/core';
import FieldPassword from 'features/profile/components/field-password/field-password';
import Loading from 'components/loading/loading';
import PasswordStrength from 'features/profile/components/password-strength/password-strength';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from 'reactstrap/lib/Card';
import { PasswordMatch, validatePassword, validatePasswordMatch } from '../validation/validation';
import './profile-editing.scss';
import { PasswordChange } from 'model/user';

const mapPasswordMatch = (p: string, m: string): PasswordMatch => ({ password: p, match: m });

export interface ProfileEditingProps {
    changePassword: PasswordChange;
    showValidation: boolean;
    isUpdating: boolean;
    handlePasswordChange: (update: Partial<PasswordChange>) => void;
}

export const ProfileEditing = (props: ProfileEditingProps) => {
    const { t } = useTranslation();
    const { showValidation, changePassword, isUpdating, handlePasswordChange } = props;

    return (
        <div className="profile-editing__items">
            <div className="container">
                <Grid id="password">
                    <Grid item className="personal-data__items--title">
                        {t('pages.scd.profile.user.password.title')}
                    </Grid>
                    <Grid className="profile-editing__items--fields">
                        <Grid item xs={7}>
                            <FieldPassword
                                id="inputPassword_profileChangePassword_currentPassword"
                                label={t('pages.scd.profile.user.password.currentPassword')}
                                value={changePassword.newPasswordConfirm}
                                onChange={currentPassword => handlePasswordChange({ currentPassword })}
                                validate={validatePassword}
                                showValidation={showValidation}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid>
                            <Grid item xs={7}>
                                <FieldPassword
                                    id="inputPassword_profileChangePassword_newPassword"
                                    label={t('pages.scd.profile.user.password.newPassword')}
                                    value={changePassword.newPasswordConfirm}
                                    onChange={newPassword => handlePasswordChange({ newPassword })}
                                    validate={validatePassword}
                                    showValidation={showValidation}
                                    autoComplete="new-password"
                                />
                            </Grid>
                            <Grid item xs={5} className="profile-editing__items--fields-block">
                                <Card className="profile-editing__items--fields-card">
                                    <div className="profile-editing__items--fields-card-text">{t('pages.scd.profile.user.password.strength').toUpperCase()}</div>
                                    <PasswordStrength password={changePassword.newPassword ?? ''} />
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid className="confirm-password">
                            <Grid item xs={7}>
                                <FieldPassword
                                    id="inputPassword_profileChangePassword_confirmPassword"
                                    label={t('pages.scd.profile.user.password.confirmedPassword')}
                                    value={changePassword.newPasswordConfirm}
                                    onChange={newPasswordConfirm => handlePasswordChange({ newPasswordConfirm })}
                                    validate={it => validatePasswordMatch(mapPasswordMatch(changePassword.newPassword, it))}
                                    showValidation={showValidation}
                                    autoComplete="new-password"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {isUpdating && (
                    <div className="personal-data__items--loading">
                        <Loading />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfileEditing;
