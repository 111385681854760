import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdBatchBankerDetail } from 'model/batch';
import { markError } from 'reducer/application/error/actions';
import { detailBatchBankerRequest, detailBatchBankerSuccess, detailBatchBankerError } from './actions';
import { DetailBatchBankerTypes } from './types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchBankerApi from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleBatchBankerDetail(action: ReturnType<typeof detailBatchBankerRequest>) {
    try {
        const result: AxiosResponse<ScdBatchBankerDetail> = yield call(batchBankerApi.detailBatchBanker, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(detailBatchBankerError(mapped));
            return;
        }
        yield put(detailBatchBankerSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailBatchBankerError(mapped));
    }
}

function* watchBatchBankerDetail() {
    yield takeEvery(DetailBatchBankerTypes.DETAIL_BATCH_BANKER_REQUEST, handleBatchBankerDetail);
}

function* detailBatchBankerSaga() {
    yield all([fork(watchBatchBankerDetail)]);
}

export default detailBatchBankerSaga;
