import { Validations, ValidationUtils } from '../../shared/util/validation-utils';

const I18N_PREFIX = 'pages.forgot-password.validation';

const EMAIL_VALIDATION: Validations<string> = {
    required: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.required`,
    },
    format: {
        predicate: ValidationUtils.STRING.validEmail,
        errorMessage: `${I18N_PREFIX}.format`,
    },
    max_length: {
        predicate: ValidationUtils.STRING.maxLength(100),
        errorMessage: `${I18N_PREFIX}.maxLength`,
    },
};
export const validateEmail = (value: string) => ValidationUtils.validate(value, EMAIL_VALIDATION);
