import { CircularProgress } from '@material-ui/core';
import './loading.scss';

export const Loading = (props: { isTable?: boolean }) => {
    return (
        <>
            {props.isTable ? (
                <tr className="loading--row">
                    <td className="loading--cell">
                        <CircularProgress />
                    </td>
                </tr>
            ) : (
                <div className="loading--div">
                    <CircularProgress />
                </div>
            )}
        </>
    );
};

export default Loading;
