import { InputValidation } from 'components/inputs/validation/input-validation';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const EMPTY_STRING = '';

const FAVORED_FILTER_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: EMPTY_STRING,
    },
    minSize: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: EMPTY_STRING,
    },
};
export const validateFilterFavored = (value: string | undefined) => InputValidation.validate(value, FAVORED_FILTER_VALIDATION);

const ACCOUNT_FILTER_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: EMPTY_STRING,
    },
    minSize: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: EMPTY_STRING,
    },
};
export const validateFilterAccount = (value: string | undefined) => InputValidation.validate(value, ACCOUNT_FILTER_VALIDATION);
