import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListBatchBankerTypes, ListBatchBankerState } from './types';
import { Reducer } from 'redux';

export const initialState: ListBatchBankerState = {
    status: HttpRequestStatus.NOOP,
    batches: undefined,
    error: undefined,
};

const reducer: Reducer<ListBatchBankerState> = (state = initialState, action): ListBatchBankerState => {
    switch (action.type) {
        case ListBatchBankerTypes.LIST_BATCH_BANKER_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case ListBatchBankerTypes.LIST_BATCH_BANKER_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, batches: action.payload, error: undefined };
        }
        case ListBatchBankerTypes.LIST_BATCH_BANKER_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListBatchBankerTypes.LIST_BATCH_BANKER_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listBatchBankerReducer };
