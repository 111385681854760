import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import { MASK_DIGIT_REGEX } from 'components/inputs/constants';
import React from 'react';
import MaskedInput from 'react-text-mask';

const CNPJ_MASK = [
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    '.',
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    '.',
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    '/',
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    '-',
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
];

const CPF_MASK = [MASK_DIGIT_REGEX, MASK_DIGIT_REGEX, MASK_DIGIT_REGEX, '.', MASK_DIGIT_REGEX, MASK_DIGIT_REGEX, MASK_DIGIT_REGEX, '.', MASK_DIGIT_REGEX, MASK_DIGIT_REGEX, MASK_DIGIT_REGEX, '-', MASK_DIGIT_REGEX, MASK_DIGIT_REGEX];

const identificationMask = (mask: string | (string | RegExp)[]) => {
    if (mask.length <= 11) {
        return CPF_MASK;
    }
    return CNPJ_MASK;
};

export const IdentificationMask: React.ElementType<InputBaseComponentProps> = props => {
    // remove ref issue
    // https://github.com/text-mask/text-mask/issues/714

    const { inputRef, ...others } = props;
    return <MaskedInput {...others} mask={identificationMask} guide={false} />;
};

export default IdentificationMask;
