import { BatchBanker } from 'model/batch';
import { BaseStoreState } from 'reducer/baseStoreState';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListBatchBankerTypes {
    LIST_BATCH_BANKER_REQUEST = '@@list-batch-banker/LIST_BATCH_BANKER_REQUEST',
    LIST_BATCH_BANKER_SUCCESS = '@@list-batch-banker/LIST_BATCH_BANKER_SUCCESS',
    LIST_BATCH_BANKER_ERROR = '@@list-batch-banker/LIST_BATCH_BANKER_ERROR',
    LIST_BATCH_BANKER_RESET_STATE = '@@list-batch-banker/LIST_BATCH_BANKER_RESET_STATE',
}

export interface ListBatchBankerState extends BaseStoreState {
    readonly batches?: Page<BatchBanker>;
}
