import { HttpRequestStatus } from 'model/enums/http-request-status';
import { Reducer } from 'redux';
import { AuthenticationActionTypes, AuthenticationState } from './types';

export const initialState: AuthenticationState = {
    status: HttpRequestStatus.NOOP,
    token: undefined,
};

const reducer: Reducer<AuthenticationState> = (state = initialState, action): AuthenticationState => {
    switch (action.type) {
        case AuthenticationActionTypes.LOGIN_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case AuthenticationActionTypes.LOGIN_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, token: action.payload };
        }
        case AuthenticationActionTypes.LOGIN_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        default: {
            return state;
        }
    }
};

export { reducer as authenticationReducer };
