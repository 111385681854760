import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { ClearButton } from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import { StandardButton } from 'components/buttons/standard-button/standard-button';
import SimpleDatePickerInput from 'components/inputs/date-input/date-picker/simple-date-picker';
import SimpleIdentificationInput from 'components/inputs/identification-input/simple-identification-input';
import SimpleTextNumberInput from 'components/inputs/text-number-input/simple-text-number-input';
import { ImportedOperationBankerStatus } from 'model/enums/imported-operation-status';
import { ProductType } from 'model/enums/product-type';
import { ImportedOperationFilterRequest } from 'model/imported-operation';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { valuesOfObject } from 'shared/util/object-utils';
import './operation-modal-filter.scss';

export type OperationModalFilterType = 'filters' | 'acceptedDate' | 'ccb' | 'batch' | 'product' | 'debtor' | 'status';

export interface OperationModalFilterProps {
    onClose: () => void;
    modalFilter: OperationModalFilterType;
    openModal: boolean;
    title: string;
    filterActive: ImportedOperationFilterRequest;
    setFilterActive: (value: ImportedOperationFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.ccb-operation';

export const DISABLED_MODAL_BUTTON_FUNCTION_RECORD = {
    acceptedDate: filter => !filter.acceptedDate,
    ccb: filter => !filter.ccbNumber,
    batch: filter => !filter.batchCode,
    product: filter => !filter.productType,
    status: filter => !filter.status?.length,
    debtor: filter => !filter.debtorIdentification,
    filters: filter => valuesOfObject({ ...filter, status: filter?.status?.length ? filter.status : undefined }).filter(it => it !== undefined).length === 0,
} as Record<OperationModalFilterType, (filter: ImportedOperationFilterRequest) => boolean>;

export const OperationModalFilter = (props: OperationModalFilterProps) => {
    const { onClose, modalFilter, openModal, title, setFilterActive, filterActive, setPage } = props;
    const [filterRequest, setFilterRequest] = useState<ImportedOperationFilterRequest>(filterActive);
    const { t } = useTranslation();

    useEffect(() => {
        if (filterActive) {
            setFilterRequest(filterActive);
        }
    }, [filterActive]);

    if (!openModal) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        setPage(0);

        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<ImportedOperationFilterRequest>) => {
        setFilterRequest({ ...filterRequest, ...value });
    };

    const handleChangeStatus = (operationStatus: ImportedOperationBankerStatus) => {
        const hasSelectedStatus = filterRequest?.status?.some(status => status === operationStatus);

        if (hasSelectedStatus) {
            const filteredData = filterRequest?.status?.filter(status => status !== operationStatus);
            handleChange({ status: filteredData });
            return;
        }

        handleChange({ status: filterRequest?.status?.concat(operationStatus) });
    };

    const handleClearFilter = {
        filters: () => {
            setFilterRequest({ status: [] });
            setFilterActive({ status: [] });
        },
        acceptedDate: () => {
            setFilterActive({ ...filterActive, acceptedDate: undefined });
            setFilterRequest({ ...filterRequest, acceptedDate: undefined });
        },
        ccb: () => {
            setFilterActive({ ...filterActive, ccbNumber: undefined });
            setFilterRequest({ ...filterRequest, ccbNumber: undefined });
        },
        batch: () => {
            setFilterActive({ ...filterActive, batchCode: undefined });
            setFilterRequest({ ...filterRequest, batchCode: undefined });
        },
        product: () => {
            setFilterActive({ ...filterActive, productType: undefined });
            setFilterRequest({ ...filterRequest, productType: undefined });
        },
        debtor: () => {
            setFilterActive({ ...filterActive, debtorIdentification: undefined });
            setFilterRequest({ ...filterRequest, debtorIdentification: undefined });
        },
        status: () => {
            setFilterActive({ ...filterActive, status: [] });
            setFilterRequest({ ...filterRequest, status: [] });
        },
    } as Record<OperationModalFilterType, () => void>;

    const AcceptedDateFilter: JSX.Element = (
        <>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    label={t(`${I18N_PREFIX}.inputs.initialDate`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.initialDate`)}
                    value={filterRequest.acceptedDate?.start ?? ''}
                    onChange={initialDate => handleChange({ acceptedDate: { ...filterRequest.acceptedDate, start: initialDate } })}
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    label={t(`${I18N_PREFIX}.inputs.finalDate`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.finalDate`)}
                    minDate={filterRequest.acceptedDate?.start}
                    value={filterRequest.acceptedDate?.end ?? ''}
                    onChange={finalDate => handleChange({ acceptedDate: { ...filterRequest.acceptedDate, end: finalDate } })}
                />
            </Grid>
        </>
    );

    const CcbNumberFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleTextNumberInput
                    onChange={ccbNumber => handleChange({ ccbNumber })}
                    label={t(`${I18N_PREFIX}.inputs.ccbNumber`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.ccbNumber`)}
                    value={filterRequest?.ccbNumber ?? ''}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const BatchCodeFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleTextNumberInput
                    onChange={batchCode => handleChange({ batchCode })}
                    label={t(`${I18N_PREFIX}.inputs.batchNumber`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.batchNumber`)}
                    value={filterRequest?.batchCode ?? ''}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const DebtorIdentificationFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleIdentificationInput
                    onChange={debtorIdentification => handleChange({ debtorIdentification })}
                    label={t(`${I18N_PREFIX}.inputs.debtorIdentification`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.debtorIdentification`)}
                    value={filterRequest?.debtorIdentification ?? ''}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const ProductFilter: JSX.Element = (
        <div className="operation-modal-filter--content">
            <div
                className={clsx('operation-modal-filter--content-product--label', {
                    active: filterRequest.productType === ProductType.CREDIT_CARD,
                })}
                onClick={() => handleChange({ productType: ProductType.CREDIT_CARD })}
            >
                {t(`${I18N_PREFIX}.product.options.CREDIT_CARD`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('operation-modal-filter--content-product--label', {
                    active: filterRequest.productType === ProductType.DIRECT_CONSUMER_CREDIT,
                })}
                onClick={() => handleChange({ productType: ProductType.DIRECT_CONSUMER_CREDIT })}
            >
                {t(`${I18N_PREFIX}.product.options.DIRECT_CONSUMER_CREDIT`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('operation-modal-filter--content-product--label', {
                    active: filterRequest.productType === ProductType.PERSONAL_CREDIT,
                })}
                onClick={() => handleChange({ productType: ProductType.PERSONAL_CREDIT })}
            >
                {t(`${I18N_PREFIX}.product.options.PERSONAL_CREDIT`).toLocaleUpperCase()}
            </div>
        </div>
    );

    const StatusFilter: JSX.Element = (
        <div className="operation-modal-filter--content">
            <button
                className={clsx('operation-modal-filter--content-status--label', {
                    RECEIVED: filterRequest.status?.some(status => status === ImportedOperationBankerStatus.RECEIVED),
                })}
                onClick={() => {
                    handleChangeStatus(ImportedOperationBankerStatus.RECEIVED);
                }}
            >
                {t(`${I18N_PREFIX}.status.options.RECEIVED`).toLocaleUpperCase()}
            </button>

            <button
                className={clsx('operation-modal-filter--content-status--label', {
                    PROCESSED: filterRequest.status?.some(status => status === ImportedOperationBankerStatus.PROCESSED),
                })}
                onClick={() => {
                    handleChangeStatus(ImportedOperationBankerStatus.PROCESSED);
                }}
            >
                {t(`${I18N_PREFIX}.status.options.PROCESSED`).toLocaleUpperCase()}
            </button>
            <button
                className={clsx('operation-modal-filter--content-status--label', {
                    ERROR: filterRequest.status?.some(status => status === ImportedOperationBankerStatus.ERROR),
                })}
                onClick={() => {
                    handleChangeStatus(ImportedOperationBankerStatus.ERROR);
                }}
            >
                {t(`${I18N_PREFIX}.status.options.ERROR`).toLocaleUpperCase()}
            </button>
            <button
                className={clsx('operation-modal-filter--content-status--label', {
                    ARCHIVED: filterRequest.status?.some(status => status === ImportedOperationBankerStatus.ARCHIVED),
                })}
                onClick={() => {
                    handleChangeStatus(ImportedOperationBankerStatus.ARCHIVED);
                }}
            >
                {t(`${I18N_PREFIX}.status.options.ARCHIVED`).toLocaleUpperCase()}
            </button>
            <button
                className={clsx('operation-modal-filter--content-status--label', {
                    ARCHIVED: filterRequest.status?.some(status => status === ImportedOperationBankerStatus.DISAPPROVED),
                })}
                onClick={() => {
                    handleChangeStatus(ImportedOperationBankerStatus.DISAPPROVED);
                }}
            >
                {t(`${I18N_PREFIX}.status.options.DISAPPROVED`).toLocaleUpperCase()}
            </button>
        </div>
    );

    const isConfirmButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    return (
        openModal && (
            <Modal isOpen>
                <div className="operation-modal-filter">
                    <div className="operation-modal-filter--header">
                        <label className="operation-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            {AcceptedDateFilter}
                                            {ProductFilter}
                                            {CcbNumberFilter}
                                            {BatchCodeFilter}
                                            {DebtorIdentificationFilter}
                                            {StatusFilter}
                                        </>
                                    ),
                                    acceptedDate: AcceptedDateFilter,
                                    product: ProductFilter,
                                    ccb: CcbNumberFilter,
                                    batch: BatchCodeFilter,
                                    debtor: DebtorIdentificationFilter,
                                    status: StatusFilter,
                                } as Record<OperationModalFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>
                    <div className="operation-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButton label="entity.action.confirm" onClick={handleConfirmRequest} disabled={isConfirmButtonDisabled} />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default OperationModalFilter;
