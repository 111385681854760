import './forgot-password.scss';
import ModalForgotPassword from './components/modal-forgot-password/modal-forgot-password';
import { useTranslation } from 'react-i18next';
import FieldBasic from 'components/inputs/login/field-basic/field-basic';
import OutlinedButton from 'components/buttons/outlined-button/outlined-button';
import StandardButton from 'components/buttons/standard-button/standard-button';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useForgotPasswordState, useRootDispatch } from 'reducer/hooks';
import { validateEmail } from './validation-constants';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UserPasswordResetRequest } from 'model/user';
import { forgotPasswordRequest, forgotPasswordResetState } from 'reducer/account/forgot-password/actions';

export interface ForgotPasswordProps extends RouteComponentProps<{ key: string }> {}

const ForgotPassword = (props: ForgotPasswordProps) => {
    const [email, setEmail] = useState<string>('');
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const history = useHistory();
    const { status } = useForgotPasswordState();

    const haltProgression = !validateEmail(email).isValid;

    const I18N_PREFIX = 'pages.forgot-password';

    const handleValidSubmit = () => {
        setShowValidation(true);
        const _email = email;

        if (haltProgression) return;

        const payload: UserPasswordResetRequest = { mail: _email };
        dispatch(forgotPasswordRequest(payload));
    };

    const handleCloseModal = () => {
        history.goBack();
    };

    useEffect(() => {
        return () => {
            dispatch(forgotPasswordResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (status === HttpRequestStatus.SUCCESS) {
            setShowModal(true);
        }
    }, [status]);

    return (
        <main className="password__forgot">
            <article className="password__forgot--container">
                <header className="password__forgot--header">
                    <div className="password__forgot--field">
                        <p className="password__forgot--header--title">{t(`${I18N_PREFIX}.title`)}</p>
                    </div>
                </header>
                <div className="password__forgot--content">
                    <div className="password__forgot--field">
                        <FieldBasic helpText={t('pages.forgot-password.email.help')} label={t('pages.forgot-password.email.label')} value={email} onChange={setEmail} validate={validateEmail} showValidation={showValidation} />
                    </div>
                    <div className="password__forgot--button">
                        <OutlinedButton label="pages.forgot-password.action.back" onClick={() => props.history.goBack()} />
                        <StandardButton label="pages.forgot-password.action.send" onClick={handleValidSubmit} disabled={showValidation && haltProgression} />
                    </div>
                </div>
            </article>
            <ModalForgotPassword open={showModal} closeModal={handleCloseModal} />
        </main>
    );
};

export default ForgotPassword;
