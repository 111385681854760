import BackButton from 'components/buttons/back-button/back-button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import SimpleHeader from 'shared/header/simple-header';
import './page-user-role-not-valid.scss';

export const PageUseRoleNotValid = () => {
    const { t } = useTranslation();
    const history = useHistory()

    return (
        <div className="body__page-user-role-not-valid">
            <SimpleHeader />
            <Row style={{ height: '84vh' }}>
                <Col md={{ size: '8', offset: '2' }}>
                    <div className="page-user-role-not-valid--itens">
                        <div className="page-user-role-not-valid--image" />
                        <div className="page-user-role-not-valid--text">
                            <span>{t('page-not-valid-for-user.title')}</span>
                            <div className="page-user-role-not-valid--subtitle">{t('page-not-valid-for-user.subtitle')}</div>
                        </div>
                            <BackButton label={t('global.back')} onClick={() => history.push('/dashboard')} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default PageUseRoleNotValid;
