import { HttpRequestStatus } from 'model/enums/http-request-status';
import { Reducer } from 'redux';
import { DetailImportedOperationState, DetailImportedOperationTypes } from './types';

const initialState: DetailImportedOperationState = {
    status: HttpRequestStatus.NOOP,
    detailImportedOperation: undefined,
    error: undefined,
};

const reducer: Reducer<DetailImportedOperationState> = (state = initialState, action): DetailImportedOperationState => {
    switch (action.type) {
        case DetailImportedOperationTypes.DETAIL_IMPORTED_OPERATION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case DetailImportedOperationTypes.DETAIL_IMPORTED_OPERATION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, detailImportedOperation: action.payload, error: undefined };
        }
        case DetailImportedOperationTypes.DETAIL_IMPORTED_OPERATION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case DetailImportedOperationTypes.DETAIL_IMPORTED_OPERATION_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as detailImportedOperationReducer };
