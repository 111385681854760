import { ImportedOperationToBatchDetail } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/baseStoreState';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum ListImportedOperationsByBatchTypes {
    LIST_IMPORTED_OPERATIONS_BY_BATCH_REQUEST = '@@list-imported-operations_by_batch/LIST_IMPORTED_OPERATIONS_BY_BATCH_REQUEST',
    LIST_IMPORTED_OPERATIONS_BY_BATCH_SUCCESS = '@@list-imported-operations_by_batch/LIST_IMPORTED_OPERATIONS_BY_BATCH_SUCCESS',
    LIST_IMPORTED_OPERATIONS_BY_BATCH_ERROR = '@@list-imported-operations_by_batch/LIST_IMPORTED_OPERATIONS_BY_BATCH_ERROR',
    LIST_IMPORTED_OPERATIONS_BY_BATCH_RESET_STATE = '@@list-imported-operations_by_batch/LIST_IMPORTED_OPERATIONS_BY_BATCH_RESET_STATE',
}

export interface ListImportedOperationsByBatchState extends BaseStoreState {
    readonly operations?: Page<ImportedOperationToBatchDetail>;
}
