import { BaseStoreState } from 'reducer/baseStoreState';
import { ScdPaymentTransactionData } from 'model/payment-transaction';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListPaymentTransactionsTypes {
    LIST_PAYMENT_TRANSACTIONS_REQUEST = '@@payment-transactions-list/LIST_PAYMENT_TRANSACTIONS_REQUEST',
    LIST_PAYMENT_TRANSACTIONS_SUCCESS = '@@payment-transactions-list/LIST_PAYMENT_TRANSACTIONS_SUCCESS',
    LIST_PAYMENT_TRANSACTIONS_ERROR = '@@payment-transactions-list/LIST_PAYMENT_TRANSACTIONS_ERROR',
    LIST_PAYMENT_TRANSACTIONS_RESET_STATE = '@@payment-transactions-list/LIST_PAYMENT_TRANSACTIONS_RESET_STATE',
}

export interface ListPaymentTransactionsState extends BaseStoreState {
    readonly paymentTransactions?: Page<ScdPaymentTransactionData>;
}
