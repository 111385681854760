import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { OperationApprovalErrorResponse } from 'model/operation-approvals';
import { markError } from 'reducer/application/error/actions';
import { approveMultiplesOperationsApprovalRequest, approveMultiplesOperationsApprovalError, approveMultiplesOperationsApprovalSuccess } from './actions';
import { ApproveMultiplesOperationsApprovalTypes } from './types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import operationApprovalApi from 'services/api/operation-approvals-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleOperationApprovalApproveMultiples(action: ReturnType<typeof approveMultiplesOperationsApprovalRequest>) {
    try {
        const result: AxiosResponse<OperationApprovalErrorResponse> = yield call(operationApprovalApi.approveMultiplesOperations, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(approveMultiplesOperationsApprovalError(mapped));
            return;
        }
        yield put(approveMultiplesOperationsApprovalSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(approveMultiplesOperationsApprovalError(mapped));
    }
}

function* watchOperationApprovalApproveMultiples() {
    yield takeLeading(ApproveMultiplesOperationsApprovalTypes.APPROVE_MULTIPLES_OPERATIONS_APPROVAL_REQUEST, handleOperationApprovalApproveMultiples);
}

function* approveMultiplesOperationsApprovalSaga() {
    yield all([fork(watchOperationApprovalApproveMultiples)]);
}

export default approveMultiplesOperationsApprovalSaga;
