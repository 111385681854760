import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { UserSimple } from 'model/user';
import accountApi from 'services/api/accountApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { resetPasswordError, resetPasswordRequest, resetPasswordSuccess } from 'reducer/account/password-reset/actions';
import { UserPasswordResetActionTypes } from 'reducer/account/password-reset/types';

function* handlePasswordReset(action: ReturnType<typeof resetPasswordRequest>) {
    try {
        const result: AxiosResponse<UserSimple> = yield call(accountApi.passwordResetFinish, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(resetPasswordError(mapped));
            return;
        }
        yield put(resetPasswordSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(resetPasswordError(mapped));
    }
}

function* watchUserResetPassword() {
    yield takeEvery(UserPasswordResetActionTypes.USER_RESET_PASSWORD_REQUEST, handlePasswordReset);
}

function* userPasswordResetSaga() {
    yield all([fork(watchUserResetPassword)]);
}

export default userPasswordResetSaga;
