import { HttpRequestStatus } from 'model/enums/http-request-status';
import { Reducer } from 'redux';
import { GetPaymentTransactionOnImportedOperationState, GetPaymentTransactionOnImportedOperationTypes } from './types';

const initialState: GetPaymentTransactionOnImportedOperationState = {
    status: HttpRequestStatus.NOOP,
    headerInfoPaymentTransaction: undefined,
    error: undefined,
};

const reducer: Reducer<GetPaymentTransactionOnImportedOperationState> = (state = initialState, action): GetPaymentTransactionOnImportedOperationState => {
    switch (action.type) {
        case GetPaymentTransactionOnImportedOperationTypes.GET_PAYMENT_TRANSACTION_ON_IMPORTED_OPERATION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case GetPaymentTransactionOnImportedOperationTypes.GET_PAYMENT_TRANSACTION_ON_IMPORTED_OPERATION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, headerInfoPaymentTransaction: action.payload, error: undefined };
        }
        case GetPaymentTransactionOnImportedOperationTypes.GET_PAYMENT_TRANSACTION_ON_IMPORTED_OPERATION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case GetPaymentTransactionOnImportedOperationTypes.GET_PAYMENT_TRANSACTION_ON_IMPORTED_OPERATION_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as getPaymentTransactionOnImportedOperationReducer };
