import { GiroWebappError } from 'model/error';
import { PaymentAccountsToGetAllResponse } from 'model/payment-accounts';
import { action } from 'typesafe-actions';
import { ListPaymentAccountsTypes } from './types';

export const listPaymentAccountsRequest = () =>
    action(ListPaymentAccountsTypes.LIST_PAYMENT_ACCOUNTS_REQUEST);
export const listPaymentAccountsSuccess = (paymentAccounts: PaymentAccountsToGetAllResponse) =>
    action(ListPaymentAccountsTypes.LIST_PAYMENT_ACCOUNTS_SUCCESS, paymentAccounts);
export const listPaymentAccountsError = (error: GiroWebappError) =>
    action(ListPaymentAccountsTypes.LIST_PAYMENT_ACCOUNTS_ERROR, error);
export const listPaymentAccountsResetState = () =>
    action(ListPaymentAccountsTypes.LIST_PAYMENT_ACCOUNTS_RESET);
