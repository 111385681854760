import { CloseButton } from 'components/buttons/close-button/close-button';
import { OutlinedButton } from 'components/buttons/outlined-button/outlined-button';
import { StandardButton } from 'components/buttons/standard-button/standard-button';
import { Modal } from 'reactstrap';
import './modal-message.scss';

export interface ModalMessageProps {
    title?: string;
    children?: React.ReactNode;
    message?: React.ReactNode;
    onClose: () => void;
    onAction?: () => void;
    onCancel?: () => void;
    outlinedButtonLabel?: string;
    outlinedButtonSize?: 'small' | 'medium' | 'large';
    standardButtonLabel?: string;
    disabled?: boolean;
    icon?: React.ReactNode;
    OutlinedButtonStyle?: React.CSSProperties;
    StandardButtonStyle?: React.CSSProperties;
}

export const ModalMessage = (props: ModalMessageProps) => {
    const { message, title, onClose, onAction, onCancel, outlinedButtonLabel, standardButtonLabel, children, disabled, icon, outlinedButtonSize, OutlinedButtonStyle, StandardButtonStyle } = props;

    return (
        <Modal isOpen>
            <div className="modal-message">
                <article className="modal-message--content">
                    <header className="modal-message--header">
                        <CloseButton onClick={onClose} />
                    </header>
                    <div className="modal-message--message">
                        <div className="modal-message--icon"> {icon} </div>
                        <p className="modal-message--title">{title}</p>
                        {message && <p className="modal-message--text">{message}</p>}
                        {children && children}
                    </div>
                    {(onCancel ?? onAction) && (
                        <div className="modal-message--actions">
                            {onCancel && <OutlinedButton label={outlinedButtonLabel ?? 'entity.action.cancel'} onClick={onCancel} size={outlinedButtonSize} style={OutlinedButtonStyle} />}
                            {onAction && <StandardButton label={standardButtonLabel ?? 'entity.action.confirm'} onClick={onAction} disabled={disabled ?? false} style={StandardButtonStyle} />}
                        </div>
                    )}
                </article>
            </div>
        </Modal>
    );
};

export default ModalMessage;
