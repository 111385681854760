import ptBR from 'i18n/pt-br.json';
import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources: Resource = {
    pt: { translation: ptBR },
};
const i18n = i18next.createInstance();

i18n.use(initReactI18next).init({
    resources,
    lng: 'pt',
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

export default i18n;
