import FormatterUtils from 'components/formatter/formatter-utils';

export type PercentageValue = number | undefined | null;

export interface PercentageFormatterProps {
    value: PercentageValue;
    defaultValue?: number;
}

export const PercentageFormatter = (props: PercentageFormatterProps) => {
    const { value, defaultValue } = props;

    return <>{FormatterUtils.formatNumberToPercentage(value, defaultValue)}</>;
};

export const formatPercentage = FormatterUtils.formatNumberToPercentage;

export default PercentageFormatter;
