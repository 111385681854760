import { Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterButton from 'components/buttons/filter-button/filter-button';
import { PaymentTransactionModalFilterType } from 'features/payment-transaction/components/payment-transaction-modal-filter/payment-transaction-modal-filter';
import { PaymentTransactionFilterRequest } from 'model/payment-transaction';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

const I18N_PREFIX = 'pages.scd.payment-transaction.filter.buttons';

interface PaymentTransactionFilterButtonsProps {
    setModalFilter: (value: PaymentTransactionModalFilterType) => void;
    setOpenModalFilter: (value: boolean) => void;
    filterActive: PaymentTransactionFilterRequest;
    handleResetFilterActive: () => void;
}

const PaymentTransactionFilterButtons = (props: PaymentTransactionFilterButtonsProps) => {
    const { setModalFilter, setOpenModalFilter, filterActive, handleResetFilterActive } = props;
    const { t } = useTranslation();

    const arrowFilterIcon = (
        <React.Fragment>
            <ArrowDropDownIcon />
        </React.Fragment>
    );
    const isFiltersButtonActive = valuesOfObject({ ...filterActive }).length !== 0 && valuesOfObject({ ...filterActive }).some(value => value !== undefined);

    const showRemoveAllIcon = valuesOfObject({ ...filterActive }).some(value => !!value);

    return (
        <>
            <div className="scd-payment-transaction__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('filters');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.filters`)}
                    icon={<FilterListIcon />}
                    isActiveFilter={isFiltersButtonActive}
                />
            </div>
            <div className="scd-payment-transaction__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('date');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.date`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={!!filterActive.date}
                />
            </div>
            <div className="scd-payment-transaction__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('amount');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.amount`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={!!filterActive.amount}
                />
            </div>
            <div className="scd-payment-transaction__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('favored');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.favored`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={!!filterActive.favoredSearch}
                />
            </div>
            <div className="scd-payment-transaction__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('account');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.account`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={!!filterActive.accountSearch}
                />
            </div>
            <div className="scd-payment-transaction__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('status');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.status`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={!!filterActive.status}
                />
            </div>
            {showRemoveAllIcon && (
                <Tooltip title={String(t('global.remove-filter.title'))}>
                    <div className="scd-payment-transaction__header--buttons-content-error-arrow" onClick={handleResetFilterActive} />
                </Tooltip>
            )}
        </>
    );
};

export default PaymentTransactionFilterButtons;
