import FieldBasic from 'components/inputs/login/field-basic/field-basic';
import FieldPassword from 'components/inputs/login/field-password/field-password';
import { Authentication } from 'model/authentication';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { loginRequest } from 'reducer/authentication/actions';
import { useAuthenticationState, useRootDispatch } from 'reducer/hooks';
import ActionButton from 'components/buttons/action-button/action-button';
import Carousel from 'features/login/components/carousel/carousel';
import './user-login.scss';
import { validateEmail, validatePassword } from 'features/login/validation-constants';

const UserLogin = () => {
    const { t } = useTranslation();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showValidation, setValidation] = useState<boolean>(false);

    const dispatch = useRootDispatch();

    const state = useAuthenticationState();

    const isLoading = state.status === HttpRequestStatus.ON_GOING;

    const haltProgression = !validateEmail(email).isValid || !validatePassword(password).isValid;

    const handleEnter = () => {
        setValidation(true);
        if (haltProgression) return;

        const _password = password;
        const request: Authentication = { username: email, password: _password, rememberMe: true };
        dispatch(loginRequest(request));
    };

    return (
        <div className="login-container">
            <Grid container>
                <Grid item md={6}>
                    <div className="login--logo" />
                    <Carousel />
                </Grid>
                <Grid item md={6} sm={12} className="login--row">
                    <div className="login--form-logo">
                        <div className="login--form-logo-image" />
                    </div>
                    <form className="login--form-container">
                        <div className="login--title">
                            <p> {t('pages.login.title.start').toUpperCase()} </p>
                            <p> {t('pages.login.title.end').toUpperCase()} </p>
                        </div>
                        <div style={{ marginBottom: '30px' }} />
                        <FieldBasic label={t('pages.login.email.label')} value={email} onChange={setEmail} validate={validateEmail} autoComplete="username" showValidation={showValidation} onEnter={handleEnter} />
                        <div style={{ marginBottom: '12px' }} />
                        <div className="login--link">
                            <Link to="/forgot-password"> {t('pages.login.password.forgot')} </Link>
                        </div>
                        <div style={{ marginBottom: '15px' }} />
                        <FieldPassword label={t('pages.login.password.label')} value={password} onChange={setPassword} validate={validatePassword} autoComplete="current-password" showValidation={showValidation} onEnter={handleEnter} />
                        <div style={{ marginBottom: '40px' }} />
                        <div>
                            <ActionButton label={t('pages.login.actions.enter').toUpperCase()} onClick={handleEnter} disabled={isLoading || (showValidation && haltProgression)} isLoading={isLoading} />
                        </div>
                    </form>
                </Grid>
            </Grid>
        </div>
    );
};

export default UserLogin;
