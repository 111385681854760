import clsx from 'clsx';
import MenuRolePermissionValidationComponent
    from 'components/menu-role-permission-validation/menu-role-permission-validation';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export type LinkAction = () => void;

export interface MenuItemPropsList {
    translation: string;
    rolesAllowedMainMenu: string[];
    visibility: boolean;
    submenu: {
        link: string;
        translation: string;
        visibility: boolean;
        rolesAllowedSubmenu: string[];
        action?: LinkAction;
    }[];
}

export interface MenuItemProps {
    icon?: string;
    list: MenuItemPropsList[];
    menu?: boolean;
}

export const MenuItem = (props: MenuItemProps) => {
    const local = useLocation().pathname;
    const [itemActive, setItemActive] = useState<number | null>(null);
    const [submenu, setSubmenu] = useState<boolean>(false);
    const menuRef = useRef(null);

    const { t } = useTranslation();
    const { icon, list, menu } = props;

    const handleSubMenuVisibility = () => (submenu ? setSubmenu(false) : setSubmenu(true));

    const handleActiveItem = (index: number) => {
        setItemActive(index);
        handleSubMenuVisibility();
    };

    const handleActive = useCallback(
        (path?: string) => {
            const filtered = props.list.filter((it) => it.visibility);
            const activeIndex = filtered.findIndex(it => it.submenu?.find(_it => _it.link.split('/')[1] === path));
            setItemActive(ps => (ps !== activeIndex ? activeIndex : ps));
        },
        [props.list]
    );

    const handleSubMenu = (action?: LinkAction) => {
        if (action) {
            action();
        }
        handleSubMenuVisibility();
        setItemActive(null);
    };

    const handleOutside = (ref: any) => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setSubmenu(false);
                setItemActive(null);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    };

    useEffect(() => {
        if (itemActive === null) {
            handleActive(local.split('/')[1]);
        }
    }, [local, itemActive, handleActive]);

    useEffect(() => {
        handleOutside(menuRef);
    }, [menuRef]);

    useEffect(() => {
        return () => {
            setSubmenu(false);
            setItemActive(null);
        };
    }, [setSubmenu, setItemActive]);

    return (
        <ul className="page-header--menu-list" ref={menu ? menuRef : null}>
            {list.filter(it => it.visibility).map((item, index) => {
                return (
                    <MenuRolePermissionValidationComponent rolesAllowed={item.rolesAllowedMainMenu} key={index}>
                        <li className="page-header--menu-list-item" key={index} ref={!menu ? menuRef : null}>
                            <div className={clsx('page-header--menu-list-item-button', { active: itemActive === index })} onClick={() => handleActiveItem(index)}>
                                {icon && <img src={icon} alt={icon} />}
                                <span> {t(item.translation)}</span>
                            </div>
                            <ul className="page-header--submenu-list">
                                {itemActive === index &&
                                    submenu &&
                                    item.submenu
                                        ?.filter(it => it.visibility)
                                        .map((it, arrayIndex) => (
                                            <MenuRolePermissionValidationComponent rolesAllowed={it.rolesAllowedSubmenu} key={arrayIndex}>
                                                <li key={it.link} className={clsx('page-header--submenu-list-item', { active: it.link === local })}>
                                                    <Link to={it.link} className={clsx('page-header--submenu-list-item-link')} onClick={() => handleSubMenu(it.action)}>
                                                        <span>{t(it.translation)}</span>
                                                    </Link>
                                                </li>
                                            </MenuRolePermissionValidationComponent>
                                        ))}
                            </ul>
                        </li>
                    </MenuRolePermissionValidationComponent>
                );
            })}
        </ul>
    );
};

export default MenuItem;
