import { ErrorType } from 'model/enums/error-constants';
import { ErrorHandlingCustomization } from 'model/error';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { reset, resolveError } from 'reducer/application/error/actions';
import { useApplicationErrorState, useRootDispatch } from 'reducer/hooks';
import ErrorToast from 'shared/error/error-toast/error-toast';

export const ErrorHandler = () => {
    const applicationErrorState = useApplicationErrorState();
    const dispatch = useRootDispatch();
    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, [dispatch, location]);

    const handleToastClose = (error: string) => {
        dispatch(resolveError(error));
    };

    const handleToastAction = (error: string) => {
        const custom = applicationErrorState.customs[error];
        if (custom?.handler) {
            custom.handler(history);
        }
        dispatch(resolveError(error));
    };

    return (
        <>
            {applicationErrorState.errors
                .filter(it => {
                    const record = applicationErrorState.customs[it];
                    return !record || !record.type || record.type === ErrorType.BLOCKING;
                })
                .map((error: any, index) => {
                    const custom: ErrorHandlingCustomization | undefined = applicationErrorState.customs[error];
                    return (
                        <ErrorToast
                            key={index}
                            open
                            message={custom?.message?.key ?? error}
                            messageParams={custom?.message?.options}
                            actionLabel={custom?.action?.label}
                            onClose={() => handleToastClose(error)}
                            onAction={() => handleToastAction(error)}
                        />
                    );
                })}
        </>
    );
};

export default ErrorHandler;
