import { useTranslation } from 'react-i18next';
import ActionButton from 'components/buttons/action-button/action-button';
import ModalSuccess from 'components/modals/modal-success/modal-success';
import './reset-success.scss';

export interface ResetSuccessProps {
    open: boolean;
    onClose: () => void;
}

export const ResetSuccess = (props: ResetSuccessProps) => {
    const { t } = useTranslation();

    return (
        <ModalSuccess open={props.open}>
            <div className="content__modal-reset-password-success--itens">
                <div className="content__modal-reset-password-success--image" />
                <div className="content__modal-reset-password-success--title">{t('pages.password-reset.success.title')}</div>
                <div className="content__modal-reset-password-success--message">{t('pages.password-reset.success.message')}</div>
                <ActionButton label={t('pages.password-reset.success.action').toUpperCase()} onClick={props.onClose} />
            </div>
        </ModalSuccess>
    );
};

export default ResetSuccess;
