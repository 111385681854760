import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const BATCH_MAX_LENGTH_VALIDATION: Validations<string | undefined> = {
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(20),
        errorMessage: '',
    },
};

export const validateBatchCodeLength = (value: string | undefined) => ValidationUtils.validate(value, BATCH_MAX_LENGTH_VALIDATION);
