import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.password-reset';

const PASSWORD_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.password.validation.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(4),
        errorMessage: `${I18N_PREFIX}.password.validation.minLength`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(100),
        errorMessage: `${I18N_PREFIX}.password.validation.maxLength`,
    },
};
export const validateUserPassword = (value: string | undefined) => ValidationUtils.validate(value, PASSWORD_VALIDATION);

export interface PasswordMatch {
    password: string;
    confirm: string;
}

const PASSWORD_MATCH_VALIDATION: Validations<PasswordMatch | undefined> = {
    required: {
        predicate: it => !!it?.confirm,
        errorMessage: `${I18N_PREFIX}.confirm-password.validation.required`,
    },
    minLength: {
        predicate: it => !!it?.confirm.length && it.confirm.length >= 4,
        errorMessage: `${I18N_PREFIX}.confirm-password.validation.minLength`,
    },
    maxLength: {
        predicate: it => !it?.confirm.length || it.confirm.length <= 100,
        errorMessage: `${I18N_PREFIX}.confirm-password.validation.maxLength`,
    },
    match: {
        predicate: it => it?.password === it?.confirm,
        errorMessage: `${I18N_PREFIX}.confirm-password.validation.match`,
    },
};
export const validateUserPasswordMatch = ({ password, confirm }: PasswordMatch) => ValidationUtils.validate({ password, confirm }, PASSWORD_MATCH_VALIDATION);

export const validatePasswordCreate = (value: PasswordMatch): boolean => {
    if (!value) return false;
    return validateUserPassword(value.password).isValid && validateUserPasswordMatch(value).isValid;
};
