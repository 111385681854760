import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.profile.user.password.validation';

const PASSWORD_VALIDATION: Validations<string> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.required`,
    },
    min_length: {
        predicate: ValidationUtils.STRING.minLength(4),
        errorMessage: `${I18N_PREFIX}.min-length`,
    },
    max_length: {
        predicate: ValidationUtils.STRING.maxLength(100),
        errorMessage: `${I18N_PREFIX}.max-length`,
    },
};
export const validatePassword = (value: string) => ValidationUtils.validate(value, PASSWORD_VALIDATION);

export interface PasswordMatch {
    password: string;
    match: string;
}

const PASSWORD_MATCH_VALIDATION: Validations<PasswordMatch> = {
    required: {
        predicate: it => !!it && !!it.match,
        errorMessage: `${I18N_PREFIX}.required`,
    },
    min_length: {
        predicate: it => !!it?.match?.length && it.match.length >= 4,
        errorMessage: `${I18N_PREFIX}.min-length`,
    },
    max_length: {
        predicate: it => !it?.match?.length || it.match.length <= 100,
        errorMessage: `${I18N_PREFIX}.max-length`,
    },
    match: {
        predicate: it => !!it && !!it.password && !!it.match && it.password === it.match,
        errorMessage: `${I18N_PREFIX}.match`,
    },
};

export const validatePasswordMatch = (value: PasswordMatch) => ValidationUtils.validate(value, PASSWORD_MATCH_VALIDATION);
