import { AxiosResponse } from 'axios';
import { BatchBanker, BatchBankerFilterPageable, ScdBatchBankerDetail } from 'model/batch';
import { api } from 'services/api/api';
import { Page } from 'services/page';

const URL_BASE = 'api/banker/batches';

const batchBanker = () => {
    const listbatchBanker = (request: BatchBankerFilterPageable): Promise<AxiosResponse<Page<BatchBanker>>> => {
        const { filter, pageable } = request;

        const _requestFilters = [
            filter?.date?.start && filter?.date.end ? `date.start=${filter.date.start}&date.end=${filter.date.end}` : undefined,
            filter?.date?.start && !filter?.date.end ? `date.start=${filter.date.start}` : undefined,
            filter?.date?.end && !filter?.date.start ? `date.end=${filter.date.end}` : undefined,

            filter?.status && `status=${filter?.status}`,

            filter?.productType ? `productType=${filter.productType}` : undefined,
            filter?.code ? `code=${filter.code}` : undefined,
            filter?.nominalValue?.minValue ? `nominalValue.minValue=${filter?.nominalValue?.minValue}` : undefined,
            filter?.nominalValue?.maxValue ? `nominalValue.maxValue=${filter?.nominalValue?.maxValue}` : undefined,
        ]
            .filter(it => !!it)
            .join('&');

        return api.get<Page<BatchBanker>>(`${URL_BASE}?${_requestFilters}`, { params: { ...pageable } });
    };

    const detailBatchBanker = (batchId: number): Promise<AxiosResponse<ScdBatchBankerDetail>> => {
        return api.get<ScdBatchBankerDetail>(`${URL_BASE}/${batchId}`);
    };

    return {
        listbatchBanker,
        detailBatchBanker,
    };
};

export default batchBanker();
