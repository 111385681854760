import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.payment-transaction.imported-operations.table';

export type ImportedOperationsDataSortableProperties = 'ccb_number' | 'batch_code' | 'product' | 'total_value' | 'accepted_date' | 'identification' | 'disbursement_value' ;

interface ScdImportedOperationsTableHeaderProps {
    handleSort: (property: ImportedOperationsDataSortableProperties, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabled?: boolean;
}

export const ScdImportedOperationsTableHeader = (props: ScdImportedOperationsTableHeaderProps) => {
    const { t } = useTranslation();
    const { disabled = false, handleSort, sortedProperty, sortOrder } = props;

    const mapSortable = (_property: ImportedOperationsDataSortableProperties) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.ccb`).toUpperCase()} sortable={mapSortable('ccb_number')} />
            <SortableColumn label={t(`${I18N_PREFIX}.batch`).toUpperCase()} sortable={mapSortable('batch_code')} />
            <SortableColumn label={t(`${I18N_PREFIX}.acceptedDate`).toUpperCase()} sortable={mapSortable('accepted_date')} />
            <SortableColumn label={t(`${I18N_PREFIX}.product.header`).toUpperCase()} sortable={mapSortable('product')} />
            <SortableColumn label={t(`${I18N_PREFIX}.favored.header`).toUpperCase()} sortable={mapSortable('identification')} />
            <SortableColumn label={t(`${I18N_PREFIX}.amount`).toUpperCase()} sortable={mapSortable('total_value')} />
            <SortableColumn label={t(`${I18N_PREFIX}.disbursementValue`).toUpperCase()} sortable={mapSortable('disbursement_value')} />
        </tr>
    );
};

export default ScdImportedOperationsTableHeader;
