import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListImportedOperationsByPaymentTransactionTypes, ListImportedOperationsByPaymentTransactionState } from './types';

const initialState: ListImportedOperationsByPaymentTransactionState = {
    status: HttpRequestStatus.NOOP,
    importedOperations: undefined,
    error: undefined,
};

const reducer: Reducer<ListImportedOperationsByPaymentTransactionState> = (state = initialState, action): ListImportedOperationsByPaymentTransactionState => {
    switch (action.type) {
        case ListImportedOperationsByPaymentTransactionTypes.LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ListImportedOperationsByPaymentTransactionTypes.LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, importedOperations: action.payload, error: undefined };
        }
        case ListImportedOperationsByPaymentTransactionTypes.LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListImportedOperationsByPaymentTransactionTypes.LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listImportedOperationsByPaymentTransactionReducer };
