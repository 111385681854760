import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BatchBankerMoreOptionsMenu from './menu/batch-more-options-menu';

import './batch-more-options-icon-button.scss';
import { BatchBanker } from 'model/batch';

export interface BatchBankerMoreOptionsIconButtonProps {
    disabled?: boolean;
    selectedBatch: BatchBanker | undefined;
    onClick: () => void;
}

const BatchBankerMoreOptionsIconButton = (props: BatchBankerMoreOptionsIconButtonProps): JSX.Element => {
    const { t } = useTranslation();

    const { onClick, selectedBatch } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        onClick();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="button-batch__style--more-options-icon">
            <Button variant="contained" onClick={handleClick} disabled={props.disabled}>
                <Tooltip title={t('tooltips.more-options') ?? ''} aria-label="more-options">
                    <Icon>
                        <div className="button-batch__style--more-options-icon--icon" />
                    </Icon>
                </Tooltip>
            </Button>
            <BatchBankerMoreOptionsMenu open={open} anchorEl={anchorEl} handleClose={handleClose} batchId={selectedBatch?.id} />
        </div>
    );
};
export default BatchBankerMoreOptionsIconButton;
