import { ScdPaymentTransactionVoucher } from 'model/payment-transaction';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum DetailPaymentTransactionVoucherTypes {
    DETAIL_PAYMENT_TRANSACTION_VOUCHER_REQUEST = '@@payment-transaction-voucher-detail/DETAIL_PAYMENT_TRANSACTION_VOUCHER_REQUEST',
    DETAIL_PAYMENT_TRANSACTION_VOUCHER_SUCCESS = '@@payment-transaction-voucher-detail/DETAIL_PAYMENT_TRANSACTION_VOUCHER_SUCCESS',
    DETAIL_PAYMENT_TRANSACTION_VOUCHER_ERROR = '@@payment-transaction-voucher-detail/DETAIL_PAYMENT_TRANSACTION_VOUCHER_ERROR',
    DETAIL_PAYMENT_TRANSACTION_VOUCHER_RESET_STATE = '@@payment-transaction-voucher-detail/DETAIL_PAYMENT_TRANSACTION_VOUCHER_RESET_STATE',
}

export interface DetailPaymentTransactionVoucherState extends BaseStoreState {
    readonly paymentTransactionVoucher?: ScdPaymentTransactionVoucher;
}
