import PageUserRoleNotValid from 'features/page-user-role-not-valid/page-user-role-not-valid';
import { useMemo } from 'react';
import { useUserAccountState } from 'reducer/hooks';

interface RouteRolePermissionValidationComponentProps {
    rolesAllowed?: string[];
    children: React.ReactNode;
}

const RouteRolePermissionValidationComponent = (props: RouteRolePermissionValidationComponentProps) => {
    const { rolesAllowed, children } = props;

    const { account } = useUserAccountState();

    const authorizedAccount: boolean | undefined = useMemo(() => account?.authorities?.some(it => rolesAllowed?.includes(it)), [account?.authorities, rolesAllowed]);

    return authorizedAccount ? <>{children}</> : <PageUserRoleNotValid />;
};

export default RouteRolePermissionValidationComponent;
