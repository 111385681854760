import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ImportedOperationToBatchDetail } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import { listImportedOperationsByBatchRequest, listImportedOperationsByBatchSuccess, listImportedOperationsByBatchError } from './actions';
import { ListImportedOperationsByBatchTypes } from './types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleImportedOperationsListByBatch(action: ReturnType<typeof listImportedOperationsByBatchRequest>) {
    try {
        const result: AxiosResponse<Page<ImportedOperationToBatchDetail>> = yield call(importedOperationApi.listImportedOperationsToBatch, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(listImportedOperationsByBatchError(mapped));
            return;
        }
        yield put(listImportedOperationsByBatchSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listImportedOperationsByBatchError(mapped));
    }
}

function* watchImportedOperationsListByBatch() {
    yield takeEvery(ListImportedOperationsByBatchTypes.LIST_IMPORTED_OPERATIONS_BY_BATCH_REQUEST, handleImportedOperationsListByBatch);
}

function* listImportedOperationsByBatchSaga() {
    yield all([fork(watchImportedOperationsListByBatch)]);
}

export default listImportedOperationsByBatchSaga;
