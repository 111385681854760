import { RouteComponentProps, Switch } from 'react-router-dom';

import ScdCcbOperations from 'features/ccb-operation/ccb-operation';

import PrivateRoute from 'shared/routes/private-route';
import ScdCcbOperationDetail from './ccb-operation-detail/ccb-operation-detail';
import { Authority } from 'model/enums/authorities';

interface MatchParams {
    url: string;
}

const CcbOperationRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}`} component={ScdCcbOperations} rolesAllowed={[Authority.ROLE_BANKER]} />
        <PrivateRoute exact path={`${match.url}/view-ccb/:operationId`} component={ScdCcbOperationDetail} rolesAllowed={[Authority.ROLE_BANKER]} />
    </Switch>
);

export default CcbOperationRoutes;
