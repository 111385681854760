import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { balancePaymentAccountReducer } from './balance-payment-accounts/reducer';
import balancePaymentAccountSaga from './balance-payment-accounts/sagas';
import { BalancePaymentAccountState } from './balance-payment-accounts/types';
import { listPaymentAccountsReducer } from './list-payment-accounts/reducer';
import listPaymentAccountsSaga from './list-payment-accounts/sagas';
import { ListPaymentAccountsState } from './list-payment-accounts/types';

export interface PaymentAccountsState {
    readonly listPaymentAccounts: ListPaymentAccountsState;
    readonly balancePaymentAccount: BalancePaymentAccountState;
}

export const PaymentAccountsReducer: ReducersMapObject<PaymentAccountsState, AnyAction> = {
    listPaymentAccounts: listPaymentAccountsReducer,
    balancePaymentAccount: balancePaymentAccountReducer,
};

export function* paymentAccountsSagas() {
    yield all([fork(listPaymentAccountsSaga), fork(balancePaymentAccountSaga)]);
}
