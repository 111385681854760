import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';
import './loan-operation-card.scss';

interface LoanOperationCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.loan';

const LoanOperationCard = (props: LoanOperationCardProps) => {
    const {
        operation: { resumeDetailOperation },
    } = props;

    const { t } = useTranslation();

    return (
        <div className="scd-loan-operation-card">
            <h3 className="scd-loan-operation-card--title"> {t(`${I18N_PREFIX}.title`)} </h3>
            <div className="scd-loan-operation-card--item">
                <span className="scd-loan-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.totalValue`)} </span>
                <span className="scd-loan-operation-card--item__total-value">
                    <CurrencyFormatter value={resumeDetailOperation?.totalValue} prefix />
                </span>
            </div>
            <div className="scd-loan-operation-card--item">
                <span className="scd-loan-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.storeTransferValue`)} </span>
                <span className="scd-loan-operation-card--item__value">
                    <CurrencyFormatter value={resumeDetailOperation?.storeTransferValue} prefix />
                </span>
            </div>
            <div className="scd-loan-operation-card--item">
                <span className="scd-loan-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.installmentValue`)} </span>
                <span className="scd-loan-operation-card--item__value">
                    <CurrencyFormatter value={resumeDetailOperation?.installmentValue} prefix />
                </span>
            </div>
            <div className="scd-loan-operation-card--item">
                <span className="scd-loan-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.titleFare`)} </span>
                <span className="scd-loan-operation-card--item__value">
                    <CurrencyFormatter value={resumeDetailOperation?.titleFare} prefix />
                </span>
            </div>
            <div className="scd-loan-operation-card--item">
                <span className="scd-loan-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.firstDueDate`)} </span>
                <span className="scd-loan-operation-card--item__value">
                    <DateFormatter date={resumeDetailOperation?.firstDueDate} />
                </span>
            </div>
        </div>
    );
};

export default LoanOperationCard;
