import StandardButton from 'components/buttons/standard-button/standard-button';
import { useTranslation } from 'react-i18next';
import { OperationApprovalMoreOptionsType } from '../more-options-icon-button/menu/operation-approval-more-options-menu';

import './operation-approval-slide.scss';

interface OperationApprovalSlideProps {
    onCancel?: () => void;
    operationApprovalsIds: number[];
    setModalType: (value: OperationApprovalMoreOptionsType) => void;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-approval.slide';

const OperationApprovalSlide = (props: OperationApprovalSlideProps) => {
    const { onCancel, operationApprovalsIds, setModalType } = props;

    const { t } = useTranslation();

    return (
        <div className="operation-approval-slide">
            <div className="operation-approval-slide--container">
                <div className="operation-approval-slide--content" onClick={onCancel ? () => onCancel() : () => setModalType('cancel')}>
                    <span className="operation-approval-slide--item__action">{t(`${I18N_PREFIX}.cancel`).toUpperCase()}</span>
                </div>
                <div className="scd-operation-slide--content__selected-operations">
                    {t(`${I18N_PREFIX}.selected.total`, {
                        count: operationApprovalsIds.length,
                    })}
                </div>
                <div className="operation-approval-slide--content">
                    <StandardButton label={t(`${I18N_PREFIX}.approve`)} disabled={!operationApprovalsIds.length} onClick={() => setModalType('approveMultiplesOperations')} />
                </div>
            </div>
        </div>
    );
};

export default OperationApprovalSlide;
