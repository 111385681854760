import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// List Imported Operations
import { ListImportedOperationsState } from './list-imported-operations/types';
import { listImportedOperationsReducer } from './list-imported-operations/reducer';
import listImportedOperationsSaga from './list-imported-operations/sagas';

// Detail Imported Operation
import { DetailImportedOperationState } from './detail-imported-operation/types';
import { detailImportedOperationReducer } from './detail-imported-operation/reducer';
import detailImportedOperationSaga from './detail-imported-operation/sagas';

// List Imported Operation By Batch
import { ListImportedOperationsByBatchState } from './list-imported-operation-by-batch/types';
import { listImportedOperationsByBatchReducer } from './list-imported-operation-by-batch/reducer';
import listImportedOperationsByBatchSaga from './list-imported-operation-by-batch/sagas';
export interface ImportedOperationState {
    readonly listImportedOperations: ListImportedOperationsState;
    readonly detailImportedOperation: DetailImportedOperationState;
    readonly listImportedOperationsByBatch: ListImportedOperationsByBatchState;
}

export const ImportedOperationReducer: ReducersMapObject<ImportedOperationState, AnyAction> = {
    listImportedOperations: listImportedOperationsReducer,
    detailImportedOperation: detailImportedOperationReducer,
    listImportedOperationsByBatch: listImportedOperationsByBatchReducer,
};

export function* importedOperationSagas() {
    yield all([fork(listImportedOperationsSaga), fork(detailImportedOperationSaga), fork(listImportedOperationsByBatchSaga)]);
}
