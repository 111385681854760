import CloseButton from 'components/buttons/close-button/close-button';
import BatchRoutes from 'features/batch';
import CcbOperationRoutes from 'features/ccb-operation';
// Routes
import Dashboard from 'features/dashboard/dashboard';
import ForgotPassword from 'features/forgot-password/forgot-password';
// Pages
import Login from 'features/login/login';
import PageNotFound from 'features/page-not-found/page-not-found';
import PasswordReset from 'features/password-reset/password-reset';
import PaymentTransactionRoutes from 'features/payment-transaction';
import { Profile } from 'features/profile/profile';
import RegisterFinishPage from 'features/register-finish/register-finish';

import { Authority } from 'model/enums/authorities';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import ErrorHandler from 'shared/error/error-handler';
import PrivateRoute from 'shared/routes/private-route';
import PublicRoute from 'shared/routes/public-route';
import OperationsApprovalRoutes from 'features/ccb-operation-approval';
import PaymentAccountsRoutes from 'features/payment-accounts';

export interface AppRoutesProps {
    basename: string;
}

const AppRoutes = (props: AppRoutesProps) => {
    return (
        <BrowserRouter basename={props.basename}>
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} className="toastify-container" toastClassName="toastify-toast" hideProgressBar={true} pauseOnHover={true} closeButton={<CloseButton />} />
            <ErrorHandler />
            <Switch>
                <Route exact path="/" component={Login} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} rolesAllowed={[Authority.ROLE_BANKER]} />
                <PrivateRoute exact path="/profile" component={Profile} rolesAllowed={[Authority.ROLE_BANKER]} />

                <Route path="/payment-transactions" component={PaymentTransactionRoutes} />
                <Route path="/imported-operations" component={CcbOperationRoutes} />
                <Route path="/batches" component={BatchRoutes} />
                <Route path="/operations-approval" component={OperationsApprovalRoutes} />
                <Route path="/payment-accounts" component={PaymentAccountsRoutes} />

                <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
                <PublicRoute exact path="/password-reset/:key" component={PasswordReset} />

                <PublicRoute exact path="/register-finish/:key" component={RegisterFinishPage} />

                <PublicRoute component={PageNotFound} />
            </Switch>
        </BrowserRouter>
    );
};

export default AppRoutes;
