import Modal from '@material-ui/core/Modal';
import React from 'react';
import './modal-success.scss';

export interface ModalSuccessProps {
    open: boolean;
}

export const ModalSuccess: React.FunctionComponent<ModalSuccessProps> = props => {
    return (
        <Modal className="body__modal--style" open={props.open} onClose={(reason = 'backdropClick') => reason} disableEscapeKeyDown>
            <div className="container__content--style">{props.children}</div>
        </Modal>
    );
};

export default ModalSuccess;
