import { Authority } from 'model/enums/authorities';
import MenuItem, { MenuItemPropsList } from 'shared/banker-navbar/components/menu-item/menu-item';

const I18N_PREFIX = 'navbar.entities';


export const BankerEntities = () => {
    const entities: MenuItemPropsList[] = [
        {
            translation: `${I18N_PREFIX}.dashboard.main`,
            rolesAllowedMainMenu: [Authority.ROLE_BANKER],
            visibility: false,
            submenu: [
                {
                    link: `/dashboard`,
                    translation: `${I18N_PREFIX}.dashboard.submenu.dashboard`,
                    visibility: false,
                    rolesAllowedSubmenu: [Authority.ROLE_BANKER],
                },
            ],
        },
        {
            translation: `${I18N_PREFIX}.transactions.main`,
            rolesAllowedMainMenu: [Authority.ROLE_BANKER],
            visibility: true,
            submenu: [
                {
                    link: '/payment-transactions',
                    translation: `${I18N_PREFIX}.transactions.submenu.transactions`,
                    visibility: true,
                    rolesAllowedSubmenu: [Authority.ROLE_BANKER],
                },
            ],
        },
        {
            translation: `${I18N_PREFIX}.imported-operation.main`,
            rolesAllowedMainMenu: [Authority.ROLE_BANKER],
            visibility: true,
            submenu: [
                {
                    link: '/imported-operations',
                    translation: `${I18N_PREFIX}.imported-operation.submenu.imported-operation`,
                    visibility: true,
                    rolesAllowedSubmenu: [Authority.ROLE_BANKER],
                },
                {
                    link: '/operations-approval',
                    translation: `${I18N_PREFIX}.imported-operation.submenu.pending-operations`,
                    visibility: true,
                    rolesAllowedSubmenu: [Authority.ROLE_BANKER_APPROVAL],
                },
            ],
        },
        {
            translation: `${I18N_PREFIX}.batches.main`,
            rolesAllowedMainMenu: [Authority.ROLE_BANKER],
            visibility: true,
            submenu: [
                {
                    link: '/batches',
                    translation: `${I18N_PREFIX}.batches.submenu.batches`,
                    visibility: true,
                    rolesAllowedSubmenu: [Authority.ROLE_BANKER],
                },
            ],
        },
        {
            translation: `${I18N_PREFIX}.accounts.main`,
            rolesAllowedMainMenu: [Authority.ROLE_BANKER],
            visibility: true,
            submenu: [
                {
                    link: '/payment-accounts/balance',
                    translation: `${I18N_PREFIX}.accounts.submenu.balance`,
                    visibility: true,
                    rolesAllowedSubmenu: [Authority.ROLE_BANKER],
                },
            ],
        },
    ];

    return <MenuItem list={entities} menu={true}/>;
};

export default BankerEntities;
