import { useTranslation } from 'react-i18next';
import ModalSuccess from 'components/modals/modal-success/modal-success';
import ButtonAction from 'components/buttons/action-button/action-button';
import './modal-forgot-password.scss';

const I18N_PREFIX = 'pages.forgot-password.success';
export interface ModalForgotPasswordProps {
    open: boolean;
    closeModal: () => void;
}

export const ModalForgotPassword = (props: ModalForgotPasswordProps) => {
    const { t } = useTranslation();

    const handleClose = () => {
        props.closeModal();
    };

    return (
        <ModalSuccess open={props.open}>
            <div className="content__modal-forgot-password--itens">
                <div className="content__modal-forgot-password--image" />
                <div className="content__modal-forgot-password--title">{t(`${I18N_PREFIX}.title`)}</div>
                <div className="content__modal-forgot-password--message">{t(`${I18N_PREFIX}.message`)}</div>
                <ButtonAction label={t(`${I18N_PREFIX}.action`).toUpperCase()} onClick={handleClose} />
            </div>
        </ModalSuccess>
    );
};

export default ModalForgotPassword;
