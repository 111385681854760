import { Modal } from 'reactstrap';
import CloseButton from 'components/buttons/close-button/close-button';
import { BatchBankerFilterRequest } from 'model/batch';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';
import { BatchBankerStatus } from 'model/enums/batch-status';
import { Grid } from '@material-ui/core';
import SimpleDatePickerInput from 'components/inputs/date-input/date-picker/simple-date-picker';

import clsx from 'clsx';
import { ProductType } from 'model/enums/product-type';
import SimpleCurrencyInput from 'components/inputs/currency-input/simple-currency-input';
import ClearButton from 'components/buttons/clear-button/clear-button';
import StandardButton from 'components/buttons/standard-button/standard-button';

import './batch-modal-filter.scss';

import ValidTextNumberInput from 'components/inputs/text-number-input/valid-text-number-input';
import { validateBatchCodeLength } from 'features/batch/validation/batch-code-validation';

const I18N_PREFIX = 'pages.scd.batch';

export type BatchModalFilterType = 'filters' | 'date' | 'product' | 'code' | 'nominalValue' | 'status';

interface BatchModalFilterProps {
    openModal: boolean;
    title: string;
    onClose: () => void;
    modalFilter: BatchModalFilterType;
    setFilterActive: (value: BatchBankerFilterRequest) => void;
    filterActive: BatchBankerFilterRequest;
    setPage: (value: number) => void;
}

export const DISABLED_BATCH_MODAL_BUTTON_FUNCTION_RECORD = {
    filters: filter => valuesOfObject({ ...filter, status: filter?.status?.length ? filter.status : undefined }).filter(it => it !== undefined).length === 0,
    date: filter => !filter.date,
    product: filter => !filter.productType,
    code: filter => !filter.code,
    nominalValue: filter => !filter.nominalValue,
    status: filter => !filter.status?.length,
} as Record<BatchModalFilterType, (filter: BatchBankerFilterRequest) => boolean>;

const BatchModalFilter = (props: BatchModalFilterProps) => {
    const { openModal, title, onClose, modalFilter, setFilterActive, filterActive, setPage } = props;

    const [filterRequest, setFilterRequest] = useState<BatchBankerFilterRequest>(filterActive);

    const { t } = useTranslation();

    useEffect(() => {
        if (filterActive) {
            setFilterRequest(filterActive);
        }
    }, [filterActive]);

    if (!openModal) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        setPage(0);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<BatchBankerFilterRequest>) => {
        setFilterRequest({ ...filterRequest, ...value });
    };

    const handleChangeStatus = (batchStatus: BatchBankerStatus) => {
        const hasSelectedStatus = filterRequest?.status?.some(status => status === batchStatus);

        if (hasSelectedStatus) {
            const filteredData = filterRequest?.status?.filter(status => status !== batchStatus);
            handleChange({ status: filteredData });
            return;
        }
        handleChange({ status: filterRequest?.status?.concat(batchStatus) });
    };

    const handleClearFilter = {
        filters: () => {
            setFilterActive({});
            setFilterRequest({});
        },
        date: () => {
            setFilterActive({ ...filterActive, date: undefined });
            setFilterRequest({ ...filterRequest, date: undefined });
        },
        product: () => {
            setFilterActive({ ...filterActive, productType: undefined });
            setFilterRequest({ ...filterRequest, productType: undefined });
        },
        code: () => {
            setFilterActive({ ...filterActive, code: undefined });
            setFilterRequest({ ...filterRequest, code: undefined });
        },
        nominalValue: () => {
            setFilterActive({ ...filterActive, nominalValue: undefined });
            setFilterRequest({ ...filterRequest, nominalValue: undefined });
        },
        status: () => {
            setFilterActive({ ...filterActive, status: [] });
            setFilterRequest({ ...filterRequest, status: [] });
        },
    } as Record<BatchModalFilterType, () => void>;

    const DateFilter: JSX.Element = (
        <>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    label={t(`${I18N_PREFIX}.inputs.initialDate`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.initialDate`)}
                    value={filterRequest.date?.start!}
                    onChange={initialDate => handleChange({ date: { ...filterRequest.date, start: initialDate } })}
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    label={t(`${I18N_PREFIX}.inputs.finalDate`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.finalDate`)}
                    value={filterRequest.date?.end!}
                    onChange={finalDate => handleChange({ date: { ...filterRequest.date, end: finalDate } })}
                />
            </Grid>
        </>
    );

    const ProductFilter: JSX.Element = (
        <div className="batch-modal-filter--content">
            <div
                className={clsx('batch-modal-filter--content-product--label', {
                    active: filterRequest.productType === ProductType.CREDIT_CARD,
                })}
                onClick={() => handleChange({ productType: ProductType.CREDIT_CARD })}
            >
                {t(`${I18N_PREFIX}.product.options.CREDIT_CARD`).toUpperCase()}
            </div>

            <div
                className={clsx('batch-modal-filter--content-product--label', {
                    active: filterRequest.productType === ProductType.DIRECT_CONSUMER_CREDIT,
                })}
                onClick={() => handleChange({ productType: ProductType.DIRECT_CONSUMER_CREDIT })}
            >
                {t(`${I18N_PREFIX}.product.options.DIRECT_CONSUMER_CREDIT`).toUpperCase()}
            </div>

            <div
                className={clsx('batch-modal-filter--content-product--label', {
                    active: filterRequest.productType === ProductType.PERSONAL_CREDIT,
                })}
                onClick={() => handleChange({ productType: ProductType.PERSONAL_CREDIT })}
            >
                {t(`${I18N_PREFIX}.product.options.PERSONAL_CREDIT`).toUpperCase()}
            </div>
        </div>
    );

    const CodeFilter: JSX.Element = (
        <>
            {
                <Grid item xs={12}>
                    <ValidTextNumberInput
                        label={t(`${I18N_PREFIX}.inputs.code`)}
                        placeholder={t(`${I18N_PREFIX}.inputs.code`)}
                        value={filterRequest?.code ?? ''}
                        onChange={code => handleChange({ code })}
                        externalUpdate
                        validate={validateBatchCodeLength}
                        showValidation={false}
                    />
                </Grid>
            }
        </>
    );

    const NominalValueFilter: JSX.Element = (
        <>
            <Grid item xs={6}>
                <SimpleCurrencyInput
                    label={t(`${I18N_PREFIX}.inputs.minValue`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.minValue`)}
                    onChange={minValue => handleChange({ nominalValue: { ...filterRequest.nominalValue, minValue } })}
                    value={filterRequest?.nominalValue?.minValue}
                    externalUpdate
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleCurrencyInput
                    label={t(`${I18N_PREFIX}.inputs.maxValue`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.maxValue`)}
                    value={filterRequest?.nominalValue?.maxValue}
                    onChange={maxValue => handleChange({ nominalValue: { ...filterRequest.nominalValue, maxValue } })}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const StatusFilter: JSX.Element = (
        <div className="batch-modal-filter--content">
            <div
                className={clsx('batch-modal-filter--content-status--label', {
                    RECEIVED: filterRequest?.status?.some(status => status === BatchBankerStatus.RECEIVED),
                })}
                onClick={() => handleChangeStatus(BatchBankerStatus.RECEIVED)}
            >
                {t(`${I18N_PREFIX}.status.options.RECEIVED`).toUpperCase()}
            </div>
            <>
                <div
                    className={clsx('batch-modal-filter--content-status--label', {
                        PROCESSED: filterRequest?.status?.some(status => status === BatchBankerStatus.PROCESSED),
                    })}
                    onClick={() => handleChangeStatus(BatchBankerStatus.PROCESSED)}
                >
                    {t(`${I18N_PREFIX}.status.options.PROCESSED`).toUpperCase()}
                </div>
                <div
                    className={clsx('batch-modal-filter--content-status--label', {
                        CLOSED: filterRequest?.status?.some(status => status === BatchBankerStatus.CLOSED),
                    })}
                    onClick={() => handleChangeStatus(BatchBankerStatus.CLOSED)}
                >
                    {t(`${I18N_PREFIX}.status.options.CLOSED`).toUpperCase()}
                </div>
                <div
                    className={clsx('batch-modal-filter--content-status--label', {
                        ERROR: filterRequest?.status?.some(status => status === BatchBankerStatus.ERROR),
                    })}
                    onClick={() => handleChangeStatus(BatchBankerStatus.ERROR)}
                >
                    {t(`${I18N_PREFIX}.status.options.ERROR`).toUpperCase()}
                </div>
            </>
        </div>
    );

    const isConfirmButtonDisabled = DISABLED_BATCH_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled = DISABLED_BATCH_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    return (
        openModal && (
            <Modal isOpen>
                <div className="batch-modal-filter">
                    <div className="batch-modal-filter--header">
                        <label className="batch-modal-filter--header-title">{title}</label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            {DateFilter}
                                            {ProductFilter}
                                            {CodeFilter}
                                            {NominalValueFilter}
                                            {StatusFilter}
                                        </>
                                    ),
                                    date: DateFilter,
                                    product: ProductFilter,
                                    code: CodeFilter,
                                    nominalValue: NominalValueFilter,
                                    status: StatusFilter,
                                } as Record<BatchModalFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>
                    <div className="batch-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButton label="entity.action.confirm" onClick={handleConfirmRequest} disabled={isConfirmButtonDisabled} />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default BatchModalFilter;
