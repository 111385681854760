import { GiroWebappError } from 'model/error';
import { ImportedOperation, ImportedOperationFilterPageable } from 'model/imported-operation';
import { ListImportedOperationsTypes } from './types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listImportedOperationsRequest = (request: ImportedOperationFilterPageable) => action(ListImportedOperationsTypes.LIST_IMPORTED_OPERATIONS_REQUEST, request);
export const listImportedOperationsSuccess = (data: Page<ImportedOperation>) => action(ListImportedOperationsTypes.LIST_IMPORTED_OPERATIONS_SUCCESS, data);
export const listImportedOperationsError = (error: GiroWebappError) => action(ListImportedOperationsTypes.LIST_IMPORTED_OPERATIONS_ERROR, error);
export const listImportedOperationsResetState = () => action(ListImportedOperationsTypes.LIST_IMPORTED_OPERATIONS_RESET_STATE);
