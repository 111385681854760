import { useTranslation } from 'react-i18next';
import './readonly-text-field.scss';

export interface ReadOnlyTextFieldProps {
    label?: string;
    value: React.ReactNode;
}

export const ReadOnlyTextField = (props: ReadOnlyTextFieldProps) => {
    const { label, value } = props;
    const { t } = useTranslation();

    return (
        <dl className="readonly__text-field">
            {label && <dt className="readonly__text-field-label">{t(label)}</dt>}
            <dd className="readonly__text-field-content">{value ?? '-'}</dd>
        </dl>
    );
};

export default ReadOnlyTextField;
