import SimpleHeader from 'shared/header/simple-header';
import BankerNavbar from 'shared/banker-navbar/banker-navbar';

export enum HeaderType {
    NONE = 'NONE',
    SIMPLE = 'SIMPLE',
    BANKER = 'BANKER',
}

export interface HeaderProps {
    type: HeaderType;
}

export const Header = (props: HeaderProps) => {
    switch (props.type) {
        case HeaderType.BANKER:
            return <BankerNavbar />;
        case HeaderType.SIMPLE:
            return <SimpleHeader />;
        case HeaderType.NONE:
        default:
            return <></>;
    }
};

export default Header;
