import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { PersonType } from 'model/enums/person-type';
import { Trans, useTranslation } from 'react-i18next';
import { useListImportedOperationsByBatchState } from 'reducer/hooks';
import { getPersonType } from 'shared/util/person-type-utils';

const I18N_PREFIX = 'pages.scd.batch.imported-operations';

const ScdImportedOperationsBatchList = () => {
    const { t } = useTranslation();

    const { operations } = useListImportedOperationsByBatchState();

    const getFavoredTranslation = (identification: string, name: string): React.ReactNode => {
        if (identification === undefined || identification === null) return name ?? '-';

        if (getPersonType(identification) === PersonType.INDIVIDUAL) {
            return <Trans i18nKey={`${I18N_PREFIX}.favored.fullFavored`} t={t} values={{ name: name }} components={{ identification: <IdentificationFormatter value={identification} type={PersonType.INDIVIDUAL} /> }} />;
        }

        return <Trans i18nKey={`${I18N_PREFIX}.favored.fullFavored`} t={t} values={{ name: name }} components={{ identification: <IdentificationFormatter value={identification} type={PersonType.CORPORATION} /> }} />;
    };

    return (
        <>
            {operations?.content?.map(({ id, ccbNumber, acceptedDate, productType, debtorIdentification, debtorName, companyName, totalValue }) => (
                <tr className="page-container--table-cell" key={id}>
                    <td className="page-container--table-cell-title">{ccbNumber ?? '-'}</td>
                    <td className="page-container--table-cell-title">{acceptedDate ? <DateFormatter date={acceptedDate} /> : '00/00/00'}</td>
                    <td className="page-container--table-cell-title">
                        <span>{t(`${I18N_PREFIX}.product.options.${productType}`)}</span>
                    </td>
                    <td title='' className="page-container--table-cell-title">
                        <span>{getFavoredTranslation(debtorIdentification, companyName ?? debtorName)}</span>
                    </td>
                    <td className="page-container--table-cell-title">{<CurrencyFormatter value={totalValue ?? 0} prefix />}</td>
                </tr>
            ))}
        </>
    );
};

export default ScdImportedOperationsBatchList;
