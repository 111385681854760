import { Menu, MenuItem, PopoverOrigin } from '@material-ui/core';
import { PaymentTransactionStatus } from 'model/enums/payment-transaction-status';
import { useTranslation } from 'react-i18next';
import './payment-transaction-more-option-menu.scss';
import RedirectMenuItem from 'components/more-options-menu/redirect-menu-item/redirect-menu-item';
import { useRouteMatch } from 'react-router-dom';
import { ScdPaymentTransactionData } from 'model/payment-transaction';

const I18N_PREFIX = 'pages.scd.payment-transaction.more-options';

export type PaymentTransactionMoreOptionsType = 'importedOperations' | 'details';

export interface PaymentTransactionMoreOptionsMenuProps {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
    onSelectItem: Record<PaymentTransactionMoreOptionsType, () => void>;
    selectedPaymentTransaction?: ScdPaymentTransactionData
}

const transformOrigin: PopoverOrigin = {
    vertical: -38,
    horizontal: 0,
};
const anchorOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center',
};

const PaymentTransactionsMoreOptionsMenu = (props: PaymentTransactionMoreOptionsMenuProps) => {
    const { open, anchorEl, handleClose, onSelectItem, selectedPaymentTransaction } = props;

    const { t } = useTranslation();
    const { url } = useRouteMatch();

    return (
        <Menu className="button__style--more-options--menu-menuitems" elevation={0} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} anchorEl={anchorEl} open={open} onClose={handleClose}>
            <RedirectMenuItem link={`${url}/${selectedPaymentTransaction?.id}/imported-operations`} titleKey={t(`${I18N_PREFIX}.imported-operations`)} handleClose={handleClose}  />
            {selectedPaymentTransaction?.status &&
                (
                    {
                        CONFIRMED: (
                            <MenuItem className="button__style--more-options--menu-menuitems-item" onClick={onSelectItem['details']}>
                                {t(`${I18N_PREFIX}.details`)}
                            </MenuItem>
                        ),
                    } as Record<PaymentTransactionStatus, React.ReactNode>
                )[selectedPaymentTransaction?.status]}
        </Menu>
    );
};

export default PaymentTransactionsMoreOptionsMenu;
