import FormatterUtils from 'components/formatter/formatter-utils';
import { PersonType } from 'model/enums/person-type';

export type IdentificationValue = string | undefined;

export interface IdentificationFormatterProps {
    value: IdentificationValue;
    type: PersonType;
}

export const IdentificationFormatter = (props: IdentificationFormatterProps) => {
    const { value, type } = props;

    return <>{FormatterUtils.formatIdentification(type, value)}</>;
};

export const formatIdentification = FormatterUtils.formatIdentification;

export default IdentificationFormatter;
