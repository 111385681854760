import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.batch.imported-operations.table';

export type ImportedOperationsByBatchSortableData = 'ccbNumber' | 'acceptedDate' | 'product' | 'personName' | 'totalValue' | 'createdDate';

interface ScdImportedOperationsBatchTableHeaderProps {
    handleSort: (property: ImportedOperationsByBatchSortableData, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabled?: boolean;
}

const ScdImportedOperationsBatchTableHeader = (props: ScdImportedOperationsBatchTableHeaderProps) => {
    const { t } = useTranslation();
    const { handleSort, sortOrder, sortedProperty, disabled = false } = props;

    const mapSortable = (_property: ImportedOperationsByBatchSortableData) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.ccbNumber`).toUpperCase()} sortable={mapSortable('ccbNumber')} />
            <SortableColumn label={t(`${I18N_PREFIX}.acceptedDate`).toUpperCase()} sortable={mapSortable('acceptedDate')} />
            <SortableColumn label={t(`${I18N_PREFIX}.productType`).toUpperCase()} sortable={mapSortable('product')} />
            <SortableColumn label={t(`${I18N_PREFIX}.debtorName`).toUpperCase()} sortable={mapSortable('personName')} />
            <SortableColumn label={t(`${I18N_PREFIX}.totalValue`).toUpperCase()} sortable={mapSortable('totalValue')} />
        </tr>
    );
};

export default ScdImportedOperationsBatchTableHeader;
