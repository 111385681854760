import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { PaymentAccountsToGetAllResponse } from 'model/payment-accounts';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import paymentAccountsApi from 'services/api/payment-accounts-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { listPaymentAccountsError, listPaymentAccountsSuccess } from './actions';
import { ListPaymentAccountsTypes } from './types';

function* handleListPaymentAccounts() {
    try {
        const result: AxiosResponse<PaymentAccountsToGetAllResponse> = yield call(
            paymentAccountsApi.listPaymentAccounts
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(listPaymentAccountsError(mapped));
            return;
        }
        yield put(listPaymentAccountsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listPaymentAccountsError(mapped));
    }
}

function* watchListPaymentAccounts() {
    yield takeEvery(ListPaymentAccountsTypes.LIST_PAYMENT_ACCOUNTS_REQUEST, handleListPaymentAccounts);
}

function* listPaymentAccountsSaga() {
    yield all([fork(watchListPaymentAccounts)]);
}

export default listPaymentAccountsSaga;
