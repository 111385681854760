import { RouteComponentProps, Switch } from 'react-router-dom';

import ScdPaymentTransaction from 'features/payment-transaction/payment-transaction';

import PrivateRoute from 'shared/routes/private-route';
import ScdPaymentTransactionImportedOperations from './imported-operations/payment-transaction-imported-operations';
import { Authority } from 'model/enums/authorities';

interface MatchParams {
    url: string;
}

const PaymentTransactionRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}`} component={ScdPaymentTransaction} rolesAllowed={[Authority.ROLE_BANKER]} />
        <PrivateRoute exact path={`${match.url}/:paymentTransactionId/imported-operations`} component={ScdPaymentTransactionImportedOperations} rolesAllowed={[Authority.ROLE_BANKER]} />
    </Switch>
);

export default PaymentTransactionRoutes;
