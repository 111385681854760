import { useState, useMemo, useEffect, ReactNode, useCallback } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import uniqueId from 'lodash/uniqueId';

import { useTranslation } from 'react-i18next';
import { ValidationResult } from 'components/inputs/validation/input-validation';

export interface ValidationOptions {
    error: boolean;
}

export interface ValidInputProps<T> {
    value: T;
    validate: (value: T) => ValidationResult;
    showValidation: boolean;
    children: (v: ValidationOptions) => ReactNode;
    className?: string;
}

export const ValidInput = <T extends {}>(props: ValidInputProps<T>) => {
    const { value: propsValue, validate, showValidation } = props;
    const UID = useMemo(() => uniqueId('help-text-'), []);
    const [value, setValue] = useState<T>(propsValue);
    const [validation, setValidation] = useState<ValidationResult>(validate(value));

    const { t } = useTranslation();

    const validateFunction = useCallback(() => validate, [validate]);

    useEffect(() => {
        setValue(ps => (ps === propsValue ? ps : propsValue));
    }, [propsValue]);

    useEffect(() => {
        if (showValidation) {
            const _validation = validate(value);
            setValidation(ps => (ps.isValid === _validation.isValid && ps.errorMessage === _validation.errorMessage ? ps : _validation));
        }
    }, [validateFunction, value, showValidation, validate]);

    const error: boolean = showValidation && !validation.isValid;
    const helpText: string | undefined = showValidation ? validation.errorMessage : undefined;
    return (
        <FormControl fullWidth className={props.className}>
            {props.children({ error })}
            {error && (
                <FormHelperText id={UID} error={error}>
                    {helpText && t(helpText)}
                </FormHelperText>
            )}
        </FormControl>
    );
};

export default ValidInput;
