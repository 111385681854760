import { OperationApproval } from 'model/operation-approvals';
import { BaseStoreState } from 'reducer/baseStoreState';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListOperationApprovalTypes {
    LIST_OPERATION_APPROVALS_REQUEST = '@@list-operation-approvals/LIST_OPERATION_APPROVAL_REQUEST',
    LIST_OPERATION_APPROVALS_SUCCESS = '@@list-operation-approvals/LIST_OPERATION_APPROVAL_SUCCESS',
    LIST_OPERATION_APPROVALS_ERROR = '@@list-operation-approvals/LIST_OPERATION_APPROVAL_ERROR',
    LIST_OPERATION_APPROVALS_RESET_STATE = '@@list-operation-approvals/LIST_OPERATION_APPROVAL_RESET_STATE',
}

export interface ListOperationApprovalState extends BaseStoreState {
    readonly operationApprovals?: Page<OperationApproval>;
}
