import { AxiosResponse } from 'axios';
import { ImportedOperation, ScdImportedOperationDetail, ImportedOperationFilterPageable, ImportedOperationToBatchDetail, ImportedOperationToBatchRequest } from 'model/imported-operation';
import { api } from 'services/api/api';
import { Page } from 'services/page';

const URL_BASE = 'api/banker/imported-operations';

const importedOperation = () => {
    const listImportedOperations = (request: ImportedOperationFilterPageable): Promise<AxiosResponse<Page<ImportedOperation>>> => {
        const { filter, pageable } = request;

        const _requestFilters = [
            filter?.acceptedDate?.start && filter?.acceptedDate.end ? `acceptedDate.start=${filter.acceptedDate.start}&acceptedDate.end=${filter.acceptedDate.end}` : undefined,
            filter?.acceptedDate?.start && !filter?.acceptedDate.end ? `acceptedDate.start=${filter.acceptedDate.start}` : undefined,
            filter?.acceptedDate?.end && !filter?.acceptedDate.start ? `acceptedDate.end=${filter.acceptedDate.end}` : undefined,

            filter?.status && `status=${filter?.status}`,

            filter?.productType ? `productType=${filter.productType}` : undefined,
            filter?.ccbNumber ? `ccbNumber=${filter.ccbNumber}` : undefined,
            filter?.batchCode ? `batchCode=${filter.batchCode}` : undefined,
            filter?.debtorIdentification ? `debtorIdentification=${filter.debtorIdentification}` : undefined,
        ]
            .filter(it => !!it)
            .join('&');

        return api.get<Page<ImportedOperation>>(`${URL_BASE}?${_requestFilters}`, { params: { ...pageable } });
    };

    const detailImportedOperation = (operationId: number): Promise<AxiosResponse<ScdImportedOperationDetail>> => {
        return api.get<ScdImportedOperationDetail>(`${URL_BASE}/${operationId}`);
    };

    const listImportedOperationsToBatch = (request: ImportedOperationToBatchRequest): Promise<AxiosResponse<Page<ImportedOperationToBatchDetail>>> => {
        const { batchId, pageable } = request;
        return api.get<Page<ImportedOperationToBatchDetail>>(`${URL_BASE}/batches/${batchId}`, { params: { ...pageable } });
    };

    return {
        listImportedOperations,
        detailImportedOperation,
        listImportedOperationsToBatch,
    };
};

export default importedOperation();
