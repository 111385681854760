import { Pageable } from 'services/pageable';
import { BatchBankerStatus } from './enums/batch-status';
import { ProductType } from './enums/product-type';
import { LocalDatePeriod } from './local-date';

export interface BatchBanker {
    id?: number;
    status?: BatchBankerStatus;
    productType?: ProductType;
    code?: string;
    date?: Date;
    numberOfOperations?: number;
    nominalValue?: number;
}

export interface BatchBankerFilterRequest {
    code?: string;
    productType?: ProductType;
    date?: LocalDatePeriod;
    nominalValue?: {
        minValue?: number;
        maxValue?: number;
    };
    status?: BatchBankerStatus[];
}

export interface BatchBankerFilterPageable {
    pageable?: Pageable;
    filter?: BatchBankerFilterRequest;
}

export const defaultBatchBankerFilter: Readonly<BatchBankerFilterRequest> = {
    status: [],
};
export interface ScdBatchBankerDetail {
    id?: number;
    date?: Date;
    code?: string;
    productType?: ProductType;
    originatorName?: string;
    numberOfOperations?: number;
    has_program?: boolean;
    has_originator?: boolean;
}
