import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import BaseSelectInput, { BaseSelectInputProps } from 'components/inputs/select-input/base-select-input';

type SimpleSelectInputProps<T> = BaseSelectInputProps<T>;

export const SimpleSelectInput = <T extends {}>(props: SimpleSelectInputProps<T>) => {
    return (
        <FormControl fullWidth className={clsx('select-input', { dirty: props.value })}>
            <BaseSelectInput {...props} />
        </FormControl>
    );
};

export default SimpleSelectInput;
