import H from 'history';
import { useHistory } from 'react-router-dom';
import { useRootDispatch } from 'reducer/hooks';
import MenuItem, { LinkAction, MenuItemPropsList } from 'shared/banker-navbar/components/menu-item/menu-item';

import profileSvg from 'images/profile.svg';
import { Authority } from 'model/enums/authorities';
import { userAccountResetState } from 'reducer/account/user-account/actions';
import { loginResetState } from 'reducer/authentication/actions';
import authUtil from 'services/api/authUtil';

export const BankerAccountMenu = () => {
    const history: H.History = useHistory();
    const dispatch = useRootDispatch();

    const handleLogout: LinkAction = () => {
        authUtil.removeToken();
        dispatch(loginResetState());
        dispatch(userAccountResetState());
        history.push('/');
    };

    const account: MenuItemPropsList[] = [
        {
            translation: 'navbar.account.main',
            rolesAllowedMainMenu: [Authority.ROLE_BANKER],
            visibility: true,
            submenu: [
                {
                    link: '/profile',
                    translation: 'navbar.account.main',
                    visibility: true,
                    rolesAllowedSubmenu: [Authority.ROLE_BANKER],
                },
                {
                    link: '/',
                    translation: 'navbar.account.logout',
                    visibility: true,
                    action: handleLogout,
                    rolesAllowedSubmenu: [Authority.ROLE_BANKER],
                },
            ],
        },
    ];

    return <MenuItem list={account} icon={profileSvg} />;
};

export default BankerAccountMenu;
