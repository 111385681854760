import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { BatchBanker } from 'model/batch';
import { markError } from 'reducer/application/error/actions';
import { listBatchBankerRequest, listBatchBankerError, listBatchBankerSuccess } from './actions';
import { ListBatchBankerTypes } from './types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchBankerApi from 'services/api/batch-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleBatchBankerList(action: ReturnType<typeof listBatchBankerRequest>) {
    try {
        const result: AxiosResponse<Page<BatchBanker>> = yield call(batchBankerApi.listbatchBanker, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(listBatchBankerError(mapped));
            return;
        }
        yield put(listBatchBankerSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listBatchBankerError(mapped));
    }
}

function* watchBatchBankerList() {
    yield takeEvery(ListBatchBankerTypes.LIST_BATCH_BANKER_REQUEST, handleBatchBankerList);
}

function* listBatchBankerSaga() {
    yield all([fork(watchBatchBankerList)]);
}

export default listBatchBankerSaga;
