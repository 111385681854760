import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { OperationApproval } from 'model/operation-approvals';
import { markError } from 'reducer/application/error/actions';
import { listOperationApprovalsRequest, listOperationApprovalsError, listOperationApprovalsSuccess } from './actions';
import { ListOperationApprovalTypes } from './types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import operationApprovalApi from 'services/api/operation-approvals-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleOperationApprovalsList(action: ReturnType<typeof listOperationApprovalsRequest>) {
    try {
        const result: AxiosResponse<Page<OperationApproval>> = yield call(operationApprovalApi.listOperationApproval, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(listOperationApprovalsError(mapped));
            return;
        }
        yield put(listOperationApprovalsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listOperationApprovalsError(mapped));
    }
}

function* watchOperationApprovalsList() {
    yield takeEvery(ListOperationApprovalTypes.LIST_OPERATION_APPROVALS_REQUEST, handleOperationApprovalsList);
}

function* listOperationApprovalsSaga() {
    yield all([fork(watchOperationApprovalsList)]);
}

export default listOperationApprovalsSaga;
