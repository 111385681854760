import ProfileEditing from 'features/profile/components/profile-editing/profile-editing';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PasswordChange } from 'model/user';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { userAccountResetState } from 'reducer/account/user-account/actions';
import { loginResetState } from 'reducer/authentication/actions';
import { useChangePasswordState, useRootDispatch } from 'reducer/hooks';
import { changePasswordRequest, changePasswordResetState } from 'reducer/profile/change-password/actions';
import authUtil from 'services/api/authUtil';
import { useToast } from 'shared/hooks/use-toast';
import { validatePassword, validatePasswordMatch } from './components/validation/validation';
import './profile.scss';

const I18N_PREFIX = 'pages.scd.profile';

export const Profile = () => {
    const { t } = useTranslation();
    const parentRef = React.useRef<HTMLDivElement>(null);
    const history = useHistory();
    const dispatch = useRootDispatch();
    const { toastSuccess } = useToast();

    const changePasswordState = useChangePasswordState();

    const [changePassword, setChangePassword] = React.useState<PasswordChange>({} as PasswordChange);
    const [showValidation, setShowValidation] = React.useState<boolean>(false);

    const isUpdating = changePasswordState.status === HttpRequestStatus.ON_GOING;
    const updateSuccess = changePasswordState.status === HttpRequestStatus.SUCCESS;

    const disabled = isUpdating ? 'disabled' : '';

    const handleSave = () => {
        setShowValidation(true);

        if (!validatePassword(changePassword.currentPassword).isValid) return;
        if (!validatePasswordMatch({ password: changePassword.newPassword, match: changePassword.newPasswordConfirm }).isValid) return;

        dispatch(changePasswordRequest(changePassword));
    };

    const handlePasswordChange = (update: Partial<PasswordChange>) => setChangePassword(ps => ({ ...ps, ...update }));

    useEffect(() => {
        if (updateSuccess) {
            toastSuccess(t(`${I18N_PREFIX}.user.password.change-success`));
            dispatch(changePasswordResetState());
        }
    }, [toastSuccess, updateSuccess, t, dispatch]);

    const saveEdition = (evt: React.MouseEvent) => {
        disabled ? evt.preventDefault() : handleSave();
    };

    const handleLogout = () => {
        authUtil.removeToken();
        dispatch(loginResetState());
        dispatch(userAccountResetState());
        history.push('/');
    };
    return (
        <div id="profile" style={{ background: 'white' }} className="user-profile__items">
            <div className="personal-data__items">
                <div className="personal-data__items--header">
                    <Container>
                        <Row>
                            <>
                                <Col style={{ paddingRight: '5' }} md={{ size: '2', offset: '2' }}>
                                    <Link to="/dashboard" className="license-name__items--cancel">
                                        {t('pages.scd.profile.user.password.cancel').toUpperCase()}
                                    </Link>
                                </Col>
                                <Col style={{ paddingLeft: '0' }} md="2">
                                    <div className={`license-name__items--save`} onClick={e => saveEdition(e)}>
                                        {t('pages.scd.profile.user.password.save').toUpperCase()}
                                    </div>
                                </Col>
                            </>
                        </Row>
                    </Container>
                </div>
                <div className="container">
                    <Row>
                        <Col md="2">
                            <div className="personal-data__items--sidebar-one">
                                <div className="personal-data__items--sidebar-title">{t(`${I18N_PREFIX}.user.title`)}</div>
                                <a href="#password">
                                    <div className={`personal-data__items--sidebar-subtitle`}>
                                        {t(`${I18N_PREFIX}.user.password.title`)}
                                        <div className="personal-data__items--sidebar-icon" />
                                    </div>
                                </a>
                                <div className="personal-data__items--sidebar-subtitle" onClick={handleLogout}>
                                    {t(`${I18N_PREFIX}.exit`)}
                                    <div className="personal-data__items--sidebar-icon" />
                                </div>
                            </div>
                        </Col>

                        <Col md="9">
                            <div ref={parentRef}>{<ProfileEditing changePassword={changePassword} handlePasswordChange={handlePasswordChange} isUpdating={isUpdating} showValidation={showValidation} />}</div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default Profile;
