import { Authority } from 'model/enums/authorities';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useUserAccountState } from 'reducer/hooks';
import { usePrivateRouteRedirect } from 'shared/hooks/use-private-route-redirect';

export const useRedirectToDashboard = () => {
    const history = useHistory();

    const userAccountState = useUserAccountState();

    usePrivateRouteRedirect();

    useEffect(() => {
        if (userAccountState.status !== HttpRequestStatus.SUCCESS) return;
        if (userAccountState.account?.activated !== true) return;
        if (userAccountState.account.authorities?.every(it => it !== Authority.ROLE_BANKER)) return;

        history.push('/dashboard');
    }, [history, userAccountState]);
};
