import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Change Password
import { ChangePasswordState } from 'reducer/profile/change-password/types';
import changePasswordSaga from 'reducer/profile/change-password/sagas';
import { changePasswordReducer } from 'reducer/profile/change-password/reducer';
export interface ProfileState {
    readonly userPasswordChange: ChangePasswordState;
}

export const ProfileReducer: ReducersMapObject<ProfileState, AnyAction> = {
    userPasswordChange: changePasswordReducer,
};

export function* profileSagas() {
    yield all([fork(changePasswordSaga)]);
}
