import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { UserAccount } from 'model/user';
import { userAccountError, userAccountSuccess } from 'reducer/account/user-account/actions';
import { UserAccountActionTypes } from 'reducer/account/user-account/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import accountApi from 'services/api/accountApi';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleUserAccount() {
    try {
        const result: AxiosResponse<UserAccount> = yield call(accountApi.recoverUserAccount);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(userAccountError(mapped));
            return;
        }
        yield put(userAccountSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(userAccountError(mapped));
    }
}

function* watchUserAccountRequest() {
    yield takeEvery(UserAccountActionTypes.USER_ACCOUNT_REQUEST, handleUserAccount);
}

function* userAccountSaga() {
    yield all([fork(watchUserAccountRequest)]);
}

export default userAccountSaga;
