import { Button, Icon, Tooltip } from '@material-ui/core';
import { OperationApproval } from 'model/operation-approvals';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OperationApprovalMoreOptionsMenu, { OperationApprovalMoreOptionsType } from './menu/operation-approval-more-options-menu';
import './operation-approval-more-options-icon-button.scss';

export interface OperationApprovalMoreOptionsIconButtonProps {
    disabled?: boolean;
    operationApproval: OperationApproval | undefined;
    onClick: () => void;
    setModalType: (value: OperationApprovalMoreOptionsType | undefined) => void;
}

const OperationApprovalMoreOptionsIconButton = (props: OperationApprovalMoreOptionsIconButtonProps) => {
    const { onClick, operationApproval, setModalType } = props;

    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        onClick();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div className="button-operation-approval__style--more-options-icon">
                <Button variant="contained" onClick={handleClick}>
                    <Tooltip title={t('tooltips.more-options') ?? ''} aria-label="more-options">
                        <Icon>
                            <div className="button-operation-approval__style--more-options-icon--icon" />
                        </Icon>
                    </Tooltip>
                </Button>
                <OperationApprovalMoreOptionsMenu
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    operationApproval={operationApproval}
                    setModalType={setModalType}
                />
            </div>
        </>
    );
};

export default OperationApprovalMoreOptionsIconButton;
