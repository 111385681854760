import rootReducer, { IRootState, rootSaga } from 'reducer';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

const initialize = (initialState?: IRootState) => {
    const sagaMiddleware = createSagaMiddleware();

    const store = createStore(rootReducer, initialState, applyMiddleware(thunk, sagaMiddleware));

    sagaMiddleware.run(rootSaga);

    return store;
};

export default initialize;
