import ContextRibbon from 'components/context-ribbon/context-ribbon';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { detailBatchBankerRequest, detailBatchBankerResetState } from 'reducer/batch/detail-batch/actions';
import { listImportedOperationsByBatchRequest, listImportedOperationsByBatchResetState } from 'reducer/imported-operation/list-imported-operation-by-batch/actions';
import { ImportedOperationToBatchRequest } from 'model/imported-operation';
import { useDetailBatchBankerState, useRootDispatch, useListImportedOperationsByBatchState } from 'reducer/hooks';
import ScdImportedOperationsBatchList from './components/imported-operations-batch-list/imported-operations-batch-list';
import ScdImportedOperationsBatchTableHeader, { ImportedOperationsByBatchSortableData } from './components/imported-operations-batch-table-header/imported-operations-batch-table-header';
import { SortOrder } from 'services/sort';
import { LIST_PAGE_SIZE } from 'services/pageable';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import BackButton from 'components/buttons/back-button/back-button';
import Loading from 'components/loading/loading';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import EmptyCollectionAware from 'components/collection/empty-collection-aware';
import Pagination from 'shared/pagination/pagination';

import './imported-operations-batch.scss';

const I18N_PREFIX = 'pages.scd.batch';

type ScdImportedOperationsBatchProps = RouteComponentProps<{ batchId: string }>;

const ScdImportedOperationsBatch = (props: ScdImportedOperationsBatchProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useRootDispatch();

    const [batchId] = useState<number>(Number(props.match.params.batchId));
    const [page, setPage] = useState<number>(0);
    const [sortedProperty, setSortedProperty] = useState<string | undefined>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

    const sortPageable = useCallback((): ImportedOperationToBatchRequest => {
        const _pageable = {
            page,
            size: LIST_PAGE_SIZE,
            sort: sortedProperty && sortOrder ? `${sortedProperty},${sortOrder}` : '',
        };

        return {
            pageable: _pageable,
            batchId,
        };
    }, [page, sortedProperty, sortOrder, batchId]);

    const { batch, status: batchDetailStatus } = useDetailBatchBankerState();
    const { operations, status: listOperationsStatus } = useListImportedOperationsByBatchState();

    const handleGetHeaderInfoByBatchId = useCallback(() => {
        dispatch(detailBatchBankerRequest(batchId));
    }, [dispatch, batchId]);

    const handleListOperationsByBatch = useCallback(() => {
        dispatch(listImportedOperationsByBatchRequest(sortPageable()));
    }, [dispatch, sortPageable]);

    useEffect(() => {
        handleGetHeaderInfoByBatchId();
    }, [handleGetHeaderInfoByBatchId]);

    useEffect(() => {
        handleListOperationsByBatch();
    }, [handleListOperationsByBatch]);

    useEffect(() => {
        return () => {
            dispatch(listImportedOperationsByBatchResetState());
            dispatch(detailBatchBankerResetState());
        };
    }, [dispatch]);

    const hasError = batchDetailStatus === HttpRequestStatus.ERROR || listOperationsStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t(`${I18N_PREFIX}.error-message`)}</div>
                <BackButton label={t('global.try-again')} onClick={handleListOperationsByBatch} />
            </div>
        );
    }

    const isLoading = batchDetailStatus !== HttpRequestStatus.SUCCESS || listOperationsStatus !== HttpRequestStatus.SUCCESS;

    const handlePageChange = (value: { selected: number }) => {
        setPage(value.selected);
    };

    const handleSort = (property: ImportedOperationsByBatchSortableData, order: SortOrder) => {
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc' && property !== 'createdDate') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const isDisabledTableSort = operations?.content && operations.content?.length <= 1 ? true : false;

    return (
        <main className="scd-batch-imported-operations">
            <ContextRibbon action={() => history.push('/batches')} />
            <section className="scd-batch-imported-operations__container">
                <header className="scd-batch-imported-operations__header">
                    <h2 className="scd-batch-imported-operations__header--title">
                        {t(`${I18N_PREFIX}.product.options.${batch?.productType}`)} {batch?.code}
                    </h2>
                    <h4 className="scd-batch-imported-operations__header--info">
                        <Trans i18nKey={`${I18N_PREFIX}.batch-info`} t={t} values={{ name: batch?.originatorName }} components={{ date: <DateFormatter date={batch?.date} format={DateFormat.DAY_MONTH} /> }} />
                    </h4>
                </header>
                <div className="scd-batch-imported-operations__content">
                    <div className="scd-batch-imported-operations__content-header">
                        <h3 className="scd-batch-imported-operations__content-header--title"> {t(`${I18N_PREFIX}.imported-operations.table.title`)} </h3>
                        <span className="scd-batch-imported-operations__content-header--total-itens">{batch?.numberOfOperations ?? 0}</span>
                    </div>
                    <div className="scd-batch-imported-operations__content-table">
                        <table className="page-container--table">
                            <thead>
                                <ScdImportedOperationsBatchTableHeader handleSort={handleSort} sortedProperty={sortedProperty} sortOrder={sortOrder} disabled={isDisabledTableSort} />
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <Loading isTable />
                                ) : (
                                    <EmptyCollectionAware
                                        collection={operations?.content ?? []}
                                        isTable
                                        label={{
                                            key: `${I18N_PREFIX}.not-found`,
                                        }}
                                    >
                                        <ScdImportedOperationsBatchList />
                                    </EmptyCollectionAware>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {!isLoading && <Pagination page={page} totalPages={operations?.totalPages ?? 0} onChange={handlePageChange} />}
                </div>
            </section>
        </main>
    );
};

export default ScdImportedOperationsBatch;
