import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ImportedOperation } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import { listImportedOperationsError, listImportedOperationsRequest, listImportedOperationsSuccess } from './actions';
import { ListImportedOperationsTypes } from './types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleImportedOperationsList(action: ReturnType<typeof listImportedOperationsRequest>) {
    try {
        const result: AxiosResponse<Page<ImportedOperation>> = yield call(importedOperationApi.listImportedOperations, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(listImportedOperationsError(mapped));
            return;
        }
        yield put(listImportedOperationsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listImportedOperationsError(mapped));
    }
}

function* watchImportedOperationsList() {
    yield takeEvery(ListImportedOperationsTypes.LIST_IMPORTED_OPERATIONS_REQUEST, handleImportedOperationsList);
}

function* listImportedOperationsSaga() {
    yield all([fork(watchImportedOperationsList)]);
}

export default listImportedOperationsSaga;
