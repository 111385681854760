import DefaultFooter from 'shared/footer/default-footer';
import './footer.scss';

export enum FooterType {
    DEFAULT = 'DEFAULT',
    NONE = 'NONE',
}

export interface IFooterProps {
    type: FooterType;
}

export const Footer = (props: IFooterProps) => {
    switch (props.type) {
        case FooterType.DEFAULT:
            return <DefaultFooter />;
        case FooterType.NONE:
        default:
            return <></>;
    }
};

export default Footer;
