import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// User Account
import { userAccountReducer } from 'reducer/account/user-account/reducer';
import userAccountSaga from 'reducer/account/user-account/sagas';
import { UserAccountState } from 'reducer/account/user-account/types';

// Forgot Password
import { forgotPasswordReducer } from 'reducer/account/forgot-password/reducer';
import forgotPasswordSaga from 'reducer/account/forgot-password/sagas';
import { ForgotPasswordState } from 'reducer/account/forgot-password/types';

// Reset Password
import { userPasswordResetReducer } from './password-reset/reducer';
import userPasswordResetSaga from './password-reset/sagas';
import { UserPasswordResetState } from './password-reset/types';

export interface AccountState {
    readonly userAccount: UserAccountState;
    readonly forgotPassword: ForgotPasswordState;
    readonly passwordReset: UserPasswordResetState;
}

export const AccountReducer: ReducersMapObject<AccountState, AnyAction> = {
    userAccount: userAccountReducer,
    forgotPassword: forgotPasswordReducer,
    passwordReset: userPasswordResetReducer,
};

export function* accountSagas() {
    yield all([fork(userAccountSaga), fork(forgotPasswordSaga), fork(userPasswordResetSaga)]);
}
