import { INTL_LOCALE, CURRENCY_FIXED_DECIMAL, PERCENTAGE_FIXED_DECIMAL, INTL_CURRENCY } from 'config/constants';

export const CURRENCY_FORMATTER = new Intl.NumberFormat(INTL_LOCALE, {
    minimumFractionDigits: CURRENCY_FIXED_DECIMAL,
    maximumFractionDigits: CURRENCY_FIXED_DECIMAL,
});

export const CURRENCY_FORMATTER_PREFIXED = new Intl.NumberFormat(INTL_LOCALE, {
    style: 'currency',
    currency: INTL_CURRENCY,
    minimumFractionDigits: CURRENCY_FIXED_DECIMAL,
    maximumFractionDigits: CURRENCY_FIXED_DECIMAL,
});

export const PERCENTAGE_FORMATTER = new Intl.NumberFormat(INTL_LOCALE, {
    style: 'percent',
    minimumFractionDigits: PERCENTAGE_FIXED_DECIMAL,
    maximumFractionDigits: PERCENTAGE_FIXED_DECIMAL,
});
