import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// List Operation Approvals
import { ListOperationApprovalState } from './list-operation-approvals/types';
import { listOperationApprovalsReducer } from './list-operation-approvals/reducer';
import listOperationApprovalsSaga from './list-operation-approvals/sagas';

// Approve Operation Approval
import { ApproveOperationApprovalState } from './approve-operation/types';
import { approveOperationApprovalReducer } from './approve-operation/reducer';
import approveOperationApprovalSaga from './approve-operation/sagas';

// Disapprove Operation Approval
import { DisapproveOperationApprovalState } from './disapprove-operation/types';
import { disapproveOperationApprovalReducer } from './disapprove-operation/reducer';
import disapproveOperationApprovalSaga from './disapprove-operation/sagas';

// Approve Multiple Operations Approval
import { ApproveMultiplesOperationsApprovalState } from './approve-multiple-operations/types';
import { approveMultiplesOperationsApprovalReducer } from './approve-multiple-operations/reducer';
import approveMultiplesOperationsApprovalSaga from './approve-multiple-operations/sagas';

export interface OperationApprovalsState {
    readonly listOperationApprovals: ListOperationApprovalState;
    readonly approveOperationApproval: ApproveOperationApprovalState;
    readonly disapproveOperationApproval: DisapproveOperationApprovalState;
    readonly approveMultiplesOperationsApproval: ApproveMultiplesOperationsApprovalState;
}

export const OperationApprovalsReducer: ReducersMapObject<OperationApprovalsState, AnyAction> = {
    listOperationApprovals: listOperationApprovalsReducer,
    approveOperationApproval: approveOperationApprovalReducer,
    disapproveOperationApproval: disapproveOperationApprovalReducer,
    approveMultiplesOperationsApproval: approveMultiplesOperationsApprovalReducer,
};

export function* operationApprovalsSagas() {
    yield all([fork(listOperationApprovalsSaga), fork(approveOperationApprovalSaga), fork(disapproveOperationApprovalSaga), fork(approveMultiplesOperationsApprovalSaga)]);
}
