import { AxiosResponse } from 'axios';
import { UserAccount, UserPasswordResetRequest, UserNewPassword, UserSimple, PasswordChange } from 'model/user';
import { api } from './api';

export const accounts = () => {
    const recoverUserAccount = (): Promise<AxiosResponse<UserAccount>> => {
        return api.get<UserAccount>(`api/account`);
    };

    const hasChangedPassword = (user: PasswordChange): Promise<AxiosResponse<void>> => {
        return api.post<void>(`/api/account/change-password`, user);
    };
    const passwordResetInit = (payload: UserPasswordResetRequest): Promise<AxiosResponse<UserSimple>> => {
        return api.post<UserSimple>('api/banker/account/reset-password/init', payload.mail, { headers: { 'Content-Type': 'text/plain' } });
    };

    const passwordResetFinish = (payload: UserNewPassword): Promise<AxiosResponse<UserSimple>> => {
        return api.post<UserSimple>('api/account/reset-password/finish', payload);
    };

    return {
        recoverUserAccount,
        hasChangedPassword,
        passwordResetInit,
        passwordResetFinish,
    };
};

export default accounts();
