import { Pageable } from 'services/pageable';
import { PaymentTransactionStatus } from './enums/payment-transaction-status';
import { LocalDatePeriod } from './local-date';

export interface ScdPaymentTransactionData {
    id?: number;
    date?: Date;
    fallbackDateTime?: Date;
    amount?: number;
    favoredName?: string;
    favoredIdentification?: string;
    bankIdentification?: string;
    bankName: string;
    agencyNumber?: string;
    accountNumber?: string;
    pixKey?: string;
    status?: PaymentTransactionStatus;
}

export interface PaymentTransactionFilterRequest {
    amount?: {
        minAmount?: number;
        maxAmount?: number;
    };
    date?: LocalDatePeriod;
    favoredSearch?: string;
    accountSearch?: string;
    status?: PaymentTransactionStatus;
}

export const defaultPaymentTransactionFilterRequest: Readonly<PaymentTransactionFilterRequest> = {};

export interface PaymentTransactionFilterPageable {
    pageable?: Pageable;
    filter?: PaymentTransactionFilterRequest;
}

export interface PaymentTransactionToListImportedOperationsRequest {
    paymentTransactionId: number;
    pageable?: Pageable;
}

export interface ScdPaymentTransactionVoucher {
    favoredName?: string;
    favoredIdentification?: string;
    bankIdentification?: string;
    bankName: string;
    agencyNumber?: string;
    accountNumber?: string;
    pixKey?: string;
    origin?: string;
}
