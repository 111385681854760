import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'components/inputs/number-mask';

const textNumberMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    allowLeadingZeroes: true,
    allowDecimal: false,
    decimalLimit: 0,
    integerLimit: 20,
    requireDecimal: false,
});

export const TextNumberMask: React.ElementType<InputBaseComponentProps> = props => {
    // remove ref issue
    // https://github.com/text-mask/text-mask/issues/714
    const { inputRef, ...others } = props;
    return <MaskedInput {...others} mask={textNumberMask} guide={false} />;
};

export default TextNumberMask;
