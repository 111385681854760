import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './imported-operation-more-options-icon-button.scss';
import { ImportedOperation } from 'model/imported-operation';
import ImportedOperationMoreOptionsMenu from './menu/imported-operation-more-option-menu';

export interface ImportedOperationMoreOptionsIconButtonProps {
    disabled?: boolean;
    selectedImportedOperation: ImportedOperation | undefined;
    onClick: () => void;
}

const ImportedOperationMoreOptionsIconButton = (props: ImportedOperationMoreOptionsIconButtonProps): JSX.Element => {
    const { selectedImportedOperation, onClick } = props;

    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        onClick();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="button__style--more-options-icon">
            <Button variant="contained" onClick={handleClick} disabled={props.disabled}>
                <Tooltip title={t('tooltips.more-options') ?? ''} aria-label="more-options">
                    <Icon>
                        <div className="button__style--more-options-icon--icon" />
                    </Icon>
                </Tooltip>
            </Button>
            <ImportedOperationMoreOptionsMenu open={open} anchorEl={anchorEl} handleClose={handleClose} ccbOperationId={selectedImportedOperation?.id} />
        </div>
    );
};
export default ImportedOperationMoreOptionsIconButton;
