import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Col from 'reactstrap/lib/Col';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import './context-ribbon.scss';

export interface ContextRibbonProps {
    children?: React.ReactNode;
    action?: () => void;
}

export const ContextRibbon = (props: ContextRibbonProps) => {
    const history = useHistory();
    const { t } = useTranslation();

    const handleBack = () => {
        history.goBack();
    };

    return (
        <div className="context-ribbon">
            <Container>
                <Row className="context-ribbon-row">
                    <Col>
                        <div className="context-ribbon__back" onClick={props.action ? props.action : handleBack}>
                            <div className="context-ribbon__back-icon" />
                            <span>{t('global.back')} </span>
                        </div>
                    </Col>
                    <Col className="context-ribbon-child">{props.children}</Col>
                </Row>
            </Container>
        </div>
    );
};

export default ContextRibbon;
