import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import "./redirect-menu-item.scss"

export type RedirectItemType = {
    link: string;
    titleKey: string;
    show?: boolean;
    handleClose?: () => void;
};

const RedirectMenuItem = (props: RedirectItemType) => {
    const { link, titleKey, show, handleClose } = props;

    const { t } = useTranslation();

    if (show === false) {
        return <></>;
    }
   
    return (
        <Link to={link} className="redirect__style--more-options">
            <MenuItem onClick={handleClose}>{t(titleKey)}</MenuItem>
        </Link>
    );
};

export default RedirectMenuItem;