import { ImportedOperation } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/baseStoreState';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum ListImportedOperationsTypes {
    LIST_IMPORTED_OPERATIONS_REQUEST = '@@list-imported-operations/LIST_IMPORTED_OPERATIONS_REQUEST',
    LIST_IMPORTED_OPERATIONS_SUCCESS = '@@list-imported-operations/LIST_IMPORTED_OPERATIONS_SUCCESS',
    LIST_IMPORTED_OPERATIONS_ERROR = '@@list-imported-operations/LIST_IMPORTED_OPERATIONS_ERROR',
    LIST_IMPORTED_OPERATIONS_RESET_STATE = '@@list-imported-operations/LIST_IMPORTED_OPERATIONS_RESET_STATE',
}

export interface ListImportedOperationsState extends BaseStoreState {
    readonly operations?: Page<ImportedOperation>;
}


