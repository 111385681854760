import { AxiosResponse } from 'axios';
import { DisapproveOperationApprovalRequest, OperationApproval, OperationApprovalErrorResponse, OperationApprovalFilterPageable, OperationApprovalSimple, OperationApprovalToApproveMultiples } from 'model/operation-approvals';
import { api } from 'services/api/api';
import { Page } from 'services/page';

const URL_BASE = 'api/banker/operations/approvals';

const operationApproval = () => {
    const listOperationApproval = (request: OperationApprovalFilterPageable): Promise<AxiosResponse<Page<OperationApproval>>> => {
        const { filter, pageable } = request;

        const _requestFilters = [filter?.status && `status=${filter?.status}`, filter?.ccbNumber ? `ccbNumber=${filter.ccbNumber}` : undefined].filter(it => !!it).join('&');

        return api.get<Page<OperationApproval>>(`${URL_BASE}?${_requestFilters}`, { params: { ...pageable } });
    };

    const approveOperationApproval = (operationApprovalId: number): Promise<AxiosResponse<OperationApprovalSimple>> => {
        return api.patch<OperationApprovalSimple>(`${URL_BASE}/${operationApprovalId}/approve`);
    };

    const disapproveOperationApproval = (request: DisapproveOperationApprovalRequest): Promise<AxiosResponse<OperationApprovalSimple>> => {
        return api.patch<OperationApprovalSimple>(`${URL_BASE}/${request.id}/disapprove`, request);
    };

    const approveMultiplesOperations = (request: OperationApprovalToApproveMultiples): Promise<AxiosResponse<OperationApprovalErrorResponse>> => {
        return api.patch<OperationApprovalErrorResponse>(`${URL_BASE}/approve`, request);
    };

    return {
        listOperationApproval,
        approveOperationApproval,
        disapproveOperationApproval,
        approveMultiplesOperations,
    };
};

export default operationApproval();
