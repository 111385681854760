import FormatterUtils from 'components/formatter/formatter-utils';

export enum DateFormat {
    DATE = 'DATE',
    YEAR_MONTH = 'YEAR_MONTH',
    DATETIME_UTC = 'DATETIME_UTC',
    DATETIME_COMPLETE_UTC = 'DATETIME_COMPLETE_UTC',
    DATETIME_WITHOUT_SECONDS_UTC = 'DATETIME_WITHOUT_SECONDS_UTC',
    DATE_TO_MONTH_STRING = 'DATE_TO_MONTH_STRING',
    DAY_MONTH = 'DAY_MONTH',
}

export interface DateProps {
    date: Date | undefined;
    format?: DateFormat;
}

export const DateFormatter = (props: DateProps) => {
    return (
        <>
            {
                {
                    DATE: FormatterUtils.formatDate(props.date),
                    YEAR_MONTH: FormatterUtils.formatYearMonthDate(props.date),
                    DAY_MONTH: FormatterUtils.formatDayMonthDate(props.date),
                    DATETIME_UTC: FormatterUtils.formatDateTimeUTC(props.date),
                    DATETIME_COMPLETE_UTC: FormatterUtils.formatDateTimeCompleteUTC(props.date),
                    DATETIME_WITHOUT_SECONDS_UTC: FormatterUtils.formatDateTimeWithoutSecondsUTC(props.date),
                    DATE_TO_MONTH_STRING: FormatterUtils.formatDateToMonthString(props.date),
                }[props.format ?? DateFormat.DATE]
            }
        </>
    );
};

export default DateFormatter;
