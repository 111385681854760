import { GiroWebappError } from 'model/error';
import { ImportedOperationToBatchDetail, ImportedOperationToBatchRequest } from 'model/imported-operation';
import { ListImportedOperationsByBatchTypes } from './types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listImportedOperationsByBatchRequest = (request: ImportedOperationToBatchRequest) => action(ListImportedOperationsByBatchTypes.LIST_IMPORTED_OPERATIONS_BY_BATCH_REQUEST, request);
export const listImportedOperationsByBatchSuccess = (data: Page<ImportedOperationToBatchDetail>) => action(ListImportedOperationsByBatchTypes.LIST_IMPORTED_OPERATIONS_BY_BATCH_SUCCESS, data);
export const listImportedOperationsByBatchError = (error: GiroWebappError) => action(ListImportedOperationsByBatchTypes.LIST_IMPORTED_OPERATIONS_BY_BATCH_ERROR, error);
export const listImportedOperationsByBatchResetState = () => action(ListImportedOperationsByBatchTypes.LIST_IMPORTED_OPERATIONS_BY_BATCH_RESET_STATE);
