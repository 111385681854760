import FormatterUtils from 'components/formatter/formatter-utils';

export interface RgFormatterProps {
    value: string;
}

export const RgFormatter = (props: RgFormatterProps) => {
    return <>{FormatterUtils.formatRg(props.value)}</>;
};

export default RgFormatter;
