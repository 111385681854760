import clsx from 'clsx';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import { BatchBanker, BatchBankerFilterRequest } from 'model/batch';
import { BatchBankerStatus } from 'model/enums/batch-status';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useListBatchBankerState } from 'reducer/hooks';
import { valuesOfObject } from 'shared/util/object-utils';
import BatchBankerMoreOptionsIconButton from '../more-options-icon-button/batch-more-options-icon-button';

interface BatchesListProps {
    filterActive: BatchBankerFilterRequest;
}

const I18N_PREFIX = 'pages.scd.batch';

const BatchesList = (props: BatchesListProps) => {
    const { filterActive } = props;

    const { t } = useTranslation();

    const [selectedBatch, setSelectedBatch] = useState<BatchBanker | undefined>(undefined);

    const { batches } = useListBatchBankerState();

    const showInvalidFilterMessage = valuesOfObject({ ...filterActive, status: filterActive?.status?.length ? filterActive.status : undefined }).some(it => it !== undefined);

    return (
        <>
            {batches?.content?.length !== 0 ? (
                batches?.content?.map(batch => {
                    const { date, productType, code, nominalValue, numberOfOperations, status, id } = batch;
                    return (
                        <tr className="page-container--table-cell" key={id}>
                            <td className="page-container--table-cell-title">{date ? <DateFormatter date={date} /> : '00/00/00'}</td>
                            <td className="page-container--table-cell-title" title={productType ?? ''}>
                                <span>{t(`${I18N_PREFIX}.product.options.${productType}`)}</span>
                            </td>
                            <td className="page-container--table-cell-title" title={code ?? ''}>
                                {code}
                            </td>
                            <td className="page-container--table-cell-title" title={`${nominalValue}` ?? ''}>
                                <CurrencyFormatter value={nominalValue} prefix />
                            </td>
                            <td className="page-container--table-cell-title" title={`${numberOfOperations}` ?? ''}>
                                {numberOfOperations}
                            </td>
                            <td className="page-container--table-cell-title" title={status ?? ''}>
                                <span
                                    className={clsx('page-container--table-status', {
                                        RECEIVED: status === BatchBankerStatus.RECEIVED,
                                        CLOSED: status === BatchBankerStatus.CLOSED,
                                        PROCESSED: status === BatchBankerStatus.PROCESSED,
                                        ERROR: status === BatchBankerStatus.ERROR,
                                    })}
                                >
                                    {t(`${I18N_PREFIX}.status.options.${status}`).toUpperCase()}
                                </span>
                            </td>
                            <td className="page-container--table-cell-title">
                                <BatchBankerMoreOptionsIconButton onClick={() => setSelectedBatch(batch)} selectedBatch={selectedBatch} />
                            </td>
                        </tr>
                    );
                })
            ) : (
                <div className="scd-batch__table-empty-filter">
                    <span className="scd-batch__table-empty-filter-image" />
                    <span className="scd-batch__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                    <span className="scd-batch__table-empty-filter-subtitle">{showInvalidFilterMessage && t(`${I18N_PREFIX}.invalid-filter`)}</span>
                </div>
            )}
        </>
    );
};

export default BatchesList;
