import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import BackButton from 'components/buttons/back-button/back-button';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import Loading from 'components/loading/loading';
import AccountOperationCard from 'features/ccb-operation/ccb-operation-detail/components/cards/account-operation-card/account-operation-card';
import AddressOperationCard from 'features/ccb-operation/ccb-operation-detail/components/cards/address-operation-card/address-operation-card';
import ContactOperationCard from 'features/ccb-operation/ccb-operation-detail/components/cards/contact-operation-card/contact-operation-card';
import DocumentOperationCard from 'features/ccb-operation/ccb-operation-detail/components/cards/document-operation-card/document-operation-card';
import HeaderOperationInfoCard from 'features/ccb-operation/ccb-operation-detail/components/cards/header-operation-info-card/header-operation-info-card';
import LoanOperationCard from 'features/ccb-operation/ccb-operation-detail/components/cards/loan-operation-card/loan-operation-card';
import PersonalInfoOperationCard from 'features/ccb-operation/ccb-operation-detail/components/cards/personal-info-operation-card/personal-info-operation-card';
import SupplementaryDataOperationCard from 'features/ccb-operation/ccb-operation-detail/components/cards/supplementary-data-operation-card/supplementary-data-operation-card';
import TaxOperationCard from 'features/ccb-operation/ccb-operation-detail/components/cards/tax-operation-card.tsx/tax-operation-card';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ImportedOperationBankerStatus } from 'model/enums/imported-operation-status';
import { defaultScdImportedOperationDetail } from 'model/imported-operation';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { useDetailImportedOperationState, useRootDispatch } from 'reducer/hooks';
import { detailImportedOperationRequest } from 'reducer/imported-operation/detail-imported-operation/actions';
import { listImportedOperationsResetState } from 'reducer/imported-operation/list-imported-operations/actions';
import './ccb-operation-detail.scss';
import OperationApprovalCard from './components/cards/operation-approval-card/operation-approval-card';
import OrganizationOperationCard from './components/cards/organization-operation-card copy/organization-operation-card';

type ScdCcbOperationDetailProps = RouteComponentProps<{ operationId: string }>;

const I18N_PREFIX = 'pages.scd.ccb-operation-detail';

const ScdCcbOperationDetail = (props: ScdCcbOperationDetailProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const [operationId] = useState<number>(Number(props.match.params.operationId));

    const { detailImportedOperation, status: operationDetailStatus } = useDetailImportedOperationState();

    const handleOperationDetailRequest = useCallback(() => {
        dispatch(detailImportedOperationRequest(operationId));
    }, [dispatch, operationId]);

    useEffect(() => {
        handleOperationDetailRequest();
    }, [handleOperationDetailRequest]);

    useEffect(() => {
        return () => {
            dispatch(listImportedOperationsResetState());
        };
    }, [dispatch]);

    const hasError = operationDetailStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('pages.scd.ccb-operation-detail.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleOperationDetailRequest} />
            </div>
        );
    }

    const isLoading = operationDetailStatus !== HttpRequestStatus.SUCCESS;

    const operationDetail = detailImportedOperation ?? defaultScdImportedOperationDetail;

    return (
        <main className="scd-ccb-operation-detail">
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <ContextRibbon />
                    <section className="scd-ccb-operation-detail__container">
                        <header className="scd-ccb-operation-detail__header">
                            <h2 className="scd-ccb-operation-detail__header--title">{t(`${I18N_PREFIX}.title`)} </h2>
                        </header>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <div className="scd-ccb-operation-detail__content">
                                    <span
                                        className={clsx('scd-ccb-operation-detail__content--status', {
                                            ERROR: detailImportedOperation?.status === ImportedOperationBankerStatus.ERROR,
                                            RECEIVED: detailImportedOperation?.status === ImportedOperationBankerStatus.RECEIVED,
                                            PROCESSED: detailImportedOperation?.status === ImportedOperationBankerStatus.PROCESSED,
                                            ARCHIVED: detailImportedOperation?.status === ImportedOperationBankerStatus.ARCHIVED,
                                            DISAPPROVED: detailImportedOperation?.status === ImportedOperationBankerStatus.DISAPPROVED,
                                        })}
                                    >
                                        {t(`pages.scd.ccb-operation.status.options.${detailImportedOperation?.status}`).toUpperCase()}
                                    </span>
                                    <div className="scd-ccb-operation-detail__content--info">
                                        <span> {detailImportedOperation?.ccbNumber} </span>
                                        <span> {t(`pages.scd.ccb-operation.product.options.${detailImportedOperation?.product}`)} </span>
                                    </div>
                                    <LoanOperationCard operation={operationDetail} />
                                    <TaxOperationCard operation={operationDetail} />
                                </div>
                            </Grid>
                            <Grid item xs={9}>
                                <HeaderOperationInfoCard operation={operationDetail} />
                                <PersonalInfoOperationCard operation={operationDetail} />
                                <OperationApprovalCard operation={operationDetail} />
                                <ContactOperationCard operation={operationDetail} />
                                <AccountOperationCard operation={operationDetail} />
                                <AddressOperationCard operation={operationDetail} />
                                <DocumentOperationCard operation={operationDetail} />
                                <OrganizationOperationCard operation={operationDetail} />
                                <SupplementaryDataOperationCard operation={operationDetail} />
                            </Grid>
                        </Grid>
                    </section>
                </>
            )}
        </main>
    );
};

export default ScdCcbOperationDetail;
