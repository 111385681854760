import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { OperationApprovalSimple } from 'model/operation-approvals';
import { markError } from 'reducer/application/error/actions';
import { disapproveOperationApprovalRequest, disapproveOperationApprovalSuccess, disapproveOperationApprovalError } from './actions';
import { DisapproveOperationApprovalTypes } from './types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import operationApprovalApi from 'services/api/operation-approvals-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleOperationApprovalDisapprove(action: ReturnType<typeof disapproveOperationApprovalRequest>) {
    try {
        const result: AxiosResponse<OperationApprovalSimple> = yield call(operationApprovalApi.disapproveOperationApproval, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(disapproveOperationApprovalError(mapped));
            return;
        }
        yield put(disapproveOperationApprovalSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(disapproveOperationApprovalError(mapped));
    }
}

function* watchOperationApprovalDisapprove() {
    yield takeEvery(DisapproveOperationApprovalTypes.DISAPPROVE_OPERATION_APPROVAL_REQUEST, handleOperationApprovalDisapprove);
}

function* disapproveOperationApprovalSaga() {
    yield all([fork(watchOperationApprovalDisapprove)]);
}

export default disapproveOperationApprovalSaga;
