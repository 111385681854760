export enum ErrorType {
    NON_BLOCKING = 'NON_BLOCKING',
    BLOCKING = 'BLOCKING',
    IGNORED = 'IGNORED',
}

export enum GiroScdBankerErrorConstants {
    // Payment transaction error
    PAYMENT_TRANSACTION_UNRESOLVED = 'error.paymentTransaction.unresolved',
    PAYMENT_TRANSACTION_NOT_FOUND = 'error.paymentTransaction.notFound',
    PAYMENT_TRANSACTION_VOUCHER_NOT_FOUND = 'error.paymentTransaction.voucherNotFound',
    BATCH_NOT_FOUND = 'error.batch.notFound',
    OPERATION_APPROVAL_STATUS_WRONG = 'error.operationApproval.statusWrong',
    IMPORTED_OPERATION_STATUS_WRONG = 'error.importedOperation.wrongStatus',
    // Integration error
    INTEGRATION_AUTHENTICATION_TOKEN_RECOVERY_FAILED = "error.integrationAuthentication.tokenRecoveryFailed",
    INTEGRATION_AUTHENTICATION_ACCOUNT_TYPE_NOT_SUPPORTED = "error.integrationAuthentication.accountTypeNotSupported",
    INTEGRATION_AUTHENTICATION_MISSING_ACCOUNT_HEADER = "error.integrationAuthentication.missingAccountHeader",
    INTEGRATION_AUTHENTICATION_DIGITAL_ACCOUNT_REFERENCE_REQUIRED = "error.integrationAuthentication.digitalAccountReferenceRequired",
    INTEGRATION_AUTHENTICATION_UNRESOLVED = "error.integrationAuthentication.unresolved",
    //Payment Accounts
    PAYMENT_INSTITUTION_ACCOUNT_TYPE_NOT_SUPPORTED = "error.paymentInstitutionAccount.typeNotSupported",
    PAYMENT_INSTITUTION_ACCOUNT_NOT_FOUND = "error.paymentInstitutionAccount.notFound",
    PAYMENT_INSTITUTION_ACCOUNT_NOT_ACTIVE = "error.paymentInstitutionAccount.notActive"
}

export enum GiroWebappErrorConstants {
    // http
    HTTP_UNAUTHORIZED = 'error.http.unauthorized',
    HTTP_FORBIDDEN = 'error.http.forbidden',

    // user
    USER_NOT_ACTIVATED = 'error.user.notActivated',

    // Payment transaction error
    PAYMENT_TRANSACTION_UNRESOLVED = 'error.paymentTransaction.unresolved',
}

export type ErrorConstants = GiroScdBankerErrorConstants | GiroWebappErrorConstants;
