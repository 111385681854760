import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { BatchBanker } from 'model/batch';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

interface ScdBatchBankerProps {
    batches: BatchBanker[];
    handleSort: (property: BatchBankerDataSortableProperties, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort?: boolean;
}

export type BatchBankerDataSortableProperties = keyof BatchBanker | 'product' | 'createdDate';

const I18N_PREFIX = 'pages.scd.batch.table';

const ScdBatchHeader = (props: ScdBatchBankerProps) => {
    const { batches, handleSort, sortOrder, sortedProperty, disabledSort } = props;

    const { t } = useTranslation();

    const mapSortable = (_property: BatchBankerDataSortableProperties) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (batches && batches.length <= 1) ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.date`).toUpperCase()} sortable={mapSortable('date')} />
            <SortableColumn label={t(`${I18N_PREFIX}.product`).toUpperCase()} sortable={mapSortable('product')} />
            <SortableColumn label={t(`${I18N_PREFIX}.code`).toUpperCase()} sortable={mapSortable('code')} />
            <SortableColumn label={t(`${I18N_PREFIX}.value`).toUpperCase()} sortable={mapSortable('nominalValue')} />
            <SortableColumn label={t(`${I18N_PREFIX}.operations`).toUpperCase()} sortable={mapSortable('numberOfOperations')} />
            <SortableColumn label={t(`${I18N_PREFIX}.status`).toUpperCase()} sortable={mapSortable('status')} />
        </tr>
    );
};

export default ScdBatchHeader;
