import { Authority } from 'model/enums/authorities';
import { GiroWebappErrorConstants } from 'model/enums/error-constants';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect } from 'react';
import { userAccountResetState } from 'reducer/account/user-account/actions';
import { markError } from 'reducer/application/error/actions';
import { loginResetState } from 'reducer/authentication/actions';
import { useRootDispatch, useUserAccountState } from 'reducer/hooks';
import AuthUtil from 'services/api/authUtil';

export const usePrivateRouteRedirect = () => {
    const dispatch = useRootDispatch();

    const { account, status: accountStatus } = useUserAccountState();

    const logout = useCallback(
        errorMessage => {
            AuthUtil.removeToken();
            dispatch(loginResetState());
            dispatch(userAccountResetState());
            setTimeout(() => {
                dispatch(markError({ message: errorMessage }));
            }, 1000);
        },
        [dispatch]
    );

    const isValidAccount = account?.activated;
    const isBanker = account?.authorities?.includes(Authority.ROLE_BANKER);

    useEffect(() => {
        if (accountStatus !== HttpRequestStatus.SUCCESS) return;
        if (!isValidAccount) return logout(GiroWebappErrorConstants.HTTP_UNAUTHORIZED);
        if (!isBanker) return logout(GiroWebappErrorConstants.HTTP_FORBIDDEN);
    }, [account, dispatch, isValidAccount, accountStatus, logout, isBanker]);
};
