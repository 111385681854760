import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useTranslation } from 'react-i18next';
import { useUserAccountState } from 'reducer/hooks';
import './dashboard.scss';

const Dashboard = () => {
    const { t } = useTranslation();
    const { account, status } = useUserAccountState();

    const isLoading = status !== HttpRequestStatus.SUCCESS;

    return isLoading ? (
        <div className="dashboard__loading">
            <Loading />
        </div>
    ) : (
        <main className="dashboard">
            <article className="dashboard__container">
                <header className="dashboard__header">
                    <h1 className="dashboard__header--title">{t('pages.home.title', { account_name: account?.firstName })}</h1>
                </header>
            </article>
        </main>
    );
};

export default Dashboard;
