import { GIRO_PHONE_CONTACT } from 'config/constants';
import AutoLogin from 'features/login/components/auto-login/auto-login';
import UserLogin from 'features/login/components/user-login/user-login';
import { ErrorConstants, ErrorType, GiroWebappErrorConstants } from 'model/enums/error-constants';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ErrorHandlingCustomization } from 'model/error';
import { useEffect } from 'react';
import { customizeErrorHandling, markError } from 'reducer/application/error/actions';
import { useRootDispatch, useUserAccountState } from 'reducer/hooks';
import { useAuthenticationAwareness } from 'shared/hooks/use-authentication-awareness';
import { useRedirectToDashboard } from 'shared/hooks/use-redirect-to-dashboard';

const useHandleUserNotActivated = () => {
    const dispatch = useRootDispatch();
    const { status, account } = useUserAccountState();

    useEffect(() => {
        const record: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = {
            'error.user.notActivated': {
                type: ErrorType.BLOCKING,
                message: { key: GiroWebappErrorConstants.USER_NOT_ACTIVATED, options: { contact_number: GIRO_PHONE_CONTACT } },
                action: { noAction: true },
            },
        };
        dispatch(customizeErrorHandling({ record }));
    }, [dispatch]);

    useEffect(() => {
        if (status === HttpRequestStatus.SUCCESS && account?.activated !== true) {
            dispatch(markError({ message: GiroWebappErrorConstants.USER_NOT_ACTIVATED }));
        }
    }, [dispatch, status, account]);
};

const Login = () => {
    const isAuthenticated = useAuthenticationAwareness();

    useHandleUserNotActivated();
    useRedirectToDashboard();

    if (isAuthenticated) {
        return <AutoLogin />;
    }

    return <UserLogin />;
};

export default Login;
