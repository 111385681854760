import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// List Batches
import { ListBatchBankerState } from './list-batches/types';
import { listBatchBankerReducer } from './list-batches/reducer';
import listBatchBankerSaga from './list-batches/sagas';

// Detail Batch
import { DetailBatchBankerState } from './detail-batch/types';
import { detailBatchBankerReducer } from './detail-batch/reducer';
import detailBatchBankerSaga from './detail-batch/sagas';

export interface BatchBankerState {
    readonly listBatchBanker: ListBatchBankerState;
    readonly detailBatchBanker: DetailBatchBankerState;
}

export const BatchBankerReducer: ReducersMapObject<BatchBankerState, AnyAction> = {
    listBatchBanker: listBatchBankerReducer,
    detailBatchBanker: detailBatchBankerReducer,
};

export function* batchBankerSagas() {
    yield all([fork(listBatchBankerSaga), fork(detailBatchBankerSaga)]);
}
