const currencySign = 'R$ ';
const emptyString = '';
const comma = ',';
const period = '.';
const minus = '-';
const minusRegExp = /-/;
const nonDigitsRegExp = /\D+/g;
const number = 'number';
const digitRegExp = /\d/;
const caretTrap = '[]';

function convertToMask(strNumber: any) {
    return strNumber.split(emptyString).map((char: any) => (digitRegExp.test(char) ? digitRegExp : char));
}

// http://stackoverflow.com/a/10899795/604296
function addThousandsSeparator(n: any, thousandsSeparatorSymbol: any) {
    return n.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparatorSymbol);
}

export default function createNumberMask({
    prefix = currencySign,
    suffix = emptyString,
    includeThousandsSeparator = true,
    thousandsSeparatorSymbol = period || comma,
    allowDecimal = true,
    decimalSymbol = comma || period,
    decimalLimit = 2,
    requireDecimal = true,
    allowNegative = false,
    allowLeadingZeroes = false,
    integerLimit = 17,
} = {}) {
    const prefixLength = (prefix && prefix.length) || 0;
    const thousandsSeparatorSymbolLength = (thousandsSeparatorSymbol && thousandsSeparatorSymbol.length) || 0;

    function numberMask(rawValue = emptyString) {
        const rawValueLength = rawValue.length;

        if (rawValue === emptyString || (rawValue[0].startsWith(prefix[0]) && rawValueLength === 1)) {
            return prefix.split(emptyString).concat(suffix.split(emptyString));
        } else if (rawValue === decimalSymbol && allowDecimal) {
            return prefix.split(emptyString).concat(['0', decimalSymbol]).concat(suffix.split(emptyString));
        }

        const isNegative = rawValue[0].startsWith(minus) && allowNegative;
        //  If negative remove "-" sign
        if (isNegative) {
            rawValue = rawValue.toString().substr(1);
        }
        // prettier-ignore
        const indexOfLastDecimal = rawValue.lastIndexOf(decimalSymbol);
        const hasDecimal = indexOfLastDecimal !== -1;

        let integer: string, mask: any;

        let fraction: string | undefined = undefined;

        if (hasDecimal && (allowDecimal || requireDecimal)) {
            integer = rawValue.slice(rawValue.slice(0, prefixLength).startsWith(prefix) ? prefixLength : 0, indexOfLastDecimal);

            fraction = rawValue.slice(indexOfLastDecimal + 1, rawValueLength) ?? '00';
            fraction = convertToMask(fraction.replace(nonDigitsRegExp, emptyString)) ?? '00';
        } else {
            rawValue.slice(0, prefixLength).startsWith(prefix) ? (integer = rawValue.slice(prefixLength)) : (integer = rawValue);

            if (fraction !== '00' || !fraction) {
                fraction = '00';
            }
        }

        if (integerLimit && typeof integerLimit === number) {
            const thousandsSeparatorRegex = thousandsSeparatorSymbol === '.' ? '[.]' : `${thousandsSeparatorSymbol}`;
            const numberOfThousandSeparators = (new RegExp(thousandsSeparatorRegex, 'g').exec(integer) || []).length;

            integer = integer.slice(0, integerLimit + numberOfThousandSeparators * thousandsSeparatorSymbolLength);
        }

        integer = integer.replace(nonDigitsRegExp, emptyString);

        if (!allowLeadingZeroes) {
            integer = integer.replace(/^0+(0$|[^0])/, '$1');
        }

        integer = includeThousandsSeparator ? addThousandsSeparator(integer, thousandsSeparatorSymbol) : integer;

        mask = convertToMask(integer);

        if ((hasDecimal && allowDecimal) || requireDecimal === true) {
            if (rawValue[indexOfLastDecimal - 1] !== decimalSymbol) {
                mask.push(caretTrap);
            }

            mask.push(decimalSymbol, caretTrap);

            if (fraction) {
                if (typeof decimalLimit === number) {
                    fraction = fraction.slice(0, decimalLimit);
                }

                mask = mask.concat(fraction);
            }

            if (requireDecimal === true && rawValue[indexOfLastDecimal - 1] === decimalSymbol) {
                mask.push(digitRegExp);
            }
        }

        if (prefixLength > 0) {
            mask = prefix.split(emptyString).concat(mask);
        }

        if (isNegative) {
            // If user is entering a negative number, add a mask placeholder spot to attract the caret to it.
            if (mask.length === prefixLength) {
                mask.push(digitRegExp);
            }
            mask = [minusRegExp].concat(mask);
        }

        if (suffix.length > 0) {
            mask = mask.concat(suffix.split(emptyString));
        }

        return mask;
    }

    numberMask.instanceOf = 'createNumberMask';

    return numberMask;
}
