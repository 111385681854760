import FormControl from '@material-ui/core/FormControl';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import clsx from 'clsx';
import BaseMaskInput, { BaseMaskInputProps } from 'components/inputs/base-mask-input';
import InputUtils from 'components/inputs/input-utils';
import React from 'react';
import CurrencyMask from './currency-mask';

type Base = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'onChange' | 'value' | 'type'>;

export interface SimpleCurrencyInputProps extends Base {
    value?: number;
    onChange?: (value: number | undefined) => void;
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
}

export const SimpleCurrencyInput = (props: SimpleCurrencyInputProps) => {
    const { value, onChange, MaskInputComponent, ...otherProps } = props;

    const handleOnChange = (_value: string) => {
        const normalized = InputUtils.currencyStringToNumber(_value);

        if (onChange && normalized === 0) {
            onChange(undefined);
            return;
        }

        if (onChange && !isNaN(normalized)) {
            onChange(normalized);
        }
    };

    return (
        <FormControl className={clsx('base-input', { dirty: value })}>
            <BaseMaskInput {...otherProps} value={value !== undefined ? InputUtils.numberToCurrecyLikeString(value) : ''} onChange={handleOnChange} MaskInputComponent={MaskInputComponent ?? CurrencyMask} type="text" />
        </FormControl>
    );
};

export default SimpleCurrencyInput;
