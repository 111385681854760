import { Tooltip } from '@material-ui/core';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import clsx from 'clsx';
import { PaymentTransactionStatus } from 'model/enums/payment-transaction-status';
import { Trans, useTranslation } from 'react-i18next';
import { getPersonType } from 'shared/util/person-type-utils';
import { BANK_SVG_URL } from 'config/constants';
import invalidBankSvg from 'images/bank-default.svg';
import './header-imported-operation-card.scss';
import { ScdPaymentTransactionData } from 'model/payment-transaction';

const I18N_PREFIX = 'pages.scd.payment-transaction.imported-operations.header';

interface HeaderImportedOperationCardProps {
    headerInfo: ScdPaymentTransactionData | undefined;
}

const HeaderImportedOperationCard = (props: HeaderImportedOperationCardProps) => {
    const { t } = useTranslation();

    const { headerInfo } = props;

    return (
        <div className="scd-header-imported-operation-card">
            <div className="scd-header-imported-operation-card--item">
                <span className="scd-header-imported-operation-card--item__label"> {t(`${I18N_PREFIX}.date`)} </span>
                <span className="scd-header-imported-operation-card--item__value">{headerInfo?.fallbackDateTime ? <DateFormatter date={headerInfo?.fallbackDateTime} format={DateFormat.DATETIME_WITHOUT_SECONDS_UTC} /> : '-'}</span>
            </div>
            <div className="scd-header-imported-operation-card--item">
                <span className="scd-header-imported-operation-card--item__label"> {t(`${I18N_PREFIX}.amount`)} </span>
                <span className="scd-header-imported-operation-card--item__value"> {headerInfo?.amount ? <CurrencyFormatter prefix value={headerInfo?.amount} /> : '-'} </span>
            </div>
            <div className="scd-header-imported-operation-card--item-favored">
                <span className="scd-header-imported-operation-card--item__label"> {t(`${I18N_PREFIX}.favored`)} </span>
                <span className="scd-header-imported-operation-card--item__value">
                    <Trans
                        i18nKey={`${I18N_PREFIX}.favoredMessage`}
                        t={t}
                        values={{ favoredIdentification: headerInfo?.favoredIdentification, favoredName: headerInfo?.favoredName }}
                        components={{ identificationComponent: <IdentificationFormatter value={headerInfo?.favoredIdentification} type={getPersonType(headerInfo?.favoredIdentification)} /> }}
                    />
                </span>
            </div>
            <div className="scd-header-imported-operation-card--item">
                <span className="scd-header-imported-operation-card--item__label"> {t(`${I18N_PREFIX}.agency`)} </span>
                <span className="scd-header-imported-operation-card--item__value">
                    {headerInfo?.bankIdentification ? (
                        <Tooltip title={headerInfo?.bankName}>
                            <img
                                src={`${BANK_SVG_URL}/${headerInfo?.bankIdentification}.svg`}
                                alt={headerInfo?.bankName ?? ''}
                                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => ((e.target as HTMLImageElement).src = invalidBankSvg)}
                                height="20"
                                width="20"
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip title={headerInfo?.bankName ?? t('tooltips.bankInvalid')}>
                            <img src={invalidBankSvg} alt={headerInfo?.bankName ?? ''} height="20" width="20" />
                        </Tooltip>
                    )}
                    {headerInfo?.agencyNumber}
                </span>
            </div>
            <div className="scd-header-imported-operation-card--item">
                <span className="scd-header-imported-operation-card--item__label"> {t(`${I18N_PREFIX}.account`)} </span>
                <span className="scd-header-imported-operation-card--item__value"> {headerInfo?.pixKey ?? headerInfo?.accountNumber ?? '-'} </span>
            </div>
            <div className="scd-header-imported-operation-card-status">
                <span className="scd-header-imported-operation-card--item__label"> {t(`${I18N_PREFIX}.status`)} </span>
                <span
                    className={clsx('scd-header-imported-operation-card--item__status', {
                        CONFIRMED: headerInfo?.status === PaymentTransactionStatus.CONFIRMED,
                        ERROR: headerInfo?.status === PaymentTransactionStatus.ERROR,
                        WAITING: headerInfo?.status === PaymentTransactionStatus.WAITING,
                        CANCELLED: headerInfo?.status === PaymentTransactionStatus.CANCELLED,
                    })}
                >
                    {t(`${I18N_PREFIX}.statusOptions.${headerInfo?.status}`).toUpperCase()}
                </span>
            </div>
        </div>
    );
};

export default HeaderImportedOperationCard;
