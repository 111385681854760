import { AxiosResponse } from 'axios';
import { ImportedOperationByPaymentTransactionData } from 'model/imported-operation';
import { PaymentTransactionFilterPageable, PaymentTransactionToListImportedOperationsRequest, ScdPaymentTransactionData, ScdPaymentTransactionVoucher } from 'model/payment-transaction';
import { api } from 'services/api/api';
import { Page } from 'services/page';

const URL_BASE = 'api/banker/payment-transactions';

const paymentTransaction = () => {
    const listPaymentTransactions = (request: PaymentTransactionFilterPageable): Promise<AxiosResponse<Page<ScdPaymentTransactionData>>> => {
        const { filter, pageable } = request;

        const _requestFilters = [
            filter?.amount?.minAmount && filter?.amount.maxAmount ? `minAmount=${filter.amount.minAmount}&maxAmount=${filter.amount.maxAmount}` : undefined,
            filter?.amount?.minAmount && !filter?.amount.maxAmount ? `minAmount=${filter.amount.minAmount}` : undefined,
            filter?.amount?.maxAmount && !filter?.amount.minAmount ? `maxAmount=${filter.amount.maxAmount}` : undefined,

            filter?.date?.start && filter?.date.end ? `date.start=${filter.date.start}&date.end=${filter.date.end}` : undefined,
            filter?.date?.start && !filter?.date.end ? `date.start=${filter.date.start}` : undefined,
            filter?.date?.end && !filter?.date.start ? `date.end=${filter.date.end}` : undefined,

            filter?.status && `status=${filter?.status}`,

            filter?.favoredSearch ? `favoredSearch=${filter.favoredSearch}` : undefined,
            filter?.accountSearch ? `accountSearch=${filter.accountSearch}` : undefined,
        ]
            .filter(it => !!it)
            .join('&');

        return api.get<Page<ScdPaymentTransactionData>>(`${URL_BASE}?${_requestFilters}`, { params: { ...pageable } });
    };

    const listImportedOperationsByPaymentTransaction = (request: PaymentTransactionToListImportedOperationsRequest) => {
        const { paymentTransactionId, pageable } = request;
        return api.get<Page<ImportedOperationByPaymentTransactionData>>(`${URL_BASE}/${paymentTransactionId}/imported-operations`, { params: { ...pageable } });
    };
    const detailVoucherPaymentTransaction = (paymentTransactionId: number) => {
        return api.get<ScdPaymentTransactionVoucher>(`${URL_BASE}/${paymentTransactionId}/voucher`);
    };
    const getPaymentTransactionHeaderOnImportedOperation = (paymentTransactionId: number) => {
        return api.get<ScdPaymentTransactionData>(`${URL_BASE}/${paymentTransactionId}`);
    };

    return {
        listPaymentTransactions,
        listImportedOperationsByPaymentTransaction,
        detailVoucherPaymentTransaction,
        getPaymentTransactionHeaderOnImportedOperation,
    };
};

export default paymentTransaction();
