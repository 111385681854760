import { HttpRequestStatus } from 'model/enums/http-request-status';
import { Reducer } from 'redux';
import { DetailPaymentTransactionVoucherState, DetailPaymentTransactionVoucherTypes } from './types';

const initialState: DetailPaymentTransactionVoucherState = {
    status: HttpRequestStatus.NOOP,
    paymentTransactionVoucher: undefined,
    error: undefined,
};

const reducer: Reducer<DetailPaymentTransactionVoucherState> = (state = initialState, action): DetailPaymentTransactionVoucherState => {
    switch (action.type) {
        case DetailPaymentTransactionVoucherTypes.DETAIL_PAYMENT_TRANSACTION_VOUCHER_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case DetailPaymentTransactionVoucherTypes.DETAIL_PAYMENT_TRANSACTION_VOUCHER_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, paymentTransactionVoucher: action.payload, error: undefined };
        }
        case DetailPaymentTransactionVoucherTypes.DETAIL_PAYMENT_TRANSACTION_VOUCHER_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case DetailPaymentTransactionVoucherTypes.DETAIL_PAYMENT_TRANSACTION_VOUCHER_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as detailPaymentTransactionVoucherReducer };
