import { Authority } from "model/enums/authorities";
import { RouteComponentProps, Switch } from "react-router-dom";
import PrivateRoute from "shared/routes/private-route";
import PaymentAccountBalance from "./payment-accounts-balance/payment-accounts-balance";

interface MatchParams {
    url: string;
}

const PaymentAccountsRoutes = ({match}: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/balance`} component={PaymentAccountBalance} rolesAllowed={[Authority.ROLE_BANKER]} />
    </Switch>

);

export default PaymentAccountsRoutes;