import { OperationApprovalSimple } from 'model/operation-approvals';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum ApproveOperationApprovalTypes {
    APPROVE_OPERATION_APPROVAL_REQUEST = '@@approve-operation-approval/APPROVE_OPERATION_APPROVAL_REQUEST',
    APPROVE_OPERATION_APPROVAL_SUCCESS = '@@approve-operation-approval/APPROVE_OPERATION_APPROVAL_SUCCESS',
    APPROVE_OPERATION_APPROVAL_ERROR = '@@approve-operation-approval/APPROVE_OPERATION_APPROVAL_ERROR',
    APPROVE_OPERATION_APPROVAL_RESET_STATE = '@@approve-operation-approval/APPROVE_OPERATION_APPROVAL_RESET_STATE',
}

export interface ApproveOperationApprovalState extends BaseStoreState {
    readonly approvedOperation?: OperationApprovalSimple;
}
