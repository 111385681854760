import { AxiosResponse } from 'axios';
import { PaymentAccountBalanceResponse, PaymentAccountsToGetAllResponse } from 'model/payment-accounts';
import { api } from 'services/api/api';

const URL_BASE = 'api/banker/payment-accounts';

const paymentAccounts = () => {
    const listPaymentAccounts = (): Promise<AxiosResponse<PaymentAccountsToGetAllResponse>> => {
        return api.get<PaymentAccountsToGetAllResponse>(`${URL_BASE}`)
    };

    const balancePaymentAccount = (accountId: number): Promise<AxiosResponse<PaymentAccountBalanceResponse>> => {
        return api.get<PaymentAccountBalanceResponse>(`${URL_BASE}/${accountId}/balance`);
    };

    return {
        listPaymentAccounts,
        balancePaymentAccount
    };
};

export default paymentAccounts();
