import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { useDetaiPaymentTransactionVoucherState, useRootDispatch } from 'reducer/hooks';
import { detailVoucherPaymentTransactionRequest } from 'reducer/payment-transaction/detail-voucher-payment-transaction/actions';
import { getPersonType } from 'shared/util/person-type-utils';
import { useReactToPrint } from 'react-to-print';
import './payment-transaction-modal-details.scss';
import { Icon, IconButton } from '@material-ui/core';
import { ScdPaymentTransactionData } from 'model/payment-transaction';

export interface ModalMessageProps {
    children?: React.ReactNode;
    selectedPaymentTransaction: ScdPaymentTransactionData | undefined;
    onClose: () => void;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.voucher';

export const PaymentTransactionModalDetails = (props: ModalMessageProps) => {
    const { onClose, selectedPaymentTransaction } = props;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const componentReferenceToPrint = useRef(null);

    const { paymentTransactionVoucher } = useDetaiPaymentTransactionVoucherState();

    useEffect(() => {
        if (selectedPaymentTransaction?.id) {
            dispatch(detailVoucherPaymentTransactionRequest(selectedPaymentTransaction.id));
        }
    }, [dispatch, selectedPaymentTransaction?.id]);

    const handlePrint = useReactToPrint({
        content: () => componentReferenceToPrint.current,
    });

    return (
        <Modal isOpen>
            <div className="payment-transaction-modal-details" ref={componentReferenceToPrint}>
                <header className="payment-transaction-modal-details--header">
                    <div className="payment-transaction-modal-details--header-close-button">
                        <IconButton onClick={onClose}>
                            <Icon>
                                <div className="payment-transaction-modal-details--header-close-button-icon" />
                            </Icon>
                        </IconButton>
                    </div>
                </header>
                <p className="payment-transaction-modal-details--title">{t(`${I18N_PREFIX}.title`)}</p>
                <article className="payment-transaction-modal-details--content">
                    <div>
                        <div className="payment-transaction-modal-details--content-message">
                            <div className="payment-transaction-modal-details--content-message--subtitle">{t(`${I18N_PREFIX}.favored`)}</div>

                            <div className="payment-transaction-modal-details--content-message--info">
                                <Trans
                                    i18nKey={`${I18N_PREFIX}.favoredInfo`}
                                    t={t}
                                    values={{ favoredName: paymentTransactionVoucher?.favoredName, favoredIdentification: paymentTransactionVoucher?.favoredIdentification }}
                                    components={{ identificationComponent: <IdentificationFormatter value={paymentTransactionVoucher?.favoredIdentification} type={getPersonType(paymentTransactionVoucher?.favoredIdentification)} /> }}
                                />
                            </div>
                        </div>
                        <div className="payment-transaction-modal-details--content-message">
                            <div className="payment-transaction-modal-details--content-message--subtitle">{t(`${I18N_PREFIX}.bank`)}</div>
                            <div className="payment-transaction-modal-details--content-message--info">
                                <Trans i18nKey={`${I18N_PREFIX}.bankInfo`} t={t} values={{ bankName: paymentTransactionVoucher?.bankName, bankIdentification: paymentTransactionVoucher?.bankIdentification }} />
                            </div>
                        </div>
                        <div className="payment-transaction-modal-details--content-message">
                            <div className="payment-transaction-modal-details--content-message--subtitle">{t(`${I18N_PREFIX}.agencyNumber`)}</div>
                            <div className="payment-transaction-modal-details--content-message--info">{paymentTransactionVoucher?.agencyNumber ?? '-'}</div>
                        </div>
                        <div className="payment-transaction-modal-details--content-message">
                            <div className="payment-transaction-modal-details--content-message--subtitle">{t(`${I18N_PREFIX}.accountNumber`)}</div>
                            <div className="payment-transaction-modal-details--content-message--info">{paymentTransactionVoucher?.accountNumber ?? '-'}</div>
                        </div>
                        <div className="payment-transaction-modal-details--content-message">
                            <div className="payment-transaction-modal-details--content-message--subtitle">{t(`${I18N_PREFIX}.pixKey`)}</div>
                            <div className="payment-transaction-modal-details--content-message--info">{paymentTransactionVoucher?.pixKey ?? '-'}</div>
                        </div>
                        <div className="payment-transaction-modal-details--content-message">
                            <div className="payment-transaction-modal-details--content-message--subtitle">{t(`${I18N_PREFIX}.origin`)}</div>
                            <div className="payment-transaction-modal-details--content-message--info">{paymentTransactionVoucher?.origin ?? '-'}</div>
                        </div>
                    </div>
                </article>
                <div className="payment-transaction-modal-details--download-button" onClick={() => handlePrint()}>
                    <span className="payment-transaction-modal-details--download-button__icon" />
                    <span className="payment-transaction-modal-details--download-button__value">{t(`${I18N_PREFIX}.download`).toLocaleUpperCase()}</span>
                </div>
            </div>
        </Modal>
    );
};

export default PaymentTransactionModalDetails;
