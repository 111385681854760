import './operation-read-only-text-field.scss';
import _ from 'lodash';
import React from 'react';

interface OperationReadOnlyTextFieldProps {
    label: string;
    value: React.ReactNode;
}

const OperationReadOnlyTextField = (props: OperationReadOnlyTextFieldProps) => {
    const { label, value } = props;

    const formatString = (value: string | undefined): string => {
        if (!value) return '-';

        if (_.isEmpty(_.trim(value))) {
            return '-';
        }

        return value;
    };

    return (
        <div className="scd-operation-read-only-text-field">
            <span className="scd-operation-read-only-text-field__label"> {label} </span>
            <span className="scd-operation-read-only-text-field__value">
                {typeof value === 'string' ? formatString(value) : value ?? '-'}
            </span>
        </div>
    );
};

export default OperationReadOnlyTextField;
