import CloseButton from 'components/buttons/close-button/close-button';
import StandardButton from 'components/buttons/standard-button/standard-button';
import { GiroScdBankerErrorConstants } from 'model/enums/error-constants';
import { OperationApproval, OperationApprovalErrorResponse } from 'model/operation-approvals';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { valuesOfObject } from 'shared/util/object-utils';
import './operation-approval-modal-error.scss';

interface OperationApprovalModalErrorProps {
    onClose: () => void;
    title: string;
    errorResponse: OperationApprovalErrorResponse | undefined;
    operationApprovals: OperationApproval[] | undefined;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-approval.modal.errors';

const OperationApprovalModalError = (props: OperationApprovalModalErrorProps) => {
    const { onClose, title, errorResponse, operationApprovals } = props;

    const { t } = useTranslation();

    const verifyErrorMessage = (message: string | undefined) => {
        const errors: string[] = valuesOfObject(GiroScdBankerErrorConstants);

        return errors.some(value => value === message);
    };

    const getCcb = (id: number): number | string | undefined => {
        const selectedOperation = operationApprovals?.find(operation => operation.id === id);

        if (!selectedOperation?.ccbNumber) return '-';

        return selectedOperation?.ccbNumber;
    };

    return (
        <Modal isOpen>
            <div className="approve-multiple-modal-error">
                <article className="approve-multiple-modal-error--content">
                    <header className="approve-multiple-modal-error--header">
                        <CloseButton onClick={onClose} />
                    </header>
                    <div className="approve-multiple-modal-error--message">
                        <p className="approve-multiple-modal-error--title">{t(title)}</p>
                        <div className="approve-multiple-modal-error--errors">
                            {errorResponse?.errors?.map(({ id, message }) => (
                                <div className="approve-multiple-modal-error--errors__text" key={id}>
                                    <span>
                                        <strong> {t(`${I18N_PREFIX}.ccbNumber`)} </strong>
                                        {getCcb(id)}
                                    </span>
                                    <span>
                                        <strong> {t(`${I18N_PREFIX}.error-message`)} </strong>
                                        {message && verifyErrorMessage(message) ? t(message) : !!message ? message : t(`${I18N_PREFIX}.default-error-message`)}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="approve-multiple-modal-error--actions">
                        <StandardButton label={'entity.action.back'} onClick={onClose} />
                    </div>
                </article>
            </div>
        </Modal>
    );
};

export default OperationApprovalModalError;
