import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { ClearButton } from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import { StandardButton } from 'components/buttons/standard-button/standard-button';
import SimpleCurrencyInput from 'components/inputs/currency-input/simple-currency-input';
import SimpleDatePickerInput from 'components/inputs/date-input/date-picker/simple-date-picker';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import { validateFilterAccount, validateFilterFavored } from 'features/payment-transaction/validation/payment-transaction-validation';
import { PaymentTransactionStatus } from 'model/enums/payment-transaction-status';
import { PaymentTransactionFilterRequest } from 'model/payment-transaction';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { valuesOfObject } from 'shared/util/object-utils';
import './payment-transaction-modal-filter.scss';

export type PaymentTransactionModalFilterType = 'filters' | 'date' | 'amount' | 'favored' | 'account' | 'status';

export interface PaymentTransactionModalFilterProps {
    onClose: () => void;
    modalFilter: PaymentTransactionModalFilterType;
    openModal: boolean;
    title: string;
    filterActive: PaymentTransactionFilterRequest;
    setFilterActive: (value: PaymentTransactionFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.filter.input';

export const DISABLED_MODAL_BUTTON_FUNCTION_RECORD = {
    date: filter => !filter.date,
    amount: filter => !filter.amount,
    favored: filter => !validateFilterFavored(filter?.favoredSearch).isValid,
    status: filter => !filter.status,
    account: filter => !validateFilterAccount(filter?.accountSearch).isValid,
    filters: filter => valuesOfObject({ ...filter }).length === 0,
} as Record<PaymentTransactionModalFilterType, (filter: PaymentTransactionFilterRequest) => boolean>;

export const PaymentTransactionModalFilter = (props: PaymentTransactionModalFilterProps) => {
    const { onClose, modalFilter, openModal, title, setFilterActive, filterActive, setPage } = props;

    const [filterRequest, setFilterRequest] = useState<PaymentTransactionFilterRequest>(filterActive);

    const { t } = useTranslation();

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    if (!openModal) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        setPage(0);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<PaymentTransactionFilterRequest>) => {
        setFilterRequest({ ...filterRequest, ...value });
    };

    const handleClearFilter = {
        amount: () => {
            setFilterActive({ ...filterActive, amount: undefined });
            setFilterRequest({ ...filterRequest, amount: undefined });
        },
        filters: () => {
            setFilterRequest({});
            setFilterActive({});
        },
        date: () => {
            setFilterActive({ ...filterActive, date: undefined });
            setFilterRequest({ ...filterRequest, date: undefined });
        },
        favored: () => {
            setFilterActive({ ...filterActive, favoredSearch: undefined });
            setFilterRequest({ ...filterRequest, favoredSearch: undefined });
        },
        account: () => {
            setFilterActive({ ...filterActive, accountSearch: undefined });
            setFilterRequest({ ...filterRequest, accountSearch: undefined });
        },
        status: () => {
            setFilterActive({ ...filterActive, status: undefined });
            setFilterRequest({ ...filterRequest, status: undefined });
        },
    } as Record<PaymentTransactionModalFilterType, () => void>;

    const DateFilter: JSX.Element = (
        <>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    label={t(`${I18N_PREFIX}.initialDate`)}
                    placeholder={t(`${I18N_PREFIX}.initialDate`)}
                    value={filterRequest.date?.start!}
                    onChange={initialDate => handleChange({ date: { ...filterRequest.date, start: initialDate } })}
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleDatePickerInput
                    label={t(`${I18N_PREFIX}.finalDate`)}
                    placeholder={t(`${I18N_PREFIX}.finalDate`)}
                    minDate={filterRequest.date?.start}
                    value={filterRequest.date?.end!}
                    onChange={finalDate => handleChange({ date: { ...filterRequest.date, end: finalDate } })}
                />
            </Grid>
        </>
    );

    const AmountFilter: JSX.Element = (
        <>
            <Grid item xs={6}>
                <SimpleCurrencyInput
                    label={t(`${I18N_PREFIX}.minValue`)}
                    placeholder={t(`${I18N_PREFIX}.minValue`)}
                    onChange={minValue => handleChange({ amount: { ...filterRequest.amount, minAmount: minValue } })}
                    value={filterRequest?.amount?.minAmount}
                    externalUpdate
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleCurrencyInput
                    label={t(`${I18N_PREFIX}.maxValue`)}
                    placeholder={t(`${I18N_PREFIX}.maxValue`)}
                    onChange={maxValue => handleChange({ amount: { ...filterRequest.amount, maxAmount: maxValue } })}
                    value={filterRequest?.amount?.maxAmount}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const SearchFavoredFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleTextInput label={t(`${I18N_PREFIX}.favoredSearch`)} placeholder={t(`${I18N_PREFIX}.favoredSearch`)} onChange={favoredSearch => handleChange({ favoredSearch })} value={filterRequest?.favoredSearch} externalUpdate />
            </Grid>
        </>
    );

    const SearchAccountFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleTextInput label={t(`${I18N_PREFIX}.accountSearch`)} placeholder={t(`${I18N_PREFIX}.accountSearch`)} onChange={accountSearch => handleChange({ accountSearch })} value={filterRequest?.accountSearch} externalUpdate />
            </Grid>
        </>
    );

    const StatusFilter: JSX.Element = (
        <>
            <div
                className={clsx('payment-transaction-modal-filter--container-status--label', {
                    WAITING: filterRequest.status === PaymentTransactionStatus.WAITING,
                })}
                onClick={() => handleChange({ status: PaymentTransactionStatus.WAITING })}
            >
                {t(`${I18N_PREFIX}.status.options.WAITING`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('payment-transaction-modal-filter--container-status--label', {
                    CONFIRMED: filterRequest.status === PaymentTransactionStatus.CONFIRMED,
                })}
                onClick={() => handleChange({ status: PaymentTransactionStatus.CONFIRMED })}
            >
                {t(`${I18N_PREFIX}.status.options.CONFIRMED`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('payment-transaction-modal-filter--container-status--label', {
                    ERROR: filterRequest.status === PaymentTransactionStatus.ERROR,
                })}
                onClick={() => handleChange({ status: PaymentTransactionStatus.ERROR })}
            >
                {t(`${I18N_PREFIX}.status.options.ERROR`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('payment-transaction-modal-filter--container-status--label', {
                    CANCELLED: filterRequest.status === PaymentTransactionStatus.CANCELLED,
                })}
                onClick={() => handleChange({ status: PaymentTransactionStatus.CANCELLED })}
            >
                {t(`${I18N_PREFIX}.status.options.CANCELLED`).toLocaleUpperCase()}
            </div>
        </>
    );

    const isConfirmButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    return (
        openModal && (
            <Modal isOpen>
                <div className="payment-transaction-modal-filter--container">
                    <div className="payment-transaction-modal-filter--header">
                        <label className="payment-transaction-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            {DateFilter}
                                            {AmountFilter}
                                            {SearchFavoredFilter}
                                            {StatusFilter}
                                        </>
                                    ),
                                    date: DateFilter,
                                    amount: AmountFilter,
                                    favored: SearchFavoredFilter,
                                    account: SearchAccountFilter,
                                    status: StatusFilter,
                                } as Record<PaymentTransactionModalFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>
                    <div className="payment-transaction-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButton label="entity.action.confirm" onClick={handleConfirmRequest} disabled={isConfirmButtonDisabled} />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default PaymentTransactionModalFilter;
