import { ImportedOperationByPaymentTransactionData } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/baseStoreState';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListImportedOperationsByPaymentTransactionTypes {
    LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_REQUEST = '@@payment-transactions-list-imported-operations/LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_REQUEST',
    LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_SUCCESS = '@@payment-transactions-list-imported-operations/LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_SUCCESS',
    LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_ERROR = '@@payment-transactions-list-imported-operations/LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_ERROR',
    LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_RESET_STATE = '@@payment-transactions-list-imported-operations/LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_RESET_STATE',
}

export interface ListImportedOperationsByPaymentTransactionState extends BaseStoreState {
    readonly importedOperations?: Page<ImportedOperationByPaymentTransactionData>;
}
