import { Menu, MenuItem, PopoverOrigin } from '@material-ui/core';
import { OperationApprovalStatus } from 'model/enums/operation-approval-status';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './operation-approval-more-options-menu.scss';
import { OperationApproval } from 'model/operation-approvals';
import RedirectMenuItem from 'components/more-options-menu/redirect-menu-item/redirect-menu-item';

const I18N_PREFIX = 'pages.scd.ccb-operation-approval.more-options';

export type OperationApprovalMoreOptionsType = 'approveOperation' | 'disapproveOperation' | 'viewCcb' | 'cancel' | 'approveMultiplesOperations';

export interface OperationApprovalMoreOptionsMenuProps {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
    operationApproval: OperationApproval | undefined;
    setModalType: (value: OperationApprovalMoreOptionsType | undefined) => void;
}

const transformOrigin: PopoverOrigin = {
    vertical: -38,
    horizontal: 0,
};
const anchorOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center',
};

const OperationApprovalMoreOptionsMenu = (props: OperationApprovalMoreOptionsMenuProps) => {
    const { anchorEl, handleClose, open, operationApproval, setModalType } = props;

    const { t } = useTranslation();

    return (
        <Menu className="button-operation-approval__style--more-options--menu-menuitems" elevation={0} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} anchorEl={anchorEl} open={open} onClose={handleClose}>
            <RedirectMenuItem link={`/imported-operations/view-ccb/${operationApproval?.importedOperationId}`} titleKey={t(`${I18N_PREFIX}.view-ccb`)} handleClose={handleClose} />
            {operationApproval?.status &&
                (
                    {
                        PENDING: (
                            <>
                                <MenuItem
                                    className="button-operation-approval__style--more-options--menu-menuitems-item"
                                    onClick={() => {
                                        setModalType('approveOperation');
                                        handleClose();
                                    }}
                                >
                                    {t(`${I18N_PREFIX}.approve`)}
                                </MenuItem>
                                <MenuItem
                                    className="button-operation-approval__style--more-options--menu-menuitems-item"
                                    onClick={() => {
                                        setModalType('disapproveOperation');
                                        handleClose();
                                    }}
                                >
                                    {t(`${I18N_PREFIX}.disapprove`)}
                                </MenuItem>
                            </>
                        ),
                    } as Record<OperationApprovalStatus, React.ReactNode>
                )[operationApproval.status]}
        </Menu>
    );
};

export default OperationApprovalMoreOptionsMenu;
