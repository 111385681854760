import { Link } from 'react-router-dom';
import BankerEntities from 'shared/banker-navbar/components/banker-entities-menu/banker-entities';
import BankerAccountMenu from './components/banker-account-menu/banker-account-menu';
import './banker-navbar.scss';

export const BankerNavbar = () => {
    return (
        <div className="page-header">
            <div>
                <nav className="page-header--menu">
                    <Link to="/dashboard">
                        <div className="page-header--logo" />
                    </Link>
                    <div className="page-header--items">
                        <BankerEntities />
                    </div>
                    <ul className="page-header--menu-list">
                        <BankerAccountMenu />
                    </ul>
                </nav>
            </div>
        </div>
    );
};
export default BankerNavbar;
