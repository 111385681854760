import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { store } from 'index';
import { GiroWebappErrorConstants } from 'model/enums/error-constants';
import HttpStatus from 'model/enums/http-status';
import { userAccountResetState } from 'reducer/account/user-account/actions';
import { markError } from 'reducer/application/error/actions';
import { loginResetState } from 'reducer/authentication/actions';
import AuthUtil from './authUtil';

const createApiInstance = (): AxiosInstance => {
    const config: AxiosRequestConfig = {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        timeout: 1 * 60 * 1000,
    };

    const instance = axios.create(config);
    instance.interceptors.request.use(async _config => {
        const token = AuthUtil.getToken();
        if (token?.id_token && _config.headers) {
            _config.headers.Authorization = `Bearer ${token.id_token}`;
        }
        return _config;
    });

    instance.interceptors.response.use(
        response => response,
        error => {
            const status = error.response?.status;
            if (status === HttpStatus.UNAUTHORIZED) {
                AuthUtil.removeToken();
                store.dispatch(loginResetState());
                store.dispatch(userAccountResetState());
                setTimeout(() => {
                    store.dispatch(markError({ message: GiroWebappErrorConstants.HTTP_UNAUTHORIZED }));
                }, 1000);
                return Promise.reject(error);
            }
            if (status === HttpStatus.FORBIDDEN) {
                AuthUtil.removeToken();
                store.dispatch(loginResetState());
                store.dispatch(userAccountResetState());
                setTimeout(() => {
                    store.dispatch(markError({ message: GiroWebappErrorConstants.HTTP_FORBIDDEN }));
                }, 1000);
                return Promise.reject(error);
            }

            return Promise.reject(error);
        }
    );

    return instance;
};

export const api = createApiInstance();
export default api;
