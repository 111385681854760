import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import { ErrorUtils } from 'shared/error/error-utils';
import paymentAccountsApi from 'services/api/payment-accounts-api';
import { PaymentAccountBalanceResponse } from 'model/payment-accounts';
import {balancePaymentAccountError, balancePaymentAccountRequest, balancePaymentAccountSuccess} from './actions';
import { BalancePaymentAccountTypes } from './types';

function* handleBalancePaymentAccount(action: ReturnType<typeof balancePaymentAccountRequest>) {
    try {
        const result: AxiosResponse<PaymentAccountBalanceResponse> = yield call(paymentAccountsApi.balancePaymentAccount, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(balancePaymentAccountError(mapped));
            return;
        }
        yield put(balancePaymentAccountSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(balancePaymentAccountError(mapped));
    }
}

function* watchBalancePaymentAccount() {
    yield takeLeading(BalancePaymentAccountTypes.BALANCE_PAYMENT_ACCOUNT_REQUEST, handleBalancePaymentAccount);
}

function* balancePaymentAccountSaga() {
    yield all([fork(watchBalancePaymentAccount)]);
}

export default balancePaymentAccountSaga;
