import { RouteComponentProps, Switch } from 'react-router-dom';
import PrivateRoute from 'shared/routes/private-route';
import { Authority } from 'model/enums/authorities';
import ScdOperationApproval from './ccb-operation-approval';

interface MatchParams {
    url: string;
}

const OperationsApprovalRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}`} component={ScdOperationApproval} rolesAllowed={[Authority.ROLE_BANKER_APPROVAL]} />
    </Switch>
);

export default OperationsApprovalRoutes;
