import { Menu, PopoverOrigin } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './batch-more-options-menu.scss';
import { useRouteMatch } from 'react-router-dom';
import RedirectMenuItem from 'components/more-options-menu/redirect-menu-item/redirect-menu-item';

const I18N_PREFIX = 'pages.scd.batch.more-options';

export type BatchBankerMoreOptionsType = 'selectBatch';

export interface BatchBankerMoreOptionsMenuProps {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
    batchId?: number;
}

const transformOrigin: PopoverOrigin = {
    vertical: -38,
    horizontal: 0,
};
const anchorOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center',
};

const BatchBankerMoreOptionsMenu = (props: BatchBankerMoreOptionsMenuProps) => {
    const { open, anchorEl, handleClose, batchId } = props;

    const { t } = useTranslation();
    const { url } = useRouteMatch();

    return (
        <Menu className="button-batch__style--more-options--menu-menuitems" elevation={0} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} anchorEl={anchorEl} open={open} onClose={handleClose}>
            <RedirectMenuItem link={`${url}/${batchId}/imported-operations`} titleKey={t(`${I18N_PREFIX}.batch-operations`)} handleClose={handleClose}  />
        </Menu>
    );
};

export default BatchBankerMoreOptionsMenu;
