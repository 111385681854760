import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_EMAIL_PREFIX = 'pages.login.email.validation';

const EMAIL_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_EMAIL_PREFIX}.required`,
    },
    format: {
        predicate: ValidationUtils.STRING.validEmail,
        errorMessage: `${I18N_EMAIL_PREFIX}.format`,
    },
    max_length: {
        predicate: ValidationUtils.STRING.maxLength(100),
        errorMessage: `${I18N_EMAIL_PREFIX}.maxLength`,
    },
};
export const validateEmail = (value: string | undefined | null) => ValidationUtils.validate(value, EMAIL_VALIDATION);

const I18N_PASSWORD_PREFIX = 'pages.login.password.validation';

const PASSWORD_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PASSWORD_PREFIX}.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(4),
        errorMessage: `${I18N_PASSWORD_PREFIX}.minLength`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(100),
        errorMessage: `${I18N_PASSWORD_PREFIX}.maxLength`,
    },
};
export const validatePassword = (value: string | undefined | null) => ValidationUtils.validate(value, PASSWORD_VALIDATION);
