import FormControl from '@material-ui/core/FormControl';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import clsx from 'clsx';
import BaseMaskInput, { BaseMaskInputProps } from 'components/inputs/base-mask-input';
import InputUtils from 'components/inputs/input-utils';
import React from 'react';
import IdentificationMask from 'components/inputs/identification-input/identification-mask';

type Base = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'type' | 'onChange'>;

export interface SimpleIdentificationInputProps extends Base {
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
    onChange: (value: string | undefined) => void;
}

export const SimpleIdentificationInput = (props: SimpleIdentificationInputProps) => {
    const { MaskInputComponent, onChange, ...otherProps } = props;

    const handleChange = (_value: string) => {
        const normalized = InputUtils.removeNonDigits(_value);

        if (onChange) {
            onChange(normalized);
        }
    };

    return (
        <FormControl fullWidth className={clsx('base-input', { dirty: props.value })}>
            <BaseMaskInput {...otherProps} onChange={handleChange} MaskInputComponent={MaskInputComponent ?? IdentificationMask} />
        </FormControl>
    );
};

export default SimpleIdentificationInput;
