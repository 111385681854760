import BackButton from 'components/buttons/back-button/back-button';
import EmptyCollectionAware from 'components/collection/empty-collection-aware';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PaymentTransactionToListImportedOperationsRequest } from 'model/payment-transaction';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router';
import { useGetPaymentTransactionOnImportedOperationState, useListImportedOperationsByPaymentTransactionState, useRootDispatch } from 'reducer/hooks';
import { getPaymentTransactionHeaderOnImportedOperationRequest } from 'reducer/payment-transaction/get-payment-transaction-on-imported-operation/actions';
import { listImportedOperationsByPaymentTransactionRequest } from 'reducer/payment-transaction/list-imported-operations-by-payment-transaction/actions';
import { LIST_PAGE_SIZE } from 'services/pageable';
import { SortOrder } from 'services/sort';
import Pagination from 'shared/pagination/pagination';
import ScdImportedOperationsTableHeader, { ImportedOperationsDataSortableProperties } from './components/imported-operations-table-header/imported-operations-table-header';
import ListImportedOperationsTable from './components/list-imported-operations-table/list-imported-operations-table';
import HeaderImportedOperationCard from './header-imported-operation-card/header-imported-operation-card';
import './payment-transaction-imported-operations.scss';

const I18N_PREFIX = 'pages.scd.payment-transaction.imported-operations';

type ScdPaymentTransactionImportedOperationsProps = RouteComponentProps<{ paymentTransactionId: string }>;

export const ScdPaymentTransactionImportedOperations = (props: ScdPaymentTransactionImportedOperationsProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useRootDispatch();

    const [paymentTransactionId] = useState<number>(Number(props.match.params.paymentTransactionId));
    const [page, setPage] = useState<number>(0);
    const [sortedProperty, setSortedProperty] = useState<string | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

    const { importedOperations, status: listStatus } = useListImportedOperationsByPaymentTransactionState();
    const { headerInfoPaymentTransaction, status: headerStatus } = useGetPaymentTransactionOnImportedOperationState();

    const sortPageable = useCallback((): PaymentTransactionToListImportedOperationsRequest => {
        const _pageable = {
            page,
            size: LIST_PAGE_SIZE,
            sort: sortedProperty && sortOrder ? `${sortedProperty},${sortOrder}` : '',
        };

        return {
            pageable: _pageable,
            paymentTransactionId: paymentTransactionId,
        };
    }, [page, sortedProperty, sortOrder, paymentTransactionId]);

    const handleListImportedOperations = useCallback(() => {
        dispatch(listImportedOperationsByPaymentTransactionRequest(sortPageable()));
    }, [dispatch, sortPageable]);

    const handleGetHeaderInfoPaymentTransaction = useCallback(() => {
        dispatch(getPaymentTransactionHeaderOnImportedOperationRequest(paymentTransactionId));
    }, [dispatch, paymentTransactionId]);

    useEffect(() => {
        handleListImportedOperations();
        handleGetHeaderInfoPaymentTransaction();
    }, [handleListImportedOperations, handleGetHeaderInfoPaymentTransaction]);

    const hasError = listStatus === HttpRequestStatus.ERROR || headerStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t(`${I18N_PREFIX}.error-message`)}</div>
                <BackButton label={t('global.try-again')} onClick={handleListImportedOperations} />
            </div>
        );
    }

    const isLoading = listStatus !== HttpRequestStatus.SUCCESS || headerStatus !== HttpRequestStatus.SUCCESS;

    const handlePageChange = (value: { selected: number }) => {
        setPage(value.selected);
    };

    const handleSort = (property: ImportedOperationsDataSortableProperties, order: SortOrder) => {
        if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
            return;
        }
        setSortedProperty(property);
        setSortOrder('asc');
    };

    const isDisabledTableSort = importedOperations?.content && importedOperations.content?.length <= 1 ? true : false;

    return (
        <main className="scd-payment-transaction-imported-operations">
            <ContextRibbon action={() => history.push('/payment-transactions')} />
            {isLoading ? (
                <Loading />
            ) : (
                <section className="scd-payment-transaction-imported-operations__container">
                    <header className="scd-payment-transaction-imported-operations__header">
                        <h2 className="scd-payment-transaction-imported-operations__header--title">{t(`${I18N_PREFIX}.title`)}</h2>
                        <HeaderImportedOperationCard headerInfo={headerInfoPaymentTransaction} />
                        <h4 className="scd-payment-transaction-imported-operations__header--info">
                            <Trans
                                i18nKey={`${I18N_PREFIX}.payment-transaction-info`}
                                t={t}
                                values={{ name: headerInfoPaymentTransaction?.favoredName }}
                                components={{ date: <DateFormatter date={headerInfoPaymentTransaction?.date} format={DateFormat.DAY_MONTH} /> }}
                            />
                        </h4>
                    </header>
                    <div className="scd-payment-transaction-imported-operations__content">
                        <div className="scd-payment-transaction-imported-operations__content-header">
                            <h3 className="scd-payment-transaction-imported-operations__content-header--title"> {t(`${I18N_PREFIX}.table.title`)} </h3>
                            <span className="scd-payment-transaction-imported-operations__content-header--total-itens"> {importedOperations?.numberOfElements ?? 0} </span>
                        </div>
                        <div className="scd-payment-transaction-imported-operations__content-table">
                            <table className="page-container--table">
                                <thead>
                                    <ScdImportedOperationsTableHeader handleSort={handleSort} sortedProperty={sortedProperty} sortOrder={sortOrder} disabled={isDisabledTableSort} />
                                </thead>
                                <tbody>
                                    <EmptyCollectionAware
                                        collection={importedOperations?.content ?? []}
                                        isTable
                                        label={{
                                            key: `${I18N_PREFIX}.not-found`,
                                        }}
                                    >
                                        <ListImportedOperationsTable />
                                    </EmptyCollectionAware>
                                </tbody>
                            </table>
                        </div>
                        {!isLoading && <Pagination page={page} totalPages={importedOperations?.totalPages ?? 0} onChange={handlePageChange} />}
                    </div>
                </section>
            )}
        </main>
    );
};

export default ScdPaymentTransactionImportedOperations;
