import { CURRENCY_FORMATTER, CURRENCY_FORMATTER_PREFIXED, PERCENTAGE_FORMATTER } from 'components/formatter/formatter-constants';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
    CORPORATION_IDENTIFICATION_LENGTH,
    CORPORATION_IDENTIFICATION_MASK,
    CORPORATION_IDENTIFICATION_MASK_REGEX,
    DEFAULT_PHONE_LENGTH,
    DEFAULT_PHONE_MASK,
    DEFAULT_PHONE_REGEX,
    EXTENDED_PHONE_LENGTH,
    EXTENDED_PHONE_MASK,
    EXTENDED_PHONE_REGEX,
    INDIVIDUAL_IDENTIFICATION_LENGTH,
    INDIVIDUAL_IDENTIFICATION_MASK,
    INDIVIDUAL_IDENTIFICATION_MASK_REGEX,
} from 'config/constants';
import { PersonType } from 'model/enums/person-type';

const EMPTY_STRING = '';
const NON_DIGITS_SEARCH_REGEX = /\D/g;
const RG_TO_FORMAT = /(^\d{1,2}).?(\d{3}).?(\d{3})-?(\d{1}|X|x$)/g;

const isNotDefined = (value: any) => value === null || value === undefined;

const formatDate = (date?: Date): string => {
    if (!date) return '';

    return moment(date).format('L');
};

const formatDateTimeUTC = (utcDate?: Date): string => {
    if (!utcDate) return '';

    return new Date(utcDate).toLocaleString();
};

const formatDateTimeCompleteUTC = (utcDate?: Date): string => {
    if (!utcDate) return '';

    return moment(utcDate).format('DD/MM/YYYY - HH:mm:ss');
};

const formatDateTimeWithoutSecondsUTC = (utcDate?: Date): string => {
    if (!utcDate) return '';

    return moment(utcDate).format('DD/MM/YYYY HH:mm');
};

const formatYearMonthDate = (date?: Date): string => {
    if (!date) return '';

    return moment(date).format('MM/YYYY');
};

const formatDayMonthDate = (date?: Date): string => {
    if (!date) return '';

    return moment(date).format('DD/MM');
};

const formatDateToMonthString = (date?: Date): string => {
    if (!date) return '';

    const month = moment(date).format('MMMM');

    return month;
};

const formatPhone = (value?: string): string => {
    if (!value) return EMPTY_STRING;
    if (isNotDefined(value)) return EMPTY_STRING;

    const digits = value.replace(NON_DIGITS_SEARCH_REGEX, EMPTY_STRING);
    if (digits.length === DEFAULT_PHONE_LENGTH) return digits.replace(DEFAULT_PHONE_REGEX, DEFAULT_PHONE_MASK);
    if (digits.length === EXTENDED_PHONE_LENGTH) return digits.replace(EXTENDED_PHONE_REGEX, EXTENDED_PHONE_MASK);
    return value;
};

const formatNumberToPercentage = (value: number | undefined | null, defaultValue?: number): string | undefined => {
    if (isNotDefined(value) && isNotDefined(defaultValue)) return undefined;
    const percentageValue = value ?? defaultValue;
    return PERCENTAGE_FORMATTER.format(percentageValue! / 100);
};

const formatNumberToCurrency = (value: number | undefined | null, defaultValue?: number, prefix?: boolean): string | undefined => {
    if (isNotDefined(value) && isNotDefined(defaultValue)) return undefined;
    if (value !== undefined && value !== null) {
        const currencyValue: number = value ?? defaultValue;
        return prefix ? CURRENCY_FORMATTER_PREFIXED.format(currencyValue) : CURRENCY_FORMATTER.format(currencyValue);
    }
};

const formatIdentification = (type?: PersonType, value: string = ''): string | undefined => {
    if (isNotDefined(value)) return EMPTY_STRING;

    const digits = value.replace(NON_DIGITS_SEARCH_REGEX, EMPTY_STRING);
    if (type === PersonType.INDIVIDUAL && digits.length === INDIVIDUAL_IDENTIFICATION_LENGTH) return digits.replace(INDIVIDUAL_IDENTIFICATION_MASK_REGEX, INDIVIDUAL_IDENTIFICATION_MASK);
    if (type === PersonType.CORPORATION && digits.length === CORPORATION_IDENTIFICATION_LENGTH) return digits.replace(CORPORATION_IDENTIFICATION_MASK_REGEX, CORPORATION_IDENTIFICATION_MASK);
    if (digits.length === INDIVIDUAL_IDENTIFICATION_LENGTH) return digits.replace(INDIVIDUAL_IDENTIFICATION_MASK_REGEX, INDIVIDUAL_IDENTIFICATION_MASK);
    if (digits.length === CORPORATION_IDENTIFICATION_LENGTH) return digits.replace(CORPORATION_IDENTIFICATION_MASK_REGEX, CORPORATION_IDENTIFICATION_MASK);
    return value;
};

const formatStringToZipCode = (value: string | undefined | null) => {
    if (value === null || value === undefined || value.length < 5) {
        return value;
    }
    return `${value.slice(0, 2)}.${value.slice(2, 5)}-${value.slice(5)}`;
};

const formatRg = (value?: string): string => {
    if (!value) return EMPTY_STRING;
    if (isNotDefined(value)) return EMPTY_STRING;

    return value?.replace(RG_TO_FORMAT, '$1.$2.$3-$4');
};

const FormatterUtils = {
    formatDate,
    formatPhone,
    formatNumberToPercentage,
    formatNumberToCurrency,
    formatIdentification,
    formatStringToZipCode,
    formatDateTimeUTC,
    formatDateTimeCompleteUTC,
    formatDateTimeWithoutSecondsUTC,
    formatYearMonthDate,
    formatDateToMonthString,
    formatDayMonthDate,
    formatRg,
};

export default FormatterUtils;
