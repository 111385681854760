import { Grid } from '@material-ui/core';
import PhoneFormatter from 'components/formatter/phone/phone-formatter';
import BaseOperationCard from 'features/ccb-operation/ccb-operation-detail/components/cards/base-operation-card/base-operation-card';
import OperationReadOnlyTextField from 'features/ccb-operation/ccb-operation-detail/components/operation-read-only-text-field/operation-read-only-text-field';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';

interface ContactOperationCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.contact';

const ContactOperationCard = (props: ContactOperationCardProps) => {
    const {
        operation: { resumeDetailOperation },
    } = props;

    const { t } = useTranslation();

    return (
        <BaseOperationCard title={t(`${I18N_PREFIX}.title`)}>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.cellphone`)}
                    value={resumeDetailOperation?.cellPhone ? <PhoneFormatter value={resumeDetailOperation?.cellPhone} /> : '-'}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.phone`)}
                    value={resumeDetailOperation?.phone ? <PhoneFormatter value={resumeDetailOperation?.phone} /> : '-'}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.email`)} value={resumeDetailOperation?.email} />
            </Grid>
        </BaseOperationCard>
    );
};

export default ContactOperationCard;
