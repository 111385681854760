import i18n from 'config/i18n';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { IRootState } from 'reducer';
import configureStore from 'reducer/configureStore';
import { Store } from 'redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

const initialState = window.INITIAL_REDUX_STATE;
export const store: Store<IRootState> = configureStore(initialState);

export type RootDispatcher = typeof store.dispatch;

ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();
