import { Menu, PopoverOrigin } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import './imported-operation-more-option-menu.scss';
import { useRouteMatch } from 'react-router-dom';
import RedirectMenuItem from 'components/more-options-menu/redirect-menu-item/redirect-menu-item';

const I18N_PREFIX = 'pages.scd.ccb-operation.more-options';

export interface ImportedOperationMoreOptionsMenuProps {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
    ccbOperationId?: number;
}

const transformOrigin: PopoverOrigin = {
    vertical: -38,
    horizontal: 0,
};
const anchorOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center',
};

const ImportedOperationMoreOptionsMenu = (props: ImportedOperationMoreOptionsMenuProps) => {
    const { open, anchorEl, handleClose, ccbOperationId } = props;

    const { t } = useTranslation();
    const { url } = useRouteMatch();

    return (
        <Menu className="button__style--more-options--menu-menuitems" elevation={0} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} anchorEl={anchorEl} open={open} onClose={handleClose}>
            <RedirectMenuItem link={`${url}/view-ccb/${ccbOperationId}`} titleKey={t(`${I18N_PREFIX}.view-ccb`)} handleClose={handleClose}  />
        </Menu>
    );
};

export default ImportedOperationMoreOptionsMenu;
