import { RouteComponentProps, Switch } from 'react-router-dom';
import PrivateRoute from 'shared/routes/private-route';
import { Authority } from 'model/enums/authorities';

import ScdBatch from './batch';
import ScdBatchImportedOperations from './imported-operations/imported-operations-batch';

interface MatchParams {
    url: string;
}

const BatchRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}`} component={ScdBatch} rolesAllowed={[Authority.ROLE_BANKER]} />
        <PrivateRoute exact path={`${match.url}/:batchId/imported-operations/`} component={ScdBatchImportedOperations} rolesAllowed={[Authority.ROLE_BANKER]} />
    </Switch>
);

export default BatchRoutes;
