import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '.';
import { RootDispatcher } from '..';

// Authentication
import { AuthenticationState } from 'reducer/authentication/types';

// Application
import { UserAccountState } from 'reducer/account/user-account/types';

// Account
import { ApplicationErrorState } from 'reducer/application/error/types';
import { ForgotPasswordState } from 'reducer/account/forgot-password/types';
import { UserPasswordResetState } from 'reducer/account/password-reset/types';

// Payment Transaction
import { ListPaymentTransactionsState } from './payment-transaction/list-payment-transactions/types';
import { ListImportedOperationsByPaymentTransactionState } from './payment-transaction/list-imported-operations-by-payment-transaction/types';
import { DetailPaymentTransactionVoucherState } from './payment-transaction/detail-voucher-payment-transaction/types';
import { GetPaymentTransactionOnImportedOperationState } from './payment-transaction/get-payment-transaction-on-imported-operation/types';

// Change password
import { ChangePasswordState } from './profile/change-password/types';

// Imported Operations
import { ListImportedOperationsState } from './imported-operation/list-imported-operations/types';
import { DetailImportedOperationState } from './imported-operation/detail-imported-operation/types';
import { ListImportedOperationsByBatchState } from './imported-operation/list-imported-operation-by-batch/types';

// Batches
import { ListBatchBankerState } from './batch/list-batches/types';
import { DetailBatchBankerState } from './batch/detail-batch/types';

// Operation Approvals
import { ListOperationApprovalState } from './operation-approval/list-operation-approvals/types';
import { ApproveOperationApprovalState } from './operation-approval/approve-operation/types';
import { DisapproveOperationApprovalState } from './operation-approval/disapprove-operation/types';
import { ApproveMultiplesOperationsApprovalState } from './operation-approval/approve-multiple-operations/types';

// Payment Accounts
import { ListPaymentAccountsState } from './payment-accounts/list-payment-accounts/types';
import { BalancePaymentAccountState } from './payment-accounts/balance-payment-accounts/types';

export const useRootDispatch = () => useDispatch<RootDispatcher>();

// Aplication
export const useApplicationErrorState = () => useSelector<IRootState, ApplicationErrorState>((state: IRootState) => state.applicationError);

// Authentication
export const useAuthenticationState = () => useSelector<IRootState, AuthenticationState>((state: IRootState) => state.authentication);

// Account
export const useUserAccountState = () => useSelector<IRootState, UserAccountState>((state: IRootState) => state.userAccount);

// Payment Transactions
export const useListPaymentTransactionsState = () => useSelector<IRootState, ListPaymentTransactionsState>((state: IRootState) => state.listPaymentTransactions);
export const useListImportedOperationsByPaymentTransactionState = () => useSelector<IRootState, ListImportedOperationsByPaymentTransactionState>((state: IRootState) => state.listImportedOperationsByPaymentTransaction);
export const useDetaiPaymentTransactionVoucherState = () => useSelector<IRootState, DetailPaymentTransactionVoucherState>((state: IRootState) => state.detailVoucherPaymentTransaction);

// Imported Operation on Payment Transaction
export const useGetPaymentTransactionOnImportedOperationState = () => useSelector<IRootState, GetPaymentTransactionOnImportedOperationState>((state: IRootState) => state.getPaymentTransactionHeaderInfo);

// Imported Operation
export const useListImportedOperationsState = () => useSelector<IRootState, ListImportedOperationsState>((state: IRootState) => state.listImportedOperations);
export const useDetailImportedOperationState = () => useSelector<IRootState, DetailImportedOperationState>((state: IRootState) => state.detailImportedOperation);
export const useListImportedOperationsByBatchState = () => useSelector<IRootState, ListImportedOperationsByBatchState>((state: IRootState) => state.listImportedOperationsByBatch);

// Change Password
export const useChangePasswordState = () => useSelector<IRootState, ChangePasswordState>((state: IRootState) => state.userPasswordChange);

// Forgot Password
export const useForgotPasswordState = () => useSelector<IRootState, ForgotPasswordState>((state: IRootState) => state.forgotPassword);
export const useUserPasswordResetState = () => useSelector<IRootState, UserPasswordResetState>((state: IRootState) => state.passwordReset);

// Batches
export const useListBatchBankerState = () => useSelector<IRootState, ListBatchBankerState>((state: IRootState) => state.listBatchBanker);
export const useDetailBatchBankerState = () => useSelector<IRootState, DetailBatchBankerState>((state: IRootState) => state.detailBatchBanker);

// Operation Approvals
export const useListOperationApprovalsState = () => useSelector<IRootState, ListOperationApprovalState>((state: IRootState) => state.listOperationApprovals);
export const useApproveOperationApprovalState = () => useSelector<IRootState, ApproveOperationApprovalState>((state: IRootState) => state.approveOperationApproval);
export const useDisapproveOperationApprovalState = () => useSelector<IRootState, DisapproveOperationApprovalState>((state: IRootState) => state.disapproveOperationApproval);
export const useApproveMultipleOperationsApprovalState = () => useSelector<IRootState, ApproveMultiplesOperationsApprovalState>((state: IRootState) => state.approveMultiplesOperationsApproval);

// Payment Accounts
export const useListPaymentAccountsState = () => useSelector<IRootState, ListPaymentAccountsState>((state: IRootState) => state.listPaymentAccounts);
export const useBalancePaymentAccountState = () => useSelector<IRootState, BalancePaymentAccountState>((state: IRootState) => state.balancePaymentAccount);
