import { useMemo } from 'react';
import { useUserAccountState } from 'reducer/hooks';

interface MenuRolePermissionValidationComponentProps {
    rolesAllowed?: string[];
    children: React.ReactNode;
}

const MenuRolePermissionValidationComponent = (props: MenuRolePermissionValidationComponentProps) => {
    const { rolesAllowed, children } = props;

    const { account } = useUserAccountState();

    const authorizedAccount: boolean | undefined = useMemo(() => account?.authorities?.some(it => rolesAllowed?.includes(it)), [account?.authorities, rolesAllowed]);

    return authorizedAccount ? <>{children}</> : <></>;
};

export default MenuRolePermissionValidationComponent;
