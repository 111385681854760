import { GiroWebappError } from 'model/error';
import { ScdBatchBankerDetail } from 'model/batch';
import { DetailBatchBankerTypes } from './types';

import { action } from 'typesafe-actions';

export const detailBatchBankerRequest = (batchId: number) => action(DetailBatchBankerTypes.DETAIL_BATCH_BANKER_REQUEST, batchId);
export const detailBatchBankerSuccess = (batchDetail: ScdBatchBankerDetail) => action(DetailBatchBankerTypes.DETAIL_BATCH_BANKER_SUCCESS, batchDetail);
export const detailBatchBankerError = (error: GiroWebappError) => action(DetailBatchBankerTypes.DETAIL_BATCH_BANKER_ERROR, error);
export const detailBatchBankerResetState = () => action(DetailBatchBankerTypes.DETAIL_BATCH_BANKER_RESET_STATE);
