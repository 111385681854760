import { GiroWebappError } from 'model/error';
import { ImportedOperationByPaymentTransactionData } from 'model/imported-operation';
import { PaymentTransactionToListImportedOperationsRequest } from 'model/payment-transaction';
import { ListImportedOperationsByPaymentTransactionTypes } from 'reducer/payment-transaction/list-imported-operations-by-payment-transaction/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listImportedOperationsByPaymentTransactionRequest = (request: PaymentTransactionToListImportedOperationsRequest) => action(ListImportedOperationsByPaymentTransactionTypes.LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_REQUEST, request);
export const listImportedOperationsByPaymentTransactionSuccess = (importedOperations: Page<ImportedOperationByPaymentTransactionData>) => action(ListImportedOperationsByPaymentTransactionTypes.LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_SUCCESS, importedOperations);
export const listImportedOperationsByPaymentTransactionError = (error: GiroWebappError) => action(ListImportedOperationsByPaymentTransactionTypes.LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_ERROR, error);
export const listImportedOperationsByPaymentTransactionResetState = () => action(ListImportedOperationsByPaymentTransactionTypes.LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_RESET_STATE);
