import { Grid } from '@material-ui/core';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import PhoneFormatter from 'components/formatter/phone/phone-formatter';
import BaseOperationCard from 'features/ccb-operation/ccb-operation-detail/components/cards/base-operation-card/base-operation-card';
import OperationReadOnlyTextField from 'features/ccb-operation/ccb-operation-detail/components/operation-read-only-text-field/operation-read-only-text-field';
import { JointAccountType } from 'model/enums/joint-account-type';
import { PersonType } from 'model/enums/person-type';
import { PixKeyType } from 'model/enums/pix-key-type';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';
import { getPersonType } from 'shared/util/person-type-utils';

interface AccountOperationCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.account';

const AccountOperationCard = (props: AccountOperationCardProps) => {
    const {
        operation,
        operation: { importedBankAccount },
    } = props;

    const { t } = useTranslation();

    const getPixKey = (): JSX.Element => {
        if (operation.importedPix?.type === PixKeyType.INDIVIDUAL || operation.importedPix?.type === PixKeyType.CORPORATION) {
            return <IdentificationFormatter value={operation.importedPix?.key} type={getPersonType(operation.importedPix?.key)} />;
        }

        if (operation.importedPix?.type === PixKeyType.PHONE_NUMBER) {
            return <PhoneFormatter value={operation.importedPix?.key} />;
        }

        return <>{operation.importedPix?.key}</>;
    };

    return (
        <BaseOperationCard title={t(`${I18N_PREFIX}.title`)} isOpenable>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.bank.label`)}
                    value={importedBankAccount?.bankNumber && `${importedBankAccount?.bankNumber} - ${importedBankAccount?.bankName ?? t(`${I18N_PREFIX}.fields.bank.noBank`)}`}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.type.label`)} value={t(`${I18N_PREFIX}.fields.type.${importedBankAccount?.type}`)} />
            </Grid>
            <Grid item xs={3}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.agency`)} value={importedBankAccount?.agencyNumber} />
            </Grid>
            <Grid item xs={3}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.digit`)} value={importedBankAccount?.agencyDigit} />
            </Grid>
            <Grid item xs={2}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.account`)} value={importedBankAccount?.accountNumber} />
            </Grid>
            <Grid item xs={2}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.digit`)} value={importedBankAccount?.accountDigit} />
            </Grid>
            <Grid item xs={2}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.operation`)} value={importedBankAccount?.operation} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.jointAccount`)} value={importedBankAccount?.jointAccount ? JointAccountType.YES : JointAccountType.NO} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.favoredIdentification`)}
                    value={importedBankAccount?.favoredIdentification ? <IdentificationFormatter value={importedBankAccount?.favoredIdentification} type={PersonType.INDIVIDUAL} /> : '-'}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.pixKey`)} value={operation.importedPix ? getPixKey() : '-'} />
            </Grid>
        </BaseOperationCard>
    );
};

export default AccountOperationCard;
