import { ScdPaymentTransactionData } from 'model/payment-transaction';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum GetPaymentTransactionOnImportedOperationTypes {
    GET_PAYMENT_TRANSACTION_ON_IMPORTED_OPERATION_REQUEST = '@@get-payment-transaction-by-id/GET_PAYMENT_TRANSACTION_ON_IMPORTED_OPERATION_REQUEST',
    GET_PAYMENT_TRANSACTION_ON_IMPORTED_OPERATION_SUCCESS = '@@get-payment-transaction-by-id/GET_PAYMENT_TRANSACTION_ON_IMPORTED_OPERATION_SUCCESS',
    GET_PAYMENT_TRANSACTION_ON_IMPORTED_OPERATION_ERROR = '@@get-payment-transaction-by-id/GET_PAYMENT_TRANSACTION_ON_IMPORTED_OPERATION_ERROR',
    GET_PAYMENT_TRANSACTION_ON_IMPORTED_OPERATION_RESET_STATE = '@@get-payment-transaction-by-id/GET_PAYMENT_TRANSACTION_ON_IMPORTED_OPERATION_RESET_STATE',
}

export interface GetPaymentTransactionOnImportedOperationState extends BaseStoreState {
    readonly headerInfoPaymentTransaction?: ScdPaymentTransactionData;
}
