import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ImportedOperationByPaymentTransactionData } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import { listImportedOperationsByPaymentTransactionError, listImportedOperationsByPaymentTransactionRequest, listImportedOperationsByPaymentTransactionSuccess } from 'reducer/payment-transaction/list-imported-operations-by-payment-transaction/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import paymentTransactionAPI from 'services/api/paymentTransactionApi';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';
import { ListImportedOperationsByPaymentTransactionTypes } from './types';

function* handleListImportedOperationsByPaymentTransaction(action: ReturnType<typeof listImportedOperationsByPaymentTransactionRequest>) {
    try {
        const result: AxiosResponse<Page<ImportedOperationByPaymentTransactionData>> = yield call(paymentTransactionAPI.listImportedOperationsByPaymentTransaction, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(listImportedOperationsByPaymentTransactionError(mapped));
            return;
        }
        yield put(listImportedOperationsByPaymentTransactionSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listImportedOperationsByPaymentTransactionError(mapped));
    }
}

function* watchListImportedOperationsByPaymentTransaction() {
    yield takeEvery(ListImportedOperationsByPaymentTransactionTypes.LIST_IMPORTED_OPERATIONS_BY_PAYMENT_TRANSACTION_REQUEST, handleListImportedOperationsByPaymentTransaction);
}

function* listImportedOperationsByPaymentTransactionSaga() {
    yield all([fork(watchListImportedOperationsByPaymentTransaction)]);
}

export default listImportedOperationsByPaymentTransactionSaga;
