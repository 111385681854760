import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { valuesOfObject } from 'shared/util/object-utils';
import FilterButton from 'components/buttons/filter-button/filter-button';
import FilterListIcon from '@material-ui/icons/FilterList';
import { OperationApprovalFilterRequest } from 'model/operation-approvals';
import { OperationApprovalModalFilterType } from '../operation-approval-modal-filter/operation-approval-modal-filter';
import { Tooltip } from '@material-ui/core';

const I18N_PREFIX = 'pages.scd.ccb-operation-approval.filter.buttons';

interface OperationApporvalFilterButtonsProps {
    setModalFilter: (value: OperationApprovalModalFilterType) => void;
    setOpenModalFilter: (value: boolean) => void;
    filterActive: OperationApprovalFilterRequest;
    handleResetFilterActive: () => void;
}

const OperationApprovalFilterButtons = (props: OperationApporvalFilterButtonsProps) => {
    const { filterActive, handleResetFilterActive, setOpenModalFilter, setModalFilter } = props;

    const { t } = useTranslation();

    const arrowFilterIcon = (
        <React.Fragment>
            <ArrowDropDownIcon />
        </React.Fragment>
    );

    const isActiveFilter = valuesOfObject({ ...filterActive, status: filterActive?.status?.length !== 2 ? filterActive.status : undefined }).some(it => it !== undefined);

    return (
        <>
            <div className="scd-ccb-operation-approval__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('filters');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.filters`)}
                    icon={<FilterListIcon />}
                    isActiveFilter={!!isActiveFilter}
                />
            </div>
            <div className="scd-ccb-operation-approval__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('ccbNumber');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.ccbNumber`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={!!filterActive.ccbNumber}
                />
            </div>
            <div className="scd-ccb-operation-approval__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('status');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.status`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={filterActive.status?.length !== 2}
                />
            </div>
            {isActiveFilter && (
                <Tooltip title={String(t('global.remove-filter.title'))}>
                    <div className="scd-ccb-operation-approval__header--buttons-content-error-arrow" onClick={handleResetFilterActive} />
                </Tooltip>
            )}
        </>
    );
};

export default OperationApprovalFilterButtons;
