import RefreshIcon from '@material-ui/icons/Refresh';
import BackButton from 'components/buttons/back-button/back-button';
import Loading from 'components/loading/loading';
import ScdOperationHeader, { ImportedOperationsDataSortableProperties } from 'features/ccb-operation/components/operation-header/operation-header';
import OperationModalFilter, { OperationModalFilterType } from 'features/ccb-operation/components/operation-modal-filter/operation-modal-filter';
import OperationsList from 'features/ccb-operation/components/operations-list/operations-list';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { defaultImportedOperationFilter, ImportedOperationFilterPageable, ImportedOperationFilterRequest } from 'model/imported-operation';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useListImportedOperationsState, useRootDispatch } from 'reducer/hooks';
import { listImportedOperationsRequest, listImportedOperationsResetState } from 'reducer/imported-operation/list-imported-operations/actions';
import { Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import Pagination from 'shared/pagination/pagination';

import './ccb-operation.scss';
import OperationFilterButtons from './components/operation-filter-buttons/operations-filter-buttons';

const I18N_PREFIX = 'pages.scd.ccb-operation';

const ScdCcbOperation = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(0);
    const dispatch = useRootDispatch();

    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [modalFilter, setModalFilter] = useState<OperationModalFilterType | undefined>(undefined);
    const [openModalFilter, setOpenModalFilter] = useState<boolean>(true);
    const [filterActive, setFilterActive] = useState<ImportedOperationFilterRequest>(defaultImportedOperationFilter);

    const { operations, status: listStatus } = useListImportedOperationsState();

    const filterPageable = useCallback((): ImportedOperationFilterPageable => {
        const _pageable: Pageable = {
            page: page,
            size: 8,
            sort: `${sortedProperty},${sortOrder}`,
        };

        const _filters = filterActive ?? {};

        return {
            pageable: _pageable,
            filter: _filters,
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    const handleListOperations = useCallback(() => {
        dispatch(listImportedOperationsRequest(filterPageable()));
    }, [dispatch, filterPageable]);

    useEffect(() => {
        handleListOperations();
    }, [handleListOperations]);

    useEffect(() => {
        return () => {
            dispatch(listImportedOperationsResetState());
        };
    }, [dispatch, filterPageable]);

    const hasError = listStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('pages.scd.ccb-operation.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleListOperations} />
            </div>
        );
    }

    const isLoading = listStatus !== HttpRequestStatus.SUCCESS;

    const handlePageChange = (value: { selected: number }) => {
        setPage(value.selected);
    };

    const handleSort = (property: ImportedOperationsDataSortableProperties, order: SortOrder) => {
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const handleResetFilterActive = () => {
        setPage(0);
        setFilterActive({ status: [] });
    };

    return (
        <main className="scd-ccb-operation">
            <section className="scd-ccb-operation__container">
                <header className="scd-ccb-operation__header">
                    <div className="scd-ccb-operation__header-container">
                        <h2 className="scd-ccb-operation__header-container--title">{t(`${I18N_PREFIX}.title`)}</h2>
                        <RefreshIcon className="scd-ccb-operation__header-container--refresh-icon" onClick={handleListOperations} />
                    </div>
                    <div className="scd-ccb-operation__header--filter-content">
                        <div className="scd-ccb-operation__header--buttons-content">
                            <OperationFilterButtons filterActive={filterActive} setModalFilter={setModalFilter} setOpenModalFilter={setOpenModalFilter} handleResetFilterActive={handleResetFilterActive} />
                        </div>
                    </div>
                </header>
                <div className="scd-ccb-operation__table">
                    <table className="page-container--table">
                        <thead>
                            <ScdOperationHeader operations={operations?.content ?? []} handleSort={handleSort} sortedProperty={sortedProperty} sortOrder={sortOrder} />
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <Loading isTable />
                            ) : (
                                <>
                                    <OperationsList filterActive={filterActive} />
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
                {!isLoading && <Pagination page={page} totalPages={operations?.totalPages ?? 0} onChange={handlePageChange} />}
                {modalFilter && (
                    <OperationModalFilter
                        openModal={openModalFilter}
                        modalFilter={modalFilter}
                        onClose={() => setOpenModalFilter(false)}
                        title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                        setFilterActive={setFilterActive}
                        filterActive={filterActive}
                        setPage={setPage}
                    />
                )}
            </section>
        </main>
    );
};

export default ScdCcbOperation;
