import { AxiosResponse } from 'axios';
import { ScdPaymentTransactionVoucher } from 'model/payment-transaction';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import paymentTransactionApi from 'services/api/paymentTransactionApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { detailVoucherPaymentTransactionError, detailVoucherPaymentTransactionRequest, detailVoucherPaymentTransactionSuccess } from './actions';
import { DetailPaymentTransactionVoucherTypes } from './types';

function* handleDetailVoucherPaymentTransaction(action: ReturnType<typeof detailVoucherPaymentTransactionRequest>) {
    try {
        const result: AxiosResponse<ScdPaymentTransactionVoucher> = yield call(paymentTransactionApi.detailVoucherPaymentTransaction, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(detailVoucherPaymentTransactionError(mapped));
            return;
        }
        yield put(detailVoucherPaymentTransactionSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailVoucherPaymentTransactionError(mapped));
    }
}

function* watchDetailVoucherPaymentTransaction() {
    yield takeEvery(DetailPaymentTransactionVoucherTypes.DETAIL_PAYMENT_TRANSACTION_VOUCHER_REQUEST, handleDetailVoucherPaymentTransaction);
}

function* detailVoucherPaymentTransactionSaga() {
    yield all([fork(watchDetailVoucherPaymentTransaction)]);
}

export default detailVoucherPaymentTransactionSaga;
