import clsx from 'clsx';
import { OperationApprovalStatus } from 'model/enums/operation-approval-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './switch-pending-history-button.scss';

interface SwitchPendingHistoryButtonProps {
    isDisabled?: boolean;
    isDefaultPending?: boolean;
    onSwitch: (value: OperationApprovalStatus[]) => void;
}

export const SwitchPendingHistoryButton = (props: SwitchPendingHistoryButtonProps) => {
    const { isDisabled, isDefaultPending, onSwitch } = props;

    const { t } = useTranslation();

    const [checkedValue, setCheckedValue] = useState<boolean | undefined>(isDefaultPending);

    const handleSwitch = () => {
        if (isDisabled) return;

        setCheckedValue(!checkedValue);
        if (checkedValue) {
            return onSwitch([OperationApprovalStatus.APPROVED, OperationApprovalStatus.DISAPPROVED]);
        }

        return onSwitch([OperationApprovalStatus.PENDING]);
    };

    useEffect(() => {
        setCheckedValue(isDefaultPending);
    }, [isDefaultPending]);

    return (
        <div className="switch-pending-history-button">
            <div
                className={clsx('switch-pending-history-button--content', {
                    active: checkedValue,
                    disabled: isDisabled,
                })}
                onClick={() => {
                    if (checkedValue) return;
                    handleSwitch();
                }}
            >
                {t('switch.pending')}
            </div>
            <div
                className={clsx('switch-pending-history-button--content', {
                    active: !checkedValue,
                    disabled: isDisabled,
                })}
                onClick={() => {
                    if (!checkedValue) return;
                    handleSwitch();
                }}
            >
                {t('switch.history')}
            </div>
        </div>
    );
};

export default SwitchPendingHistoryButton;
