// Application error
import { applicationErrorReducer } from 'reducer/application/error/reducer';
import { ApplicationErrorState } from 'reducer/application/error/types';

// Account
import { AccountReducer, accountSagas, AccountState } from 'reducer/account/account.reducer';

// Authentication
import { authenticationReducer } from 'reducer/authentication/reducer';
import authenticationSaga from 'reducer/authentication/sagas';
import { AuthenticationState } from 'reducer/authentication/types';

import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Payment Transaction
import { PaymentTransactionReducer, paymentTransactionSagas, PaymentTransactionState } from './payment-transaction/payment-transaction.reducer';

// Profile
import { ProfileReducer, profileSagas, ProfileState } from './profile/profile.reducer';

// Imported Operation
import { ImportedOperationReducer, importedOperationSagas, ImportedOperationState } from './imported-operation/imported-operation.reducer';

// Batches
import { BatchBankerReducer, BatchBankerState, batchBankerSagas } from './batch/batch.reducer';

// Operation Approvals
import { OperationApprovalsReducer, OperationApprovalsState, operationApprovalsSagas } from './operation-approval/operation-approval.reducer';

// Payment Accounts
import { PaymentAccountsReducer, paymentAccountsSagas, PaymentAccountsState } from './payment-accounts/payment-accounts.reducer';

export interface IRootState extends AccountState, ProfileState, PaymentTransactionState, ImportedOperationState, BatchBankerState, OperationApprovalsState, PaymentAccountsState {
    readonly applicationError: ApplicationErrorState;
    readonly authentication: AuthenticationState;
}

const rootReducer = combineReducers<IRootState>({
    authentication: authenticationReducer,
    applicationError: applicationErrorReducer,
    ...PaymentTransactionReducer,
    ...ProfileReducer,
    ...AccountReducer,
    ...ImportedOperationReducer,
    ...BatchBankerReducer,
    ...OperationApprovalsReducer,
    ...PaymentAccountsReducer
});

export type RootReducer = typeof rootReducer;

export function* rootSaga() {
    yield all([fork(authenticationSaga), fork(accountSagas), fork(profileSagas), fork(paymentTransactionSagas), fork(importedOperationSagas), fork(batchBankerSagas), fork(operationApprovalsSagas), fork(paymentAccountsSagas)]);
}

export default rootReducer;
