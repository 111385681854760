import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// List payment transactions
import { listPaymentTransactionsReducer } from 'reducer/payment-transaction/list-payment-transactions/reducer';
import listPaymentTransactionsSaga from 'reducer/payment-transaction/list-payment-transactions/sagas';
import { ListPaymentTransactionsState } from 'reducer/payment-transaction/list-payment-transactions/types';

import { ListImportedOperationsByPaymentTransactionState } from './list-imported-operations-by-payment-transaction/types';
import { listImportedOperationsByPaymentTransactionReducer } from './list-imported-operations-by-payment-transaction/reducer';
import listImportedOperationsByPaymentTransactionSaga from './list-imported-operations-by-payment-transaction/sagas';

// Detail Voucher Payment Transaction
import { detailPaymentTransactionVoucherReducer } from './detail-voucher-payment-transaction/reducer';
import { DetailPaymentTransactionVoucherState } from './detail-voucher-payment-transaction/types';
import detailVoucherPaymentTransactionSaga from './detail-voucher-payment-transaction/sagas';

// Payment transaction header on Imported Operation
import { getPaymentTransactionOnImportedOperationReducer } from '../payment-transaction/get-payment-transaction-on-imported-operation/reducer';
import { GetPaymentTransactionOnImportedOperationState } from '../payment-transaction/get-payment-transaction-on-imported-operation/types';
import getPaymentTransactionHeaderOnImportedOperationSaga from '../payment-transaction/get-payment-transaction-on-imported-operation/sagas';

export interface PaymentTransactionState {
    readonly listPaymentTransactions: ListPaymentTransactionsState;
    readonly listImportedOperationsByPaymentTransaction: ListImportedOperationsByPaymentTransactionState;
    readonly detailVoucherPaymentTransaction: DetailPaymentTransactionVoucherState;
    readonly getPaymentTransactionHeaderInfo: GetPaymentTransactionOnImportedOperationState;
}

export const PaymentTransactionReducer: ReducersMapObject<PaymentTransactionState, AnyAction> = {
    listPaymentTransactions: listPaymentTransactionsReducer,
    listImportedOperationsByPaymentTransaction: listImportedOperationsByPaymentTransactionReducer,
    detailVoucherPaymentTransaction: detailPaymentTransactionVoucherReducer,
    getPaymentTransactionHeaderInfo: getPaymentTransactionOnImportedOperationReducer,
};

export function* paymentTransactionSagas() {
    yield all([fork(listPaymentTransactionsSaga), fork(listImportedOperationsByPaymentTransactionSaga), fork(detailVoucherPaymentTransactionSaga), fork(getPaymentTransactionHeaderOnImportedOperationSaga)]);
}
