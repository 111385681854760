import { UserAccount } from 'model/user';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, string>;

export enum UserAccountActionTypes {
    USER_ACCOUNT_REQUEST = '@@recoverUserAccount/USER_ACCOUNT_REQUEST',
    USER_ACCOUNT_SUCCESS = '@@recoverUserAccount/USER_ACCOUNT_SUCCESS',
    USER_ACCOUNT_ERROR = '@@recoverUserAccount/USER_ACCOUNT_ERROR',

    USER_ACCOUNT_RESET_STATE = '@@recoverUserAccount/USER_ACCOUNT_RESET_STATE',
}

export interface UserAccountState extends BaseStoreState {
    readonly account?: UserAccount;
}
