import RefreshIcon from '@material-ui/icons/Refresh';
import BackButton from 'components/buttons/back-button/back-button';
import Loading from 'components/loading/loading';
import ListPaymentTransactions from 'features/payment-transaction/components/list-payment-transactions/list-payment-transactions';
import PaymentTransactionFilterButtons from 'features/payment-transaction/components/payment-transaction-filter-buttons/payment-transaction-filter-buttons';
import PaymentTransactionModalFilter, { PaymentTransactionModalFilterType } from 'features/payment-transaction/components/payment-transaction-modal-filter/payment-transaction-modal-filter';
import ScdPaymentTransactionHeader, { PaymentTransactionDataSortableProperties } from 'features/payment-transaction/components/payment-transactions-header/payment-transactions-header';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { defaultPaymentTransactionFilterRequest, PaymentTransactionFilterPageable, PaymentTransactionFilterRequest } from 'model/payment-transaction';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useListPaymentTransactionsState, useRootDispatch } from 'reducer/hooks';
import { listPaymentTransactionsRequest, listPaymentTransactionsResetState } from 'reducer/payment-transaction/list-payment-transactions/actions';
import { LIST_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import Pagination from 'shared/pagination/pagination';
import './payment-transaction.scss';

const I18N_PREFIX = 'pages.scd.payment-transaction';

const ScdPaymentTransaction = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(0);
    const dispatch = useRootDispatch();
    const [sortedProperty, setSortedProperty] = useState<PaymentTransactionDataSortableProperties | undefined>('created_date');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [modalFilter, setModalFilter] = useState<PaymentTransactionModalFilterType | undefined>(undefined);
    const [openModalFilter, setOpenModalFilter] = useState<boolean>(true);
    const [filterActive, setFilterActive] = useState<PaymentTransactionFilterRequest>(defaultPaymentTransactionFilterRequest);

    const { paymentTransactions, status: listStatus } = useListPaymentTransactionsState();

    const filterPageable = useCallback((): PaymentTransactionFilterPageable => {
        const _pageable: Pageable = {
            page: page,
            size: LIST_PAGE_SIZE,
            sort: sortedProperty && sortOrder ? `${sortedProperty},${sortOrder}` : '',
        };
        const _filter: PaymentTransactionFilterRequest = filterActive ?? {};

        return {
            pageable: _pageable,
            filter: _filter,
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    const handleListTransactions = useCallback(() => {
        dispatch(listPaymentTransactionsRequest(filterPageable()));
    }, [dispatch, filterPageable]);

    useEffect(() => {
        handleListTransactions();
    }, [handleListTransactions]);

    useEffect(() => {
        return () => {
            dispatch(listPaymentTransactionsResetState());
        };
    }, [dispatch, filterPageable]);

    const hasError = listStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('pages.scd.payment-transaction.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleListTransactions} />
            </div>
        );
    }

    const isLoading = listStatus !== HttpRequestStatus.SUCCESS;

    const handlePageChange = (value: { selected: number }) => {
        setPage(value.selected);
    };

    const handleSort = (property: PaymentTransactionDataSortableProperties, order: SortOrder) => {
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty(undefined);
            setSortOrder(undefined);
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const handleResetFilterActive = () => {
        setPage(0);
        setFilterActive(defaultPaymentTransactionFilterRequest);
    };

    return (
        <>
            <main className="scd-payment-transaction">
                <section className="scd-payment-transaction__container">
                    <header className="scd-payment-transaction__header">
                        <h2 className="scd-payment-transaction__header--title">
                            {t(`${I18N_PREFIX}.title`)}
                            <RefreshIcon className="scd-payment-transaction__header--title-refresh-icon" onClick={handleListTransactions} />
                        </h2>
                        <div className="scd-payment-transaction__header--buttons-content">
                            <PaymentTransactionFilterButtons filterActive={filterActive} handleResetFilterActive={handleResetFilterActive} setModalFilter={setModalFilter} setOpenModalFilter={setOpenModalFilter} />
                        </div>
                    </header>
                    <div className="scd-payment-transaction__table">
                        <table className="page-container--table">
                            <thead>
                                <ScdPaymentTransactionHeader paymentTransactions={paymentTransactions?.content ?? []} handleSort={handleSort} sortedProperty={sortedProperty} sortOrder={sortOrder} />
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <Loading isTable />
                                ) : (
                                    <>
                                        <ListPaymentTransactions filterActive={filterActive} />
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {!isLoading && <Pagination page={page} totalPages={paymentTransactions?.totalPages ?? 0} onChange={handlePageChange} />}
                    {modalFilter && (
                        <PaymentTransactionModalFilter
                            openModal={openModalFilter}
                            modalFilter={modalFilter}
                            onClose={() => setOpenModalFilter(false)}
                            title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                            setFilterActive={setFilterActive}
                            filterActive={filterActive}
                            setPage={setPage}
                        />
                    )}
                </section>
            </main>
        </>
    );
};

export default ScdPaymentTransaction;
