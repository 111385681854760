import RefreshIcon from '@material-ui/icons/Refresh';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRootDispatch, useListBatchBankerState } from 'reducer/hooks';
import { HttpRequestStatus } from 'model/enums/http-request-status';

import { BatchBankerFilterRequest, BatchBankerFilterPageable, defaultBatchBankerFilter } from 'model/batch';
import { listBatchBankerRequest, listBatchBankerResetState } from 'reducer/batch/list-batches/actions';

import ScdBatchHeader, { BatchBankerDataSortableProperties } from './components/batch-header/batch-header';
import BatchesList from './components/batches-list/batches-list';

import './batch.scss';
import { LIST_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import Loading from 'components/loading/loading';
import BackButton from 'components/buttons/back-button/back-button';
import Pagination from 'shared/pagination/pagination';
import BatchFilterButtons from './components/batch-filter-buttons/batch-filter-buttons';
import BatchModalFilter, { BatchModalFilterType } from './components/batch-modal-filter/batch-modal-filter';

const I18N_PREFIX = 'pages.scd.batch';

const ScdBatch = () => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const [sortedProperty, setSortedProperty] = useState<BatchBankerDataSortableProperties | undefined>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [filterActive, setFilterActive] = useState<BatchBankerFilterRequest>(defaultBatchBankerFilter);
    const [page, setPage] = useState<number>(0);
    const [openModal, setOpenModal] = useState<boolean>(true);
    const [modalFilter, setModalFilter] = useState<BatchModalFilterType | undefined>(undefined);

    const { batches, status: listBatchesStatus } = useListBatchBankerState();

    const filterPageable = useCallback((): BatchBankerFilterPageable => {
        const _pageable: Pageable = {
            page: page,
            size: LIST_PAGE_SIZE,
            sort: sortedProperty && sortOrder ? `${sortedProperty},${sortOrder}` : '',
        };

        const _filters: BatchBankerFilterRequest = filterActive ?? {};

        return {
            pageable: _pageable,
            filter: _filters,
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    const handleListBatchesBanker = useCallback(() => {
        dispatch(listBatchBankerRequest(filterPageable()));
    }, [dispatch, filterPageable]);

    useEffect(() => {
        handleListBatchesBanker();
    }, [handleListBatchesBanker]);

    useEffect(() => {
        return () => {
            dispatch(listBatchBankerResetState());
        };
    }, [dispatch, filterPageable]);

    const hasError = listBatchesStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('pages.scd.ccb-operation.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleListBatchesBanker} />
            </div>
        );
    }

    const isLoading = listBatchesStatus !== HttpRequestStatus.SUCCESS;

    const handlePageChange = (value: { selected: number }) => {
        setPage(value.selected);
    };

    const handleSort = (property: BatchBankerDataSortableProperties, order: SortOrder) => {
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const handleResetFilterActive = () => {
        setPage(0);
        setFilterActive(defaultBatchBankerFilter);
    };

    return (
        <>
            <main className="scd-batch">
                <section className="scd-batch__container">
                    <header className="scd-batch__header">
                        <div className="scd-batch__header-container">
                            <h2 className="scd-batch__header-container--title">{t(`${I18N_PREFIX}.title`)}</h2>
                            <RefreshIcon className="scd-batch__header-container--refresh-icon" onClick={handleListBatchesBanker} />
                        </div>
                        <div className="scd-batch__header--filter-content">
                            <div className="scd-batch__header--buttons-content">
                                <BatchFilterButtons filterActive={filterActive} handleResetFilterActive={handleResetFilterActive} setModalFilter={setModalFilter} setOpenModal={setOpenModal} />
                            </div>
                        </div>
                    </header>
                    <div className="scd-batch__table">
                        <table className="page-container--table">
                            <thead>
                                <ScdBatchHeader batches={batches?.content ?? []} sortOrder={sortOrder} handleSort={handleSort} sortedProperty={sortedProperty} />
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <Loading isTable />
                                ) : (
                                    <>
                                        <BatchesList filterActive={filterActive} />
                                    </>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {!isLoading && <Pagination page={page} totalPages={batches?.totalPages ?? 0} onChange={handlePageChange} />}
                    {modalFilter && (
                        <BatchModalFilter
                            openModal={openModal}
                            title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                            onClose={() => setOpenModal(false)}
                            modalFilter={modalFilter}
                            setFilterActive={setFilterActive}
                            filterActive={filterActive}
                            setPage={setPage}
                        />
                    )}
                </section>
            </main>
        </>
    );
};

export default ScdBatch;
