import { ScdBatchBankerDetail } from 'model/batch';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum DetailBatchBankerTypes {
    DETAIL_BATCH_BANKER_REQUEST = '@@detail-batch-banker/DETAIL_BATCH_BANKER_REQUEST',
    DETAIL_BATCH_BANKER_SUCCESS = '@@detail-batch-banker/DETAIL_BATCH_BANKER_SUCCESS',
    DETAIL_BATCH_BANKER_ERROR = '@@detail-batch-banker/DETAIL_BATCH_BANKER_ERROR',
    DETAIL_BATCH_BANKER_RESET_STATE = '@@detail-batch-banker/DETAIL_BATCH_BANKER_RESET_STATE',
}

export interface DetailBatchBankerState extends BaseStoreState {
    readonly batch?: ScdBatchBankerDetail;
}
