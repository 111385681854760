import { AxiosResponse } from 'axios';
import { Token } from 'model/authentication';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import AuthUtil from 'services/api/authUtil';
import authenticationApi from 'services/api/authenticationApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { loginError, loginRequest, loginSuccess } from './actions';
import { AuthenticationActionTypes } from './types';

function* handleLogin(action: ReturnType<typeof loginRequest>) {
    try {
        const result: AxiosResponse<Token> = yield call(authenticationApi.login, action.payload);

        if (result.status !== 200 || !result.data) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(loginError(mapped));
            return;
        }

        const token: Token = result.data;
        AuthUtil.setToken(token);

        yield put(loginSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(loginError(mapped));
    }
}

function* watchLoginRequest() {
    yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, handleLogin);
}

function* authenticationSaga() {
    yield all([fork(watchLoginRequest)]);
}

export default authenticationSaga;
