import { GiroWebappError } from 'model/error';
import { BatchBanker, BatchBankerFilterPageable } from 'model/batch';
import { ListBatchBankerTypes } from './types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listBatchBankerRequest = (request: BatchBankerFilterPageable) => action(ListBatchBankerTypes.LIST_BATCH_BANKER_REQUEST, request);
export const listBatchBankerSuccess = (data: Page<BatchBanker>) => action(ListBatchBankerTypes.LIST_BATCH_BANKER_SUCCESS, data);
export const listBatchBankerError = (error: GiroWebappError) => action(ListBatchBankerTypes.LIST_BATCH_BANKER_ERROR, error);
export const listBatchBankerResetState = () => action(ListBatchBankerTypes.LIST_BATCH_BANKER_RESET_STATE);
