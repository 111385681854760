import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import FormatterUtils from 'components/formatter/formatter-utils';
import { BANK_SVG_URL } from 'config/constants';
import invalidBankSvg from 'images/bank-default.svg';
import { PaymentTransactionStatus } from 'model/enums/payment-transaction-status';
import { PersonType } from 'model/enums/person-type';
import { PaymentTransactionFilterRequest, ScdPaymentTransactionData } from 'model/payment-transaction';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useListPaymentTransactionsState } from 'reducer/hooks';
import { valuesOfObject } from 'shared/util/object-utils';
import PaymentTransactionModalDetails from '../modal-details/payment-transaction-modal-details';
import PaymentTransactionMoreOptionsIconButton from '../more-options-icon-button/payment-transaction-more-options-icon-button';

interface ListPaymentTransactionsProps {
    filterActive: PaymentTransactionFilterRequest;
}

const I18N_PREFIX = 'pages.scd.payment-transaction';

const ListPaymentTransactions = (props: ListPaymentTransactionsProps) => {
    const { filterActive } = props;

    const { t } = useTranslation();

    const [selectedPaymentTransaction, setSelectedPaymentTransaction] = useState<ScdPaymentTransactionData | undefined>(undefined);
    const [openModalMoreOptions, setOpenModalMoreOptions] = useState<boolean>(false);

    const { paymentTransactions } = useListPaymentTransactionsState();

    return (
        <>
            {paymentTransactions?.content?.length !== 0 ? (
                paymentTransactions?.content?.map(paymentTransaction => {
                    const { id, fallbackDateTime, favoredName, favoredIdentification, amount, accountNumber, status, pixKey, bankName, bankIdentification, agencyNumber } = paymentTransaction;
                    return (
                        <>
                            <tr className="page-container--table-cell" key={id}>
                                <td className="page-container--table-cell-title">
                                    <div> {fallbackDateTime ? <DateFormatter date={fallbackDateTime} format={DateFormat.DATETIME_WITHOUT_SECONDS_UTC} /> : '00/00/00 00:00'} </div>
                                </td>
                                <td className="page-container--table-cell-title">
                                    <div className="scd-payment-transaction__table-fields-table-column__amount-value">
                                        <CurrencyFormatter value={amount} prefix />
                                    </div>
                                </td>
                                <td className="page-container--table-cell-title">
                                    <div className="scd-payment-transaction__table-fields">
                                        <div className="scd-payment-transaction__table-fields-table-column__favored-value">
                                            {favoredIdentification && favoredName ? `${FormatterUtils.formatIdentification(PersonType.CORPORATION, favoredIdentification)} - ${favoredName}` : '-'}
                                        </div>
                                    </div>
                                </td>
                                <td className="page-container--table-cell-title">
                                    {pixKey ? (
                                        <div className="scd-payment-transaction__table-fields-table-column__agency-value">N/A</div>
                                    ) : (
                                        <div className="scd-payment-transaction__table-fields-table-column__agency-value">
                                            {bankIdentification ? (
                                                <Tooltip title={bankName}>
                                                    <img
                                                        src={`${BANK_SVG_URL}/${bankIdentification}.svg`}
                                                        alt={bankName ?? ''}
                                                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => ((e.target as HTMLImageElement).src = invalidBankSvg)}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                    <Tooltip title={bankName ?? t('tooltips.bankInvalid')}>
                                                        <img src={invalidBankSvg} alt={bankName ?? ''} />
                                                    </Tooltip>
                                            )}
                                            {agencyNumber}
                                        </div>
                                    )}
                                </td>
                                <td className="page-container--table-cell-title">
                                    <div className="scd-payment-transaction__table-fields-table-column__account-value">{pixKey ?? accountNumber ?? '-'}</div>
                                </td>
                                <td className="page-container--table-cell-title">
                                    <div className="page-container--table-status">
                                        <span
                                            className={clsx('page-container--table-status', {
                                                CONFIRMED: status === PaymentTransactionStatus.CONFIRMED,
                                                ERROR: status === PaymentTransactionStatus.ERROR,
                                                WAITING: status === PaymentTransactionStatus.WAITING,
                                                CANCELLED: status === PaymentTransactionStatus.CANCELLED,
                                            })}
                                        >
                                            {t(`${I18N_PREFIX}.filter.input.status.options.${status}`).toUpperCase()}
                                        </span>
                                    </div>
                                </td>
                                <td className="page-container--table-cell-icon">
                                    <PaymentTransactionMoreOptionsIconButton
                                        onClick={() => setSelectedPaymentTransaction(paymentTransaction)}
                                        selectedPaymentTransaction={selectedPaymentTransaction}
                                        setOpenModalMoreOptions={setOpenModalMoreOptions}
                                    />
                                </td>
                            </tr>
                            {openModalMoreOptions && <PaymentTransactionModalDetails selectedPaymentTransaction={selectedPaymentTransaction} onClose={() => setOpenModalMoreOptions(false)} />}
                        </>
                    );
                })
            ) : (
                <div className="scd-payment-transaction__table-empty-filter">
                    <span className="scd-payment-transaction__table-empty-filter-image" />
                    <span className="scd-payment-transaction__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                    <span className="scd-payment-transaction__table-empty-filter-subtitle">{valuesOfObject({ ...filterActive }).length !== 0 && t(`${I18N_PREFIX}.invalid-filter`)}</span>
                </div>
            )}
        </>
    );
};

export default ListPaymentTransactions;
