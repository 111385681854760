import { isValidCPF as validateCPF, isValidCNPJ as validateCNPJ } from '@brazilian-utils/brazilian-utils';

const isValidCPF = (value: string): boolean => {
    return validateCPF(value);
};

const isValidCNPJ = (value: string): boolean => {
    return validateCNPJ(value);
};

const IdentificationUtils = {
    isValidCPF,
    isValidCNPJ,
};
export default IdentificationUtils;
