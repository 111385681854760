import { Grid } from '@material-ui/core';
import './base-operation-card.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useState } from 'react';

interface BaseOperationCardProps {
    children: React.ReactNode;
    title: string;
    isOpenable?: boolean;
}

const BaseOperationCard = (props: BaseOperationCardProps) => {
    const { children, title, isOpenable = false } = props;

    const [openCard, setOpenCard] = useState<boolean>(false);

    return (
        <div className="scd-base-operation-card">
            <h3 className="scd-base-operation-card--title">
                {title}
                {isOpenable && <span className="scd-base-operation-card--title__icon" onClick={() => setOpenCard(!openCard)}> {!openCard ? <ExpandMoreIcon /> : <ExpandLessIcon />} </span>}
            </h3>
            {(!isOpenable || openCard) && <Grid container>{children}</Grid>}
        </div>
    );
};

export default BaseOperationCard;
