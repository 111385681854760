import { AxiosResponse } from 'axios';
import { Authentication, Token } from 'model/authentication';
import { api } from './api';

export const authenticate = () => {
    const login = (authentication: Authentication): Promise<AxiosResponse<Token>> => {
        return api.post<Token>('api/authenticate', authentication);
    };

    return {
        login,
    };
};

export default authenticate();
