import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ScdPaymentTransactionData } from 'model/payment-transaction';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.payment-transaction.table';

export type PaymentTransactionDataSortableProperties = keyof Pick<ScdPaymentTransactionData, 'date' | 'amount' | 'status'> | 'favored_identification' | 'created_date';

interface ScdPaymentTransactionProps {
    paymentTransactions: ScdPaymentTransactionData[];
    handleSort: (property: PaymentTransactionDataSortableProperties, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
}

export const ScdPaymentTransactionHeader = (props: ScdPaymentTransactionProps) => {
    const { t } = useTranslation();
    const { paymentTransactions, handleSort, sortedProperty, sortOrder } = props;

    const mapSortable = (_property: PaymentTransactionDataSortableProperties) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: paymentTransactions && paymentTransactions.length <= 1 ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.date`).toUpperCase()} sortable={mapSortable('created_date')} />
            <SortableColumn label={t(`${I18N_PREFIX}.amount`).toUpperCase()} sortable={mapSortable('amount')} />
            <SortableColumn label={t(`${I18N_PREFIX}.favored`).toUpperCase()} sortable={mapSortable('favored_identification')} />
            <SortableColumn label={t(`${I18N_PREFIX}.agency`).toUpperCase()} />
            <SortableColumn label={t(`${I18N_PREFIX}.account`).toUpperCase()} />
            <SortableColumn label={t(`${I18N_PREFIX}.status`).toUpperCase()} sortable={mapSortable('status')} />
        </tr>
    );
};

export default ScdPaymentTransactionHeader;
