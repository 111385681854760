import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useListPaymentAccountsState, useRootDispatch } from 'reducer/hooks';
import { listPaymentAccountsRequest, listPaymentAccountsResetState } from 'reducer/payment-accounts/list-payment-accounts/actions';
import './payment-accounts-balance.scss';

import AccountBalance from './components/account-balance/account-balance';
import { PaymentAccountsToGetAll } from 'model/payment-accounts';
import SimpleSelectInput from 'components/inputs/select-input/simple-select-input';
import { MenuItem } from '@material-ui/core';
import BackButton from 'components/buttons/back-button/back-button';

const I18N_PREFIX = 'pages.scd.payment-accounts';

const PaymentAccountBalance = () => {
    const [selectedAccount, setSelectedAccount] = useState<PaymentAccountsToGetAll | undefined>(undefined);

    const { t } = useTranslation();
    const { accounts, status: listAccountsStatus } = useListPaymentAccountsState();

    const dispatch = useRootDispatch();

    const isLoadingAccount: boolean = listAccountsStatus === HttpRequestStatus.ON_GOING;
    const hasError = listAccountsStatus === HttpRequestStatus.ERROR;

    const accountCount: number = accounts?.accounts?.length ?? 0;

    const handleListAccounts = useCallback(() => {
        dispatch(listPaymentAccountsRequest());
    }, [dispatch]);

    useEffect(() => {
        handleListAccounts();
    }, [handleListAccounts]);

    useEffect(() => {
        if (!accounts?.accounts) return;
        if (!accounts?.accounts?.length) return;

        if (accounts.accounts.length === 1) {
            setSelectedAccount(accounts.accounts[0]);
        }
    }, [accounts]);

    useEffect(() => {
        return () => {
            dispatch(listPaymentAccountsResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(listPaymentAccountsResetState());
        };
    }, [dispatch]);

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t(`${I18N_PREFIX}.error-message`)}</div>
                <BackButton label={t('global.try-again')} onClick={handleListAccounts} />
            </div>
        );
    }

    return (
        <main className="scd-payment-accounts-balance">
            {isLoadingAccount ? (
                <Loading />
            ) : (
                <div className="scd-payment-accounts-balance__container">
                    {accountCount === 0 ? (
                        <header className="scd-payment-accounts-balance__header">
                            <h2 className="scd-payment-accounts-balance__header--title-error">{t(`${I18N_PREFIX}.balance.zero-account`)}</h2>
                        </header>
                    ) : (
                        <>
                            <header className="scd-payment-accounts-balance__header">
                                <h2 className="scd-payment-accounts-balance__header--title">{t(`${I18N_PREFIX}.balance.title`)}</h2>
                            </header>
                            <div className="scd-payment-accounts-balance--container">
                                <SimpleSelectInput
                                    label={`${I18N_PREFIX}.inputs.select.label`}
                                    placeholder={`${I18N_PREFIX}.inputs.select.placeholder`}
                                    value={selectedAccount?.name ?? ''}
                                    externalUpdate
                                    mapperFromString={_ => selectedAccount?.name ?? ''}
                                >
                                    {accounts?.accounts?.map(it => (
                                        <MenuItem key={it?.id} onClick={() => setSelectedAccount(it)} value={it.name}>
                                            {it?.name}
                                        </MenuItem>
                                    ))}
                                </SimpleSelectInput>
                                {!!selectedAccount && (
                                    <>
                                        <AccountBalance accountId={selectedAccount?.id} />
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
        </main>
    );
};

export default PaymentAccountBalance;
