import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import clsx from 'clsx';
import React from 'react';
import BaseMaskInput, { BaseMaskInputProps } from 'components/inputs/base-mask-input';
import InputUtils from 'components/inputs/input-utils';
import ValidInput, { ValidInputProps } from 'components/inputs/validation/valid-input';
import './text-number-input.scss';
import TextNumberMask from 'components/inputs/text-number-input/text-number-mask';

type BaseInput = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'onChange' | 'value' | 'type'>;
type BaseValidation = Omit<ValidInputProps<string>, 'children'>;
type Base = BaseInput & BaseValidation;

export interface ValidNumberInputProps extends Base {
    onChange: (value: string) => void;
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
}

export const ValidTextNumberInput = (props: ValidNumberInputProps) => {
    const { validate, showValidation, value: propsValue, onChange, MaskInputComponent, ...otherProps } = props;

    const handleOnChange = (_value: string) => {
        const normalized = InputUtils.stringToInteger(_value);
        if (normalized !== undefined && !isNaN(normalized)) {
            onChange(_value);
        }
    };

    return (
        <ValidInput value={propsValue} validate={validate} showValidation={showValidation} className={clsx('base-input number__input', { dirty: propsValue })}>
            {({ error }) => <BaseMaskInput {...otherProps} error={error} value={propsValue ? propsValue.toString() : ''} onChange={handleOnChange} MaskInputComponent={MaskInputComponent ?? TextNumberMask} />}
        </ValidInput>
    );
};

export default ValidTextNumberInput;
