import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { PersonType } from 'model/enums/person-type';
import { Trans, useTranslation } from 'react-i18next';
import { useListImportedOperationsByPaymentTransactionState } from 'reducer/hooks';
import { getPersonType } from 'shared/util/person-type-utils';

const I18N_PREFIX = 'pages.scd.payment-transaction.imported-operations.table';

const ListImportedOperationsTable = () => {
    const { t } = useTranslation();

    const { importedOperations } = useListImportedOperationsByPaymentTransactionState();

    const getFavoredTranslation = (identification: string, personName: string, companyName: string): React.ReactNode => {
        if (identification === undefined || identification === null) return '-';

        if (getPersonType(identification) === PersonType.INDIVIDUAL) {
            return <Trans i18nKey={`${I18N_PREFIX}.favored.fullFavored`} t={t} values={{ name: personName }} components={{ identification: <IdentificationFormatter value={identification} type={PersonType.INDIVIDUAL} /> }} />;
        }

        return <Trans i18nKey={`${I18N_PREFIX}.favored.fullFavored`} t={t} values={{ name: companyName }} components={{ identification: <IdentificationFormatter value={identification} type={PersonType.CORPORATION} /> }} />;
    };

    return (
        <>
            {importedOperations?.content?.map(({ id, ccbNumber, hasBatch, batchCode, acceptedDate, product, identification, personName, companyName, totalValue, disbursementValue }) => (
                <tr className="page-container--table-cell" key={id}>
                    <td className="page-container--table-cell-title">{ccbNumber ?? '-'}</td>
                    <td className="page-container--table-cell-title">{hasBatch ? batchCode : '-'} </td>
                    <td className="page-container--table-cell-title">{acceptedDate ? <DateFormatter date={acceptedDate} /> : '00/00/00'}</td>
                    <td className="page-container--table-cell-title">
                        <span> {t(`${I18N_PREFIX}.product.options.${product}`)} </span>
                    </td>
                    <td className="page-container--table-cell-title">
                        <span>{getFavoredTranslation(identification, personName, companyName)} </span>{' '}
                    </td>
                    <td className="page-container--table-cell-title">{<CurrencyFormatter value={totalValue ?? 0} prefix />}</td>
                    <td className="page-container--table-cell-title">{<CurrencyFormatter value={disbursementValue ?? 0} prefix />}</td>
                </tr>
            ))}
        </>
    );
};

export default ListImportedOperationsTable;
