import H from 'history';

import { ComponentType, useEffect } from 'react';
import { Route, RouteComponentProps, RouteProps, useHistory } from 'react-router-dom';

import Loading from 'components/loading/loading';
import RouteRolePermissionValidationComponent from 'components/route-role-permission-validation/route-role-permission-validation';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { userAccountRequest } from 'reducer/account/user-account/actions';
import { useAuthenticationState, useRootDispatch, useUserAccountState } from 'reducer/hooks';
import AuthUtil from 'services/api/authUtil';
import Footer, { FooterType } from 'shared/footer/footer';
import Header, { HeaderType } from 'shared/header/header';
import { usePrivateRouteRedirect } from 'shared/hooks/use-private-route-redirect';

interface IPrivateRouteProps extends Omit<RouteProps, 'component'> {
    component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
    headerType?: HeaderType;
    footerType?: FooterType;
    rolesAllowed?: string[];
}

export const PrivateRoute = (props: IPrivateRouteProps) => {
    const { component: Component, footerType, rolesAllowed, ...rest } = props;

    const { account, status: accountStatus } = useUserAccountState();
    const authenticationState = useAuthenticationState();

    const dispatch = useRootDispatch();
    const history: H.History = useHistory();
    const hasToken = AuthUtil.isAuthenticated();

    usePrivateRouteRedirect();

    useEffect(() => {
        if (!hasToken) {
            history.push('/');
        }
    }, [history, authenticationState, hasToken, dispatch]);

    useEffect(() => {
        if (accountStatus === HttpRequestStatus.NOOP && hasToken) {
            dispatch(userAccountRequest());
        }
    }, [hasToken, dispatch, accountStatus]);

    if (accountStatus !== HttpRequestStatus.SUCCESS) {
        return (
            <div className="loading--private-route">
                <Loading />
            </div>
        );
    }

    const header = props.headerType ?? HeaderType.BANKER;

    return (
        <div className="page__container">
            <RouteRolePermissionValidationComponent rolesAllowed={rolesAllowed}>
                <Route
                    {...rest}
                    render={routeProps => (
                        <>
                            <Header type={header} />
                            {account?.activated && (
                                <div className="page__content">
                                    <Component {...routeProps} />
                                </div>
                            )}
                            <Footer type={footerType ?? FooterType.DEFAULT} />
                        </>
                    )}
                />
            </RouteRolePermissionValidationComponent>
        </div>
    );
};

export default PrivateRoute;
