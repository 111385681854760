import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ImportedOperation } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.ccb-operation.table';

interface ScdOperationHeaderProps {
    operations: ImportedOperation[];
    handleSort: (property: ImportedOperationsDataSortableProperties, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort?: boolean;
}

export type ImportedOperationsDataSortableProperties =
    | keyof ImportedOperation
    | 'personName' | 'status' | 'product'

export const ScdOperationHeader = (props: ScdOperationHeaderProps) => {
    const { t } = useTranslation();
    const {
        operations,
        handleSort,
        sortedProperty,
        sortOrder,
        disabledSort,
    } = props;


    const mapSortable = (_property: ImportedOperationsDataSortableProperties) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (operations && operations.length <= 1) ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.acceptedDate`).toUpperCase()} sortable={mapSortable('acceptedDate')} />
            <SortableColumn label={t(`${I18N_PREFIX}.ccbNumber`).toUpperCase()} sortable={mapSortable('ccbNumber')} />
            <SortableColumn label={t(`${I18N_PREFIX}.batchCode`).toUpperCase()} sortable={mapSortable('batchCode')} />
            <SortableColumn label={t(`${I18N_PREFIX}.product`).toUpperCase()} sortable={mapSortable('product')} />
            <SortableColumn label={t(`${I18N_PREFIX}.debtor`).toUpperCase()} sortable={mapSortable('personName')} />
            <SortableColumn label={t(`${I18N_PREFIX}.status`).toUpperCase()} sortable={mapSortable('status')} />
        </tr>
    );
};

export default ScdOperationHeader;
