import * as H from 'history';
import { ErrorConstants, ErrorType } from 'model/enums/error-constants';

export const UnknownError = {
    message: 'error.unknown',
};

export type UnknownErrorType = typeof UnknownError;

export const UnauthorizedError = {
    httpStatus: 401,
    message: 'error.http.unauthorized',
};

export const ForbiddenError = {
    httpStatus: 403,
    message: 'error.http.forbidden',
};

export const BadRequestError = {
    httpStatus: 400,
    message: 'error.http.badRequest',
};

export type HttpError = typeof UnauthorizedError | typeof ForbiddenError;

export interface GiroScdBankerError {
    message?: string;
    httpStatus?: number;
    code?: number;
    fieldErrors?: string[];
    detail?: string;
    error_description?: string;
}

export type GiroWebappError = GiroScdBankerError | HttpError | UnknownErrorType;

export interface ErrorMessage {
    key: string;
    options?: { [key: string]: string };
}

export interface ErrorAction {
    label?: string;
    handler?: (history: H.History, dispatch?: any) => void;
    noAction?: boolean;
}

export interface ErrorHandlingCustomization {
    type?: ErrorType;
    message?: ErrorMessage;
    action?: ErrorAction;
}

export interface ErrorCustominization {
    defaultType?: ErrorType;
    record?: Partial<Record<ErrorConstants, ErrorHandlingCustomization>>;
}
