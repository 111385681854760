import clsx from 'clsx';
import DateFormatter from 'components/formatter/date/date-formatter';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { ImportedOperationBankerStatus } from 'model/enums/imported-operation-status';
import { ImportedOperation, ImportedOperationFilterRequest } from 'model/imported-operation';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useListImportedOperationsState } from 'reducer/hooks';
import { valuesOfObject } from 'shared/util/object-utils';
import { getPersonType } from 'shared/util/person-type-utils';
import ImportedOperationMoreOptionsIconButton from '../more-options-icon-button/imported-operation-more-options-icon-button';

const I18N_PREFIX = 'pages.scd.ccb-operation';

interface OperationsListProps {
    filterActive: ImportedOperationFilterRequest;
}

const OperationsList = (props: OperationsListProps) => {
    const { filterActive } = props;
    const { t } = useTranslation();

    const [selectedImportedOperation, setSelectedImportedOperation] = useState<ImportedOperation | undefined>(undefined);

    const { operations } = useListImportedOperationsState();

    const showInvalidFilterMessage = valuesOfObject({ ...filterActive, status: filterActive?.status?.length ? filterActive.status : undefined }).some(it => it !== undefined);

    return (
        <>
            {operations?.content?.length !== 0 ? (
                operations?.content?.map(operation => {
                    const { id, ccbNumber, acceptedDate, productType, debtorIdentification, debtorName, batchCode, status, hasBatch } = operation;

                    return (
                        <tr className="page-container--table-cell" key={id}>
                            <td className="page-container--table-cell-title">{acceptedDate ? <DateFormatter date={acceptedDate} /> : '00/00/00'}</td>
                            <td className="page-container--table-cell-title" title={ccbNumber ?? ''}>
                                {ccbNumber}
                            </td>
                            <td className="page-container--table-cell-title" title={batchCode ?? ''}>
                                {hasBatch ? batchCode : <span className="batchless"> {t(`${I18N_PREFIX}.batchless`).toUpperCase()} </span>}
                            </td>
                            <td className="page-container--table-cell-title">
                                <span> {t(`${I18N_PREFIX}.product.options.${productType}`).toUpperCase()}</span>
                            </td>
                            <td className="page-container--table-cell-title" title={debtorIdentification ?? debtorName ?? ''}>
                                <span>
                                    {' '}
                                    <Trans
                                        i18nKey={`${I18N_PREFIX}.debtorInfo`}
                                        t={t}
                                        values={{ debtorName: debtorName, debtorIdentification: debtorIdentification }}
                                        components={{ identificationComponent: <IdentificationFormatter value={debtorIdentification} type={getPersonType(debtorIdentification)} /> }}
                                    />
                                </span>
                            </td>
                            <td className="page-container--table-cell-title">
                                <span
                                    className={clsx('page-container--table-status', {
                                        ERROR: status === ImportedOperationBankerStatus.ERROR,
                                        PROCESSED: status === ImportedOperationBankerStatus.PROCESSED,
                                        RECEIVED: status === ImportedOperationBankerStatus.RECEIVED,
                                        ARCHIVED: status === ImportedOperationBankerStatus.ARCHIVED,
                                        DISAPPROVED: status === ImportedOperationBankerStatus.DISAPPROVED,
                                    })}
                                >
                                    {t(`${I18N_PREFIX}.status.options.${status}`).toUpperCase()}
                                </span>
                            </td>
                            <td className="page-container--table-cell-title">
                                <ImportedOperationMoreOptionsIconButton selectedImportedOperation={selectedImportedOperation} onClick={() => setSelectedImportedOperation(operation)} />
                            </td>
                        </tr>
                    );
                })
            ) : (
                <div className="scd-ccb-operation__table-empty-filter">
                    <span className="scd-ccb-operation__table-empty-filter-image" />
                    <span className="scd-ccb-operation__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                    <span className="scd-ccb-operation__table-empty-filter-subtitle">{showInvalidFilterMessage && t(`${I18N_PREFIX}.invalid-filter`)}</span>
                </div>
            )}
        </>
    );
};

export default OperationsList;
