import StandardButton from 'components/buttons/standard-button/standard-button';
import FieldPassword from 'components/inputs/login/field-password/field-password';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { resetPasswordRequest } from 'reducer/account/password-reset/actions';
import { useRootDispatch, useUserPasswordResetState } from 'reducer/hooks';
import { validateUserPassword, validateUserPasswordMatch, validatePasswordCreate } from './validation-constants';
import { RegisterSuccess } from './components/register-success/register-success';

import './register-finish.scss';

interface RegisterFinishParams {
    key: string;
}

const RegisterFinishPage = () => {
    const [password, setPassword] = useState<string>('');
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');
    const { key } = useParams<RegisterFinishParams>();
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const history = useHistory();

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { status: passwordResetStatus } = useUserPasswordResetState();

    const validateMatch = () => {
        return validateUserPasswordMatch({ password, confirm: passwordConfirm });
    };

    const handleValidSubmit = () => {
        setShowValidation(true);

        if (!validatePasswordCreate({ password, confirm: passwordConfirm })) return;

        dispatch(resetPasswordRequest({ key, password, newPassword: passwordConfirm }));
    };

    const handleClose = () => {
        history.push('/');
    };

    useEffect(() => {
        if (passwordResetStatus === HttpRequestStatus.SUCCESS) {
            setShowModal(true);
        }
    }, [passwordResetStatus]);

    const isLoading = passwordResetStatus === HttpRequestStatus.ON_GOING;

    const error = passwordResetStatus === HttpRequestStatus.ERROR;

    return (
        <main className="register__finish">
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    {' '}
                    <article className="register__finish--container">
                        <header className="register__finish--header">
                            <div className="register__finish--field">
                                <p className="register__finish--header--title">{t('pages.register-finish.title')}</p>
                            </div>
                        </header>
                        {!error ? (
                            <div className="register__finish--content">
                                <div className="register__finish--field">
                                    <FieldPassword label={t('pages.register-finish.password.label')} value={password} onChange={setPassword} validate={validateUserPassword} autoComplete="off" showValidation={showValidation} />
                                </div>
                                <div className="register__finish--field">
                                    <FieldPassword
                                        value={passwordConfirm}
                                        label={t('pages.register-finish.confirm-password.label')}
                                        onChange={setPasswordConfirm}
                                        validate={validateMatch}
                                        showValidation={showValidation}
                                        autoComplete="off"
                                    />
                                </div>
                                <div className="register__finish--button">
                                    <StandardButton label="pages.register-finish.finish" onClick={handleValidSubmit} />
                                </div>
                            </div>
                        ) : (
                            <p>{t('pages.register-finish.messages.key-missing')}</p>
                        )}
                    </article>
                    <RegisterSuccess open={showModal} onClose={handleClose} />{' '}
                </>
            )}
        </main>
    );
};

export default RegisterFinishPage;
