import { AxiosResponse } from 'axios';
import { ScdPaymentTransactionData } from 'model/payment-transaction';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import paymentTransactionAPI from 'services/api/paymentTransactionApi';
import { ErrorUtils } from 'shared/error/error-utils';
import { getPaymentTransactionHeaderOnImportedOperationError, getPaymentTransactionHeaderOnImportedOperationRequest, getPaymentTransactionHeaderOnImportedOperationSuccess } from './actions';
import { GetPaymentTransactionOnImportedOperationTypes } from './types';

function* handleGetPaymentTransactionInfo(action: ReturnType<typeof getPaymentTransactionHeaderOnImportedOperationRequest>) {
    try {
        const result: AxiosResponse<ScdPaymentTransactionData> = yield call(paymentTransactionAPI.getPaymentTransactionHeaderOnImportedOperation, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapScdBankerError(result);
            yield put(markError(mapped));
            yield put(getPaymentTransactionHeaderOnImportedOperationError(mapped));
            return;
        }
        yield put(getPaymentTransactionHeaderOnImportedOperationSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(getPaymentTransactionHeaderOnImportedOperationError(mapped));
    }
}

function* watchGetPaymentTransactionInfo() {
    yield takeEvery(GetPaymentTransactionOnImportedOperationTypes.GET_PAYMENT_TRANSACTION_ON_IMPORTED_OPERATION_REQUEST, handleGetPaymentTransactionInfo);
}

function* getPaymentTransactionInfoSaga() {
    yield all([fork(watchGetPaymentTransactionInfo)]);
}

export default getPaymentTransactionInfoSaga;
