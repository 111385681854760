import { OperationApprovalSimple } from 'model/operation-approvals';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum DisapproveOperationApprovalTypes {
    DISAPPROVE_OPERATION_APPROVAL_REQUEST = '@@disapprove-operation-approval/DISAPPROVE_OPERATION_APPROVAL_REQUEST',
    DISAPPROVE_OPERATION_APPROVAL_SUCCESS = '@@disapprove-operation-approval/DISAPPROVE_OPERATION_APPROVAL_SUCCESS',
    DISAPPROVE_OPERATION_APPROVAL_ERROR = '@@disapprove-operation-approval/DISAPPROVE_OPERATION_APPROVAL_ERROR',
    DISAPPROVE_OPERATION_APPROVAL_RESET_STATE = '@@disapprove-operation-approval/DISAPPROVE_OPERATION_APPROVAL_RESET_STATE',
}

export interface DisapproveOperationApprovalState extends BaseStoreState {
    readonly disapprovedOperation?: OperationApprovalSimple;
}
