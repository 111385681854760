import { Grid } from '@material-ui/core';
import ZipCodeFormatter from 'components/formatter/zip-code/zip-code-formatter';
import BaseOperationCard from 'features/ccb-operation/ccb-operation-detail/components/cards/base-operation-card/base-operation-card';
import OperationReadOnlyTextField from 'features/ccb-operation/ccb-operation-detail/components/operation-read-only-text-field/operation-read-only-text-field';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';

interface AddressOperationCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.address';

const AddressOperationCard = (props: AddressOperationCardProps) => {
    const {
        operation: { resumeDetailOperation },
    } = props;

    const { t } = useTranslation();

    return (
        <BaseOperationCard title={t(`${I18N_PREFIX}.title`)} isOpenable>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.street`)} value={resumeDetailOperation?.street} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.number`)} value={resumeDetailOperation?.addressNumber} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.complement`)} value={resumeDetailOperation?.addressComplement} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.neighborhood`)} value={resumeDetailOperation?.neighborhood} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.city`)} value={resumeDetailOperation?.city} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.state`)} value={resumeDetailOperation?.state} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.zipcode`)}
                    value={resumeDetailOperation?.zipcode ? <ZipCodeFormatter value={resumeDetailOperation?.zipcode} /> : '-'}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.residenceType.label`)}
                    value={t(`${I18N_PREFIX}.fields.residenceType.options.${resumeDetailOperation?.residenceType}`)}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.timeResidence`)} value={resumeDetailOperation?.timeResidence} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.rentAmount`)} value={resumeDetailOperation?.rentAmount ?? 'N/A'} />
            </Grid>
        </BaseOperationCard>
    );
};

export default AddressOperationCard;
