import { Pageable } from 'services/pageable';
import { OperationApprovalStatus } from './enums/operation-approval-status';

export interface OperationApproval {
    id: number;
    ccbNumber: string;
    debtorIdentification: string;
    status: OperationApprovalStatus;
    requestDate: Date;
    approverName: string;
    approvalDate: Date;
    companyName: string;
    personName: string;
    importedOperationId: number;
}

export interface OperationApprovalFilterRequest {
    status?: OperationApprovalStatus[];
    ccbNumber?: string;
}

export interface OperationApprovalFilterPageable {
    pageable?: Pageable;
    filter?: OperationApprovalFilterRequest;
}

export const defaultOperationApprovalFilter: Readonly<OperationApprovalFilterRequest> = {
    status: [OperationApprovalStatus.PENDING],
};

export interface OperationApprovalSimple {
    id: number;
}

export interface DisapproveOperationApprovalRequest {
    id?: number;
    disapprovalReason?: string;
}

export interface OperationApprovalToApproveMultiples {
    operationApprovalsIds: number[];
}

interface ResponseErrors {
    id: number;
    message: string;
}

export interface OperationApprovalErrorResponse {
    errors: ResponseErrors[];
}
