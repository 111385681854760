import { Checkbox } from '@material-ui/core';
import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { OperationApproval } from 'model/operation-approvals';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.ccb-operation-approval.table';

export type OperationApprovalsDataSortable = keyof OperationApproval | 'createdDate' | 'importedOperation.ccbNumber' | 'importedOperation.identification' | 'banker.user.firstName';

interface ScdOperationApprovalsProps {
    operationApprovals: OperationApproval[];
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort?: boolean;
    handleSort: (property: OperationApprovalsDataSortable, order: SortOrder) => void;
    isPending: boolean | undefined;
    showCheckbox: boolean;
    handleSelectAllCheckbox: () => void;
    isSelectedAllCheckbox: boolean;
}

const ScdOperationApprovalHeader = (props: ScdOperationApprovalsProps) => {
    const { t } = useTranslation();

    const { operationApprovals, sortedProperty, sortOrder, handleSort, disabledSort, isPending, showCheckbox, handleSelectAllCheckbox, isSelectedAllCheckbox } = props;

    const mapSortable = (_property: OperationApprovalsDataSortable) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (operationApprovals && operationApprovals.length <= 1) ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.ccbNumber`)} sortable={mapSortable('importedOperation.ccbNumber')} />
            <SortableColumn label={t(`${I18N_PREFIX}.requestDate`)} sortable={mapSortable('requestDate')} />
            <SortableColumn label={t(`${I18N_PREFIX}.debtorIdentification`)} sortable={mapSortable('importedOperation.identification')} />
            {!isPending && (
                <>
                    <SortableColumn label={t(`${I18N_PREFIX}.approvalDate`)} sortable={mapSortable('approvalDate')} />
                    <SortableColumn label={t(`${I18N_PREFIX}.approverName`)} sortable={mapSortable('banker.user.firstName')} />
                    <SortableColumn label={t(`${I18N_PREFIX}.status`)} sortable={mapSortable('status')} />
                </>
            )}
            {showCheckbox && <Checkbox color="primary" onChange={handleSelectAllCheckbox} checked={isSelectedAllCheckbox} disabled={!operationApprovals.length} />}
        </tr>
    );
};

export default ScdOperationApprovalHeader;
