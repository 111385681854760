import { Authority } from 'model/enums/authorities';
import { GiroWebappErrorConstants } from 'model/enums/error-constants';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useState } from 'react';
import { markError } from 'reducer/application/error/actions';
import { useAuthenticationState, useRootDispatch, useUserAccountState } from 'reducer/hooks';
import AuthUtil from 'services/api/authUtil';

export const useAuthenticationAwareness = (): boolean => {
    const dispatch = useRootDispatch();

    const { status: authStatus } = useAuthenticationState();
    const { status: accountStatus } = useUserAccountState();
    const authLoading = authStatus === HttpRequestStatus.ON_GOING;
    const accountLoading = accountStatus === HttpRequestStatus.ON_GOING;

    const authorizationCheck = AuthUtil.isAuthenticated();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(authorizationCheck);
    const [isBanker, setIsBanker] = useState<boolean>();

    const handleAuthorizationFailure = useCallback(() => {
        AuthUtil.removeToken();
        dispatch(markError({ message: GiroWebappErrorConstants.HTTP_UNAUTHORIZED }));
    }, [dispatch]);

    const updateAuthenticationState = useCallback(() => {
        if (isAuthenticated !== authorizationCheck) {
            setIsAuthenticated(authorizationCheck);
        }
    }, [authorizationCheck, isAuthenticated]);

    useEffect(() => {
        if (authLoading || accountLoading) return;
        const roles = AuthUtil.decode();
        const isBanker = roles.authorities?.includes(Authority.ROLE_BANKER);
        setIsBanker(isBanker);
    }, [authLoading, accountLoading, authorizationCheck]);

    useEffect(() => {
        if (isBanker || isBanker === undefined) return;
        if (!authorizationCheck) return;
        handleAuthorizationFailure();
    }, [handleAuthorizationFailure, isBanker, authorizationCheck]);

    useEffect(() => {
        if (!authorizationCheck) return;
        if (!isBanker) return;
        updateAuthenticationState();
    }, [updateAuthenticationState, isBanker, authorizationCheck]);

    const isBankerAndAuthenticated = isAuthenticated && (isBanker ?? false);

    return isBankerAndAuthenticated;
};
