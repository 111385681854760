import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect } from 'react';
import { useBalancePaymentAccountState, useRootDispatch } from 'reducer/hooks';
import { balancePaymentAccountRequest, balancePaymentAccountResetState } from 'reducer/payment-accounts/balance-payment-accounts/actions';
import _ from 'lodash';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import Loading from 'components/loading/loading';
import RefreshIcon from '@material-ui/icons/Refresh';
import './account-balance.scss';

const I18N_PREFIX = 'pages.scd.payment-accounts';

interface AccountBalanceProps {
    accountId?: number;
}

export const AccountBalance = ({ accountId }: AccountBalanceProps) => {
    const dispatch = useRootDispatch();

    const { balance, status: balanceStatus } = useBalancePaymentAccountState();

    const isLoadingBalance: boolean = balanceStatus === HttpRequestStatus.ON_GOING;

    const handleBalance = useCallback(() => {
        if (!accountId) return;
        dispatch(balancePaymentAccountRequest(accountId));
    }, [dispatch, accountId]);

    useEffect(() => {
        handleBalance();
    }, [handleBalance]);

    useEffect(() => {
        if (!accountId) return;
        dispatch(balancePaymentAccountRequest(accountId));
    }, [dispatch, accountId]);

    useEffect(() => {
        return () => {
            dispatch(balancePaymentAccountResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (balanceStatus === HttpRequestStatus.ERROR){
            dispatch(balancePaymentAccountResetState())
        }
    }, [dispatch, balanceStatus]);

    if (isLoadingBalance) return <Loading />;
    
    return (
        <div className="scd-account-balance__container">
            <ReadOnlyTextField label={`${I18N_PREFIX}.inputs.balance.label`} value={!_.isNil(balance?.amount) ? <CurrencyFormatter value={balance?.amount} prefix /> : '-'} />
            <RefreshIcon className="scd-ccb-operation__header-container--refresh-icon" onClick={handleBalance} />
        </div>
    );
};

export default AccountBalance;
