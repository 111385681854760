import { PaymentAccountBalanceResponse } from 'model/payment-accounts';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum BalancePaymentAccountTypes {
    BALANCE_PAYMENT_ACCOUNT_REQUEST = '@@payment-account-balance/BALANCE_PAYMENT_ACCOUNT_REQUEST',
    BALANCE_PAYMENT_ACCOUNT_SUCCESS = '@@payment-account-balance/BALANCE_PAYMENT_ACCOUNT_SUCCESS',
    BALANCE_PAYMENT_ACCOUNT_ERROR = '@@payment-account-balance/LIST_PAYMENT_TRANSATIONS_ERROR',
    BALANCE_PAYMENT_ACCOUNT_RESET = '@@payment-account-balance/BALANCE_PAYMENT_ACCOUNT_RESET',
}

export interface BalancePaymentAccountState extends BaseStoreState {
    readonly balance?: PaymentAccountBalanceResponse;
}
