import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import PercentageFormatter from 'components/formatter/percentage/percentage-formatter';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';
import './tax-operation-card.scss';

interface TaxOperationCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.tax';

const TaxOperationCard = (props: TaxOperationCardProps) => {
    const {
        operation: { resumeDetailOperation },
    } = props;

    const { t } = useTranslation();

    return (
        <div className="scd-tax-operation-card">
            <h3 className="scd-tax-operation-card--title"> {t(`${I18N_PREFIX}.title`)} </h3>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.fareAmount`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    <CurrencyFormatter value={resumeDetailOperation?.fareAmount} prefix />
                </span>
            </div>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.fee`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    <PercentageFormatter value={resumeDetailOperation?.fee} />
                </span>
            </div>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.tac`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    <CurrencyFormatter value={resumeDetailOperation?.tac} prefix />
                </span>
            </div>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.iof`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    <CurrencyFormatter value={resumeDetailOperation?.iofValue} prefix />
                </span>
            </div>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.iofPercentage`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    <PercentageFormatter value={resumeDetailOperation?.iofPercentage} />
                </span>
            </div>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.additionalIofPercentage`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    <PercentageFormatter value={resumeDetailOperation?.additionalIofPercentage} />
                </span>
            </div>
        </div>
    );
};

export default TaxOperationCard;
