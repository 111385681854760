import { GiroWebappError } from 'model/error';
import { OperationApproval, OperationApprovalFilterPageable } from 'model/operation-approvals';
import { ListOperationApprovalTypes } from './types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listOperationApprovalsRequest = (request: OperationApprovalFilterPageable) => action(ListOperationApprovalTypes.LIST_OPERATION_APPROVALS_REQUEST, request);
export const listOperationApprovalsSuccess = (data: Page<OperationApproval>) => action(ListOperationApprovalTypes.LIST_OPERATION_APPROVALS_SUCCESS, data);
export const listOperationApprovalsError = (error: GiroWebappError) => action(ListOperationApprovalTypes.LIST_OPERATION_APPROVALS_ERROR, error);
export const listOperationApprovalsResetState = () => action(ListOperationApprovalTypes.LIST_OPERATION_APPROVALS_RESET_STATE);
