import { ScdImportedOperationDetail } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum DetailImportedOperationTypes {
    DETAIL_IMPORTED_OPERATION_REQUEST = '@@imported-operation-detail/DETAIL_IMPORTED_OPERATION_REQUEST',
    DETAIL_IMPORTED_OPERATION_SUCCESS = '@@imported-operation-detail/DETAIL_IMPORTED_OPERATION_SUCCESS',
    DETAIL_IMPORTED_OPERATION_ERROR = '@@imported-operation-detail/DETAIL_IMPORTED_OPERATION_ERROR',
    DETAIL_IMPORTED_OPERATION_RESET_STATE = '@@imported-operation-detail/DETAIL_IMPORTED_OPERATION_RESET_STATE',
}

export interface DetailImportedOperationState extends BaseStoreState {
    readonly detailImportedOperation?: ScdImportedOperationDetail;
}
