import { HttpRequestStatus } from 'model/enums/http-request-status';
import { DisapproveOperationApprovalState, DisapproveOperationApprovalTypes } from './types';
import { Reducer } from 'redux';

export const initialState: DisapproveOperationApprovalState = {
    status: HttpRequestStatus.NOOP,
    disapprovedOperation: undefined,
    error: undefined,
};

const reducer: Reducer<DisapproveOperationApprovalState> = (state = initialState, action): DisapproveOperationApprovalState => {
    switch (action.type) {
        case DisapproveOperationApprovalTypes.DISAPPROVE_OPERATION_APPROVAL_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case DisapproveOperationApprovalTypes.DISAPPROVE_OPERATION_APPROVAL_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, disapprovedOperation: action.payload, error: undefined };
        }
        case DisapproveOperationApprovalTypes.DISAPPROVE_OPERATION_APPROVAL_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case DisapproveOperationApprovalTypes.DISAPPROVE_OPERATION_APPROVAL_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as disapproveOperationApprovalReducer };
