import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListPaymentAccountsState, ListPaymentAccountsTypes } from './types';

const initialState: ListPaymentAccountsState = {
    status: HttpRequestStatus.NOOP,
    accounts: undefined,
    error: undefined,
};

const reducer: Reducer<ListPaymentAccountsState> = (state = initialState, action): ListPaymentAccountsState => {
    switch (action.type) {
        case ListPaymentAccountsTypes.LIST_PAYMENT_ACCOUNTS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ListPaymentAccountsTypes.LIST_PAYMENT_ACCOUNTS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, accounts: action.payload, error: undefined };
        }
        case ListPaymentAccountsTypes.LIST_PAYMENT_ACCOUNTS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListPaymentAccountsTypes.LIST_PAYMENT_ACCOUNTS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listPaymentAccountsReducer };
