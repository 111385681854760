import { useTranslation } from 'react-i18next';
import ActionButton from 'components/buttons/action-button/action-button';
import ModalSuccess from 'components/modals/modal-success/modal-success';
import './register-success.scss';

export interface RegisterSuccessProps {
    open: boolean;
    onClose: () => void;
}

export const RegisterSuccess = (props: RegisterSuccessProps) => {
    const { t } = useTranslation();

    return (
        <ModalSuccess open={props.open}>
            <div className="content__modal-register-success--itens">
                <div className="content__modal-register-success--image" />
                <div className="content__modal-register-success--title">{t('pages.register-finish.success.title')}</div>
                <div className="content__modal-register-success--message">{t('pages.register-finish.success.message')}</div>
                <ActionButton label={t('pages.register-finish.success.action').toUpperCase()} onClick={props.onClose} />
            </div>
        </ModalSuccess>
    );
};

export default RegisterSuccess;
