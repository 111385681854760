import FormatterUtils from 'components/formatter/formatter-utils';

export type ZipCodeValue = string | undefined | null;

export interface ZipCodeFormatterProps {
    value: ZipCodeValue;
    prefix?: boolean;
    defaultValue?: number;
}

export const ZipCodeFormatter = (props: ZipCodeFormatterProps) => {
    const { value } = props;
    return <>{FormatterUtils.formatStringToZipCode(value)}</>;
};

export const formatZipCode = FormatterUtils.formatStringToZipCode;

export default ZipCodeFormatter;
