import { Pageable } from 'services/pageable';
import { BankAccountType } from './enums/bank-account-type';
import { DocumentType } from './enums/document-type';
import { EducationType } from './enums/education-type';
import { FrequencyType } from './enums/frequency-type';
import { GenderType } from './enums/gender-type';
import { ImportedOperationBankerStatus } from './enums/imported-operation-status';
import { MaritalStatus } from './enums/marital-status';
import { ModalityType } from './enums/modality-type';
import { OperationApprovalStatus } from './enums/operation-approval-status';
import { PersonType } from './enums/person-type';
import { PixKeyType } from './enums/pix-key-type';
import { ProductType } from './enums/product-type';
import { ResidenceType } from './enums/residence-type';
import { GuaranteeToImportedOperation } from './guarantee';
import { GuarantorToImportedOperation } from './guarantor';
import { ScdImportedExpirationToGetOperation } from './imported-expiration';
import { LocalDatePeriod } from './local-date';

export interface ImportedOperationByPaymentTransactionData {
    id: number;
    ccbNumber: string;
    batchCode: string;
    hasBatch: boolean;
    acceptedDate: Date;
    product: ProductType;
    companyName: string;
    personName: string;
    identification: string;
    totalValue: number;
    disbursementValue: number;
}

export interface ImportedOperation {
    id?: number;
    acceptedDate?: Date;
    ccbNumber?: string;
    batchCode?: string;
    productType?: ProductType;
    debtorIdentification?: string;
    debtorName?: string;
    status?: ImportedOperationBankerStatus;
    hasBatch?: boolean;
}

export interface ImportedOperationFilterRequest {
    ccbNumber?: string;
    batchCode?: string;
    productType?: ProductType;
    debtorIdentification?: string;
    acceptedDate?: LocalDatePeriod;
    status?: ImportedOperationBankerStatus[];
}

export const defaultImportedOperationFilter: ImportedOperationFilterRequest = {
    status: [],
};

export interface ImportedOperationFilterPageable {
    pageable?: Pageable;
    filter?: ImportedOperationFilterRequest;
}

export interface ScdImportedOperationDetail {
    id?: number;
    ccbNumber?: string;
    companyIdentificationNumber?: string;
    acceptedDate?: Date;
    releaseDate?: Date;
    originator?: string;
    product?: ProductType;
    status?: ImportedOperationBankerStatus;

    importedPix?: {
        key?: string;
        type?: PixKeyType;
    };

    organization?: {
        id?: number;
        name?: string;
        identification?: string;
    };

    resumeDetailOperation?: {
        totalValue?: number;
        retentionValue?: number;
        retentionPercentage?: number;
        storeTransferValue?: number;
        fee?: number;
        installmentNumber?: number;
        modality?: ModalityType;
        frequency?: FrequencyType;

        // tax
        iofValue?: number;
        iofPercentage?: number;
        firstDueDate?: Date;
        installmentValue?: number;
        fareAmount?: number;
        titleFare?: number;
        tac?: number;
        additionalIofPercentage?: number;

        // personalInformation
        identification?: string;
        personName?: string;
        companyName?: string;
        stateRegistration?: string;

        // contact
        email?: string;
        phone?: string;
        cellPhone?: string;

        // address
        street?: string;
        neighborhood?: string;
        city?: string;
        state?: string;
        zipcode?: string;
        residenceType?: ResidenceType;
        addressNumber?: string;
        addressComplement?: string;
        timeResidence?: string;
        rentAmount?: number;

        isExemptOfStateRegistration?: boolean;
        documentType?: DocumentType;

        // supplementary data
        education?: EducationType;
        maritalStatus?: MaritalStatus;
        nationality?: string;
        gender?: GenderType;
        spouseIdentification?: string;
        spouseName?: string;
        spouseDateBirth?: Date;
        dependentsNumber?: number;

        // document
        rgIdentification?: string;
        agencyIssuing?: string;
        agencyIssuingAcronym?: string;
        dateBirth?: Date;
        issuingDate?: Date;
        motherName?: string;
        fatherName?: string;
        placeBirth?: string;
    };
    importedBankAccount?: {
        id?: number;
        bankNumber?: string;
        bankName?: string;
        type?: BankAccountType;
        agencyNumber?: string;
        agencyDigit?: string;
        accountNumber?: string;
        accountDigit?: string;
        operation?: string;
        jointAccount?: boolean;
        favoredIdentification?: string;
    };

    program?: {
        id?: number;
        name?: string;
        identifier?: string;
    };
    personType?: PersonType;
    personalInformation?: {};

    guarantees?: GuaranteeToImportedOperation[];
    guarantors?: GuarantorToImportedOperation[];

    importedExpirations?: ScdImportedExpirationToGetOperation[];

    operationApproval?: {
        id?: number;
        requestDate?: Date;
        approvalDate?: Date;
        approverName?: string;
        approverEmail?: string;
        status?: OperationApprovalStatus;
        disapprovalReason?: string;
    };
}

export const defaultScdImportedOperationDetail: Readonly<ScdImportedOperationDetail> = {};

export interface ImportedOperationToBatchRequest {
    batchId: number;
    pageable?: Pageable;
}
export interface ImportedOperationToBatchDetail {
    id: number;
    ccbNumber: string;
    acceptedDate: Date;
    companyName: string;
    productType: ProductType;
    debtorIdentification: string;
    debtorName: string;
    totalValue: number;
}
