import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { ScdPaymentTransactionData } from 'model/payment-transaction';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import PaymentTransactionsMoreOptionsMenu, { PaymentTransactionMoreOptionsType } from './menu/payment-transaction-more-option-menu';
import './payment-transaction-more-options-icon-button.scss';

export interface PaymentTransactionMoreOptionsIconButtonProps {
    disabled?: boolean;
    selectedPaymentTransaction: ScdPaymentTransactionData | undefined;
    setOpenModalMoreOptions: (value: boolean) => void;
    onClick: () => void;
}

const PaymentTransactionMoreOptionsIconButton = (props: PaymentTransactionMoreOptionsIconButtonProps): JSX.Element => {
    const { selectedPaymentTransaction, setOpenModalMoreOptions, onClick } = props;

    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        onClick();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSelectMenuItem = {
        importedOperations: () => {
            history.push(`${url}/${selectedPaymentTransaction?.id}/imported-operations`);
            handleClose();
        },
        details: () => {
            setOpenModalMoreOptions(true);
            handleClose();
        },
    } as Record<PaymentTransactionMoreOptionsType, () => void>;

    return (
        <div className="button__style--more-options-icon">
            <Button variant="contained" onClick={handleClick} disabled={props.disabled}>
                <Tooltip title={t('tooltips.more-options') ?? ''} aria-label="more-options">
                    <Icon>
                        <div className="button__style--more-options-icon--icon" />
                    </Icon>
                </Tooltip>
            </Button>
            <PaymentTransactionsMoreOptionsMenu open={open} anchorEl={anchorEl} handleClose={handleClose} onSelectItem={onSelectMenuItem} selectedPaymentTransaction={selectedPaymentTransaction} />
        </div>
    );
};
export default PaymentTransactionMoreOptionsIconButton;
