import { OperationApprovalErrorResponse } from 'model/operation-approvals';
import { BaseStoreState } from 'reducer/baseStoreState';

export type ApiResponse = Record<string, any>;

export enum ApproveMultiplesOperationsApprovalTypes {
    APPROVE_MULTIPLES_OPERATIONS_APPROVAL_REQUEST = '@@approve-multiples-operations-approval/APPROVE_MULTIPLES_OPERATIONS_APPROVAL_REQUEST',
    APPROVE_MULTIPLES_OPERATIONS_APPROVAL_SUCCESS = '@@approve-multiples-operations-approval/APPROVE_MULTIPLES_OPERATIONS_APPROVAL_SUCCESS',
    APPROVE_MULTIPLES_OPERATIONS_APPROVAL_ERROR = '@@approve-multiples-operations-approval/APPROVE_MULTIPLES_OPERATIONS_APPROVAL_ERROR',
    APPROVE_MULTIPLES_OPERATIONS_APPROVAL_RESET_STATE = '@@approve-multiples-operations-approval/APPROVE_MULTIPLES_OPERATIONS_APPROVAL_RESET_STATE',
}

export interface ApproveMultiplesOperationsApprovalState extends BaseStoreState {
    readonly errorResponse?: OperationApprovalErrorResponse;
}
